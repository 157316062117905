import { useEffect } from 'react';

import { brandName } from '../../config';

/**
 * Updates the page's meta data.
 *
 * TDL use Helmet lib: https://github.com/nfl/react-helmet
 */
export default function Metadata({
  description,
  title,
}: {
  description: string;
  title: string;
}) {
  useEffect(() => {
    globalThis.document.title = `${title} | ${brandName}`;
    globalThis.document.querySelector('meta[name="description"]')
      ?.setAttribute('content', description);
  }, [ description, title ]);

  return null;
}
