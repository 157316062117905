// @ts-check

// @ts-expect-error - No types for `clipper-lib`
import ClipperLib from 'clipper-lib';

/**
 * A service script which abstracts the ClipperLib library for boolean polygon
 * operations (union, intersection, etc) away from the rest of the app.
 */

// -- Types --------------------------------------------------------------------

/** @typedef {import('./matrix').Path} Path */
/** @typedef {import('./matrix').Coordinates} Coordinates */
/** @typedef {import('./matrix').Rect} Rect */

/** @typedef {{ X: number; Y: number}[][]} ClipperLibPaths */

// -- Public Functions ---------------------------------------------------------

/* eslint-disable new-cap */

// Clip Types
//
// ClipperLib.ClipType.ctIntersection;
// ClipperLib.ClipType.ctUnion;
// ClipperLib.ClipType.ctDifference;
// ClipperLib.ClipType.ctXor;

// Fill types
//
// ClipperLib.PolyFillType.pftEvenOdd;
// ClipperLib.PolyFillType.pftNonZero;
// ClipperLib.PolyFillType.pftPositive;
// ClipperLib.PolyFillType.pftNegative;

/**
 * Returns the difference between one or more _closed_ shape line paths and one
 * or more shape paths, excluding all overlapping paths and returning an array
 * of line paths.
 *
 * @param {Path[]} paths
 * @param {Path[]} subtractPaths
 *
 * @returns {Path[]}
 */
export function differencePaths(paths, subtractPaths) {
  const clipper = new ClipperLib.Clipper();
  const polyTree = new ClipperLib.PolyTree();

  clipper.AddPaths(
    toClipperPaths(paths),
    ClipperLib.PolyType.ptSubject
  );

  clipper.AddPaths(
    toClipperPaths(subtractPaths),
    ClipperLib.PolyType.ptClip,
    true
  );

  clipper.Execute(
    ClipperLib.ClipType.ctDifference,
    polyTree,
    ClipperLib.PolyFillType.pftNonZero,
    ClipperLib.PolyFillType.pftNonZero
  );

  return toCoordinatesPaths(ClipperLib.Clipper.PolyTreeToPaths(polyTree));
}

/**
 * Combines multiple shape paths into one or more shape paths representing the
 * union of all the shapes, including shapes for any compound paths (holes).
 *
 * @param {Path[]} paths
 *
 * @returns {Path[]}
 */
export function unionPaths(paths) {
  const clipper = new ClipperLib.Clipper();
  const solution = new ClipperLib.Paths();
  const clipperPaths = toClipperPaths(paths);

  clipper.AddPaths(
    clipperPaths,
    ClipperLib.PolyType.ptSubject,
    true
  );

  clipper.Execute(
    ClipperLib.ClipType.ctUnion,
    solution,
    ClipperLib.PolyFillType.pftNonZero,
    ClipperLib.PolyFillType.pftNonZero
  );

  return toCoordinatesPaths(solution);
}

// -- Private Functions --------------------------------------------------------

/**
 * Converts an array of shape paths (an array of Coordinates) into ClipperLib
 * paths.
 *
 * @param {Path[]} shapePaths
 *
 * @returns {ClipperLib.Paths}
 */
function toClipperPaths(shapePaths) {
  return shapePaths.map((paths) => paths.map(([ X, Y ]) => ({ X, Y })));
}

/**
 * Converts an array of ClipperLib paths to shape paths (an array of
 * Coordinates).
 *
 * @param {ClipperLibPaths} clipperPaths
 *
 * @returns {Path[]}
 */
function toCoordinatesPaths(clipperPaths) {
  return clipperPaths.map((paths) => paths.map(({ X, Y }) => [ X, Y ]));
}
