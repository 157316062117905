import { getDiceBag } from './dice';

import type { Dice } from './dice';

// -- Constants ----------------------------------------------------------------

/** Minimum number of words for a randomly generated seed.  */
const seedLengthMin = 1;

/** Maximum number of words for a randomly generated seed.  */
const seedLengthMax = 4;

/** A sequence of numbers for prefixing before `d*` dice strings. */
const seedDiceCounts = [ ...Array(12).keys() ];

/** A set of dice strings for use in randomly generated seeds. */
const seedDice = [
  'd4',
  'd6',
  'd8',
  'd10',
  'd12',
  'd20',
  'd100',
].flatMap((d) => seedDiceCounts.map((c) => `${c === 0 ? '' : c + 1}${d}`));

/** A set of words for use in randomly generated seeds. */
const seedWords = [
  ...seedDice,
  'abandoned',
  'abjuration',
  'alchemist',
  'amethyst',
  'amethysts',
  'amulet',
  'ancient',
  'antler',
  'anvil',
  'arcane',
  'arrow',
  'axe',
  'backflipping',
  'bag',
  'bandit',
  'barbarian',
  'bard',
  'battleaxe',
  'beast',
  'beholder',
  'bellow',
  'belt',
  'blast',
  'blowgun',
  'bolt',
  'bone',
  'boot',
  'bow',
  'bracelet',
  'bracer',
  'breastplate',
  'broch',
  'broken',
  'bucket',
  'buckler',
  'bugbear',
  'burned',
  'caltrop',
  'candle',
  'castle',
  'cave',
  'cavern',
  'centaur',
  'chainmail',
  'chest',
  'city',
  'claw',
  'cleric',
  'cloak',
  'club',
  'coin',
  'collapsed',
  'conjuration',
  'copper',
  'countryside',
  'crossbow',
  'crown',
  'crumbling',
  'crypt',
  'crystal',
  'cultist',
  'cyclops',
  'dagger',
  'damaged',
  'daring',
  'dark',
  'decayed',
  'decaying',
  'demon',
  'desert',
  'deserted',
  'destroyed',
  'diamond',
  'diamonds',
  'dice',
  'dilapidated',
  'dimensional',
  'discarded',
  'divination',
  'dragon',
  'druid',
  'dryad',
  'dungeon',
  'dwarf',
  'dwarves',
  'earring',
  'elemental',
  'elf',
  'elves',
  'emerald',
  'emeralds',
  'empty',
  'enchantment',
  'evocation',
  'exotic',
  'explosion',
  'exposed',
  'exquisite',
  'extraordinary',
  'extreme',
  'fabled',
  'faerie',
  'famous',
  'fang',
  'fantastic',
  'faraway',
  'fascinating',
  'fearful',
  'fearsome',
  'feather',
  'fierce',
  'fiery',
  'fighter',
  'fighting',
  'fireball',
  'fjord',
  'flail',
  'fluttering',
  'flying',
  'forest',
  'forgotten',
  'fortress',
  'frightening',
  'fur',
  'gargantuan',
  'gauntlets',
  'gelatinous',
  'gems',
  'ghost',
  'giant',
  'glacier',
  'glaive',
  'glorious',
  'gloves',
  'gnome',
  'goblin',
  'goggles',
  'gold',
  'graceful',
  'grand',
  'great',
  'greataxe',
  'greatclub',
  'greatsword',
  'greaves',
  'halberd',
  'halfling',
  'hammer',
  'handaxe',
  'handsome',
  'harbor',
  'harsh',
  'hat',
  'hatchet',
  'headband',
  'healing',
  'heavenly',
  'heavy',
  'helmet',
  'hide',
  'hills',
  'holy',
  'horn',
  'huge',
  'human',
  'humble',
  'illusion',
  'immense',
  'imp',
  'important',
  'impossible',
  'incredible',
  'intense',
  'invisible',
  'island',
  'ivory',
  'javelin',
  'jewel',
  'jewelry',
  'jungle',
  'junk',
  'junkyard',
  'kobold',
  'laboratory',
  'labyrinth',
  'lagoon',
  'lake',
  'lance',
  'leather',
  'levitating',
  'library',
  'lighthouse',
  'lightning',
  'lizardfolk',
  'longbows',
  'longsword',
  'mace',
  'mage',
  'magic',
  'magnificent',
  'mail',
  'majestic',
  'marble',
  'market',
  'marsh',
  'maze',
  'meadow',
  'mermaid',
  'merman',
  'mimic',
  'mine',
  'monastery',
  'monk',
  'monkey',
  'morningstar',
  'mountains',
  'necklace',
  'necromancer',
  'necromancy',
  'neglected',
  'net',
  'nymph',
  'oasis',
  'ocean',
  'ogre',
  'oil',
  'oozing',
  'opal',
  'opals',
  'orc',
  'palace',
  'paladin',
  'pale',
  'pearl',
  'pearls',
  'pelt',
  'permutations',
  'pick',
  'pickaxes',
  'pirate',
  'plains',
  'platemail',
  'platinum',
  'poison',
  'poor',
  'potion',
  'prison',
  'projectile',
  'pyramid',
  'quarterstaff',
  'random',
  'ranger',
  'rapier',
  'ring',
  'robe',
  'rogue',
  'ruby',
  'rugged',
  'ruin',
  'ruined',
  'rundown',
  'sapphire',
  'satyr',
  'saw',
  'scales',
  'scimitar',
  'scrolls',
  'scythes',
  'seed',
  'sewers',
  'shattered',
  'shields',
  'shipwreck',
  'shock',
  'shortbow',
  'shortsword',
  'shovel',
  'shrine',
  'sickle',
  'silver',
  'sining',
  'siren',
  'skeleton',
  'sling',
  'smashed',
  'smithy',
  'sorcerer',
  'spear',
  'sphinx',
  'sprite',
  'squishy',
  'stubborn',
  'studded',
  'stunning',
  'sundial',
  'swamp',
  'teeth',
  'teleporting',
  'temple',
  'thieve',
  'throwing',
  'tinderbox',
  'tiny',
  'tomb',
  'tong',
  'tooth',
  'topaz',
  'torch',
  'tower',
  'town',
  'transmuting',
  'traveling',
  'treasure',
  'trident',
  'troll',
  'tusk',
  'underground',
  'uninhabited',
  'valley',
  'vampire',
  'vicious',
  'village',
  'volcano',
  'wand',
  'warhammer',
  'wasteland',
  'waterfall',
  'waves',
  'wellspring',
  'werewolf',
  'whip',
  'wizard',
  'wondrous',
  'worn',
  'wrecked',
  'wretched',
  'ziggurat',
  'zombie',
];

export {
  seedLengthMin as testSeedLengthMin,
  seedLengthMax as testSeedLengthMax,
  seedWords as testSeedWords,
};

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a random seed string.
 */
export function getRandomSeed(dice: Dice = getDiceBag()): string {
  const count = dice.roll(seedLengthMin, seedLengthMax);

  let seed = dice.rollArrayItem(seedWords);

  for (let i = 1 + 1; i <= count; i++) {
    seed += ' ' + dice.rollArrayItem(seedWords);
  }

  return seed;
}
