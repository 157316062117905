import { getConditionProbabilityRoll } from '../../../config/attributes/condition';
import { lootManifest } from '../../../config/loot';

import type { Condition, Rarity, Size } from '../../../config/attributes';
import type { LootCategory, LootConfig } from '../../../config/loot';
import type { Dice } from '../../dice';
import type { LootSettings, RarityGroup } from '../loot';

// -- Types --------------------------------------------------------------------

export interface Item {
  category: LootCategory;
  condition: Condition;
  count: number;
  isMagic?: boolean;
  name: string;
  rarity: Rarity;
  size: Size;
  variant?: string;
}

// -- Config -------------------------------------------------------------------

export const mysteriousObject: Readonly<Item> = {
  category: 'miscellaneous',
  condition: 'average',
  count: 1,
  isMagic: false,
  name: 'Mysterious object',
  rarity: 'average',
  size: 'medium',
};

// -- Public Function ----------------------------------------------------------

/**
 * Generates an item from of the given loot pool & settings.
 */
export default function generateItem(
  dice: Dice,
  settings: LootSettings,
  rarityGroup: RarityGroup
): Readonly<Item> {
  const itemName = rollItem(dice, settings, rarityGroup);

  if (!itemName) {
    console.error('Failed to roll an item in generateItem()');
    return mysteriousObject;
  }

  const lootConfig = lootManifest.loot[itemName];

  if (!lootConfig) {
    console.error(`Invalid item name "${itemName}" generated in generateItem()`);
    return mysteriousObject;
  }

  return rollItemProperties(dice, lootConfig, settings);
}

// -- Private Functions --------------------------------------------------------

/**
 * Selects a random item from the given rarity group.
 */
function rollItem(
  dice: Dice,
  settings: LootSettings,
  rarityGroup: RarityGroup
): string | null {
  const { magicItemProbability } = settings;
  const magicItems = rarityGroup.get('magic');
  const mundaneItems = rarityGroup.get('mundane');

  if (magicItems && !mundaneItems) {
    return dice.rollArrayItem(magicItems);
  }

  if (magicItems && dice.rollPercentile(magicItemProbability)) {
    return dice.rollArrayItem(magicItems);
  }

  if (!mundaneItems) {
    return null;
  }

  return dice.rollArrayItem(mundaneItems);
}

/**
 * Randomizes item properties for the given loot config and returns an item.
 */
function rollItemProperties(
  dice: Dice,
  loot: LootConfig,
  settings: LootSettings
): Readonly<Item> {
  const { conditions } = settings;
  const {
    category,
    // isContainer,
    isMagic,
    maxCount,
    minCount = 1,
    name,
    rarity,
    size,
    variants,
  } = loot;

  const count = maxCount > 1
    ? dice.roll(minCount, maxCount)
    : 1;

  const condition = conditions.length
    ? getConditionProbabilityRoll(dice, conditions)()
    : 'average';

  const item: Item = {
    category,
    condition,
    count,
    name,
    rarity,
    size,
  };

  if (isMagic) {
    item.isMagic = true;
  }

  if (variants) {
    item.variant = dice.rollArrayItem(variants);
  }

  return item;
}
