import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { memo, useCallback, useId } from 'react';

import styles from './Toolbars.module.css';
import { DRAW_OPTION, TOOL } from '../../../lib/matrix';
import {
  CursorDefault as CursorIcon,
  RectanglePlus as DrawRectangleIcon,
  Eraser as EraserIcon,
  Draw as FreeDrawIcon,
  CursorPan as PanIcon,
  Pencil as PencilIcon,
  Stamp as StampIcon,
  VectorLine as VectorLineIcon,
} from '../../Display/Icons';

import type { ToggleButtonGroupProps } from '@mui/material';

// -- Config -------------------------------------------------------------------

/** Toolbar tooltip placement. */
const tooltipPlacement = 'left';

/** Toolbar tooltip delay. */
const tooltipDelay = 500;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map's toolbars.
 */
export default memo(function Toolbars({
  activeDrawOption,
  activeTool,
  availableDrawOptions,
  className,
  classNameOverlay,
  onChangeDrawOption,
  onChangeTool,
}: {
  activeDrawOption?: DRAW_OPTION;
  activeTool: TOOL;
  availableDrawOptions?: DRAW_OPTION[];
  className: string;
  classNameOverlay: string;
  onChangeDrawOption: (option: DRAW_OPTION) => void;
  onChangeTool: (tool: TOOL) => void;
}) {
  /** Handles tool change events. */
  const handleToolChange = useCallback((event: React.MouseEvent<HTMLElement>, tool: TOOL) => {
    if (tool === null) {
      return;
    }

    onChangeTool(tool);
  }, [ onChangeTool ]);

  /** Handles draw option change events. */
  const handleDrawOptionChange = useCallback((event: React.MouseEvent<HTMLElement>, option: DRAW_OPTION) => {
    if (option === null) {
      return;
    }

    onChangeDrawOption(option);
  }, [ onChangeDrawOption ]);

  return (
    <div className={className}>
      <Toolbar
        aria-label="Map Tools"
        className={`${classNameOverlay} ${styles.toolbar}`}
        label="Tools"
        onChange={handleToolChange}
        value={activeTool}
      >
        <ToolbarButton
          icon={PencilIcon}
          onClick={() => onChangeTool(TOOL.Draw)}
          title="Draw (D)"
          value={TOOL.Draw}
        />

        <ToolbarButton
          icon={EraserIcon}
          title="Erase (E)"
          value={TOOL.Erase}
        />

        <ToolbarButton
          icon={CursorIcon}
          title="Select (S)"
          value={TOOL.Select}
        />

        <ToolbarButton
          icon={PanIcon}
          title="Pan Canvas (V or Hold Space)"
          value={TOOL.Pan}
        />
      </Toolbar>

      {availableDrawOptions && activeDrawOption &&
        <Toolbar
          aria-label="Drawing Options"
          className={`${classNameOverlay} ${styles.toolbar}`}
          label="Draw"
          onChange={handleDrawOptionChange}
          value={activeDrawOption}
        >
          {getDrawOptionButtons(availableDrawOptions)}
        </Toolbar>
      }
    </div>
  );
});

// -- Private Components -------------------------------------------------------

/**
 * Renders a map toggle button toolbar. Pass `<ToolbarButton>` components as
 * children.
 */
function Toolbar({
  'aria-label': ariaLabel,
  children,
  className,
  label,
  onChange,
  value,
}: {
  'aria-label': string;
  children: React.ReactNode;
  className: string;
  label: string;
  onChange: ToggleButtonGroupProps['onChange'];
  value: TOOL | DRAW_OPTION;
}) {
  const id = useId();
  const labelId = `map-toolbar-${id}`;

  return (
    <div className={className}>
      <Typography
        aria-label={ariaLabel}
        fontSize="0.65rem"
        fontWeight="700"
        id={labelId}
        letterSpacing="0.05rem"
        marginBottom={0.5}
        textAlign="center"
        textTransform="uppercase"
      >
        {label}
      </Typography>

      <ToggleButtonGroup
        aria-labelledby={labelId}
        color="primary"
        exclusive
        onChange={onChange}
        orientation="vertical"
        size="small"
        value={value}
      >
        {children}
      </ToggleButtonGroup>
    </div>
  );
}

/**
 * Renders a toolbar button.
 *
 * Render as children of the `<Toolbar>` components.
 */
function ToolbarButton({
  icon: Icon,
  onClick,
  title,
  value,
  ...props
}: {
  icon: React.ComponentType<{ className: string }>;
  onClick?: () => void;
  title: string;
  value: TOOL | DRAW_OPTION;
}) {
  return (
    <ToggleButton
      aria-label={title}
      onClick={onClick}
      size="small"
      value={value}
      {...props}
    >
      <Tooltip
        enterDelay={tooltipDelay}
        placement={tooltipPlacement}
        title={title}
      >
        <span>
          <Icon aria-hidden className={styles.icon} />
        </span>
      </Tooltip>
    </ToggleButton>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns toolbar buttons for the draw options toolbar.
 */
function getDrawOptionButtons(
  availableDrawOptions: DRAW_OPTION[]
): React.ReactNode[] {

  const drawOptions: React.ReactNode[] = [];

  if (availableDrawOptions.includes(DRAW_OPTION.Free)) {
    drawOptions.push(
      <ToolbarButton
        icon={FreeDrawIcon}
        key="free"
        title="Free Draw"
        value={DRAW_OPTION.Free}
      />
    );
  }

  if (availableDrawOptions.includes(DRAW_OPTION.Rectangle)) {
    drawOptions.push(
      <ToolbarButton
        icon={DrawRectangleIcon}
        key="rectangle"
        title="Draw Rectangle"
        value={DRAW_OPTION.Rectangle}
      />
    );
  }

  if (availableDrawOptions.includes(DRAW_OPTION.Line)) {
    drawOptions.push(
      <ToolbarButton
        icon={VectorLineIcon}
        key="connection"
        title="Draw Line"
        value={DRAW_OPTION.Line}
      />
    );
  }

  if (availableDrawOptions.includes(DRAW_OPTION.Stamp)) {
    drawOptions.push(
      <ToolbarButton
        icon={StampIcon}
        key="stamp"
        title="Stamp"
        value={DRAW_OPTION.Stamp}
      />
    );
  }

  return drawOptions;
}
