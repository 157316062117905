import { useEffect, useState } from 'react';

// -- Types --------------------------------------------------------------------

/** Defines a global `simulateError()` function for testing error logging. */
declare global {
  function simulateError(): void;
}

// -- Public Component ---------------------------------------------------------

/**
 * An error simulator component, used only for testing error logging. Probably
 * all sorts of reasons not to do this.
 */
export default function ErrorSimulator() {
  const [ error, setError ] = useState(false);

  useEffect(() => {
    globalThis.simulateError = () => setError(true);
  }, []);

  useEffect(() => {
    if (error) {
      setError(false);
      throw new Error('Simulated React error');
    }
  }, [ error ]);

  return null;
}
