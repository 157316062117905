import { Button } from '@mui/material';
import { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { UnsavedChangesContext } from '../../components/Utility/UnsavedChangesContextProvider';

import type { To } from 'react-router-dom';

// -- Config -------------------------------------------------------------------

const activeNavItemSx = {
  color: 'text.primary',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the main navigation bar for the application.
 */
export default function MainNav({ className }: { className: string }) {
  return (
    <nav className={className}>
      <NavItem label="Maps" to="/maps" />
      <NavItem label="Areas" to="/areas" />
      <NavItem label="Loot" to="/loot" />
    </nav>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a navigation item.
 */
function NavItem({ label, to }: { label: string; to: To }) {
  const { onNav } = useContext(UnsavedChangesContext);
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <Button
      aria-current={isActive || undefined}
      component={RouterLink}
      onClick={(event) => onNav(event, to)}
      sx={isActive ? activeNavItemSx : undefined}
      to={to}
    >
      {label}
    </Button>
  );
}
