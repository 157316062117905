import { createProbabilityTable } from '../../lib/dice';

import type { Dice } from '../../lib/dice';

// -- Types --------------------------------------------------------------------

export type Condition = typeof conditions[number];

// -- Config -------------------------------------------------------------------

/**
 * Conditions
 */
export const conditions = [
  'decaying',
  'busted',
  'poor',
  'average',
  'good',
  'exquisite',
] as const;

/**
 * Condition scores. Determines the distribution of loot and area condition. A
 * higher sore is more frequent.
 */
const conditionScores: Readonly<Record<Condition, number>> = {
  /* eslint-disable sort-keys */
  decaying: 1,
  busted: 4,
  poor: 25,
  average: 50,
  good: 15,
  exquisite: 8,
  /* eslint-enable sort-keys */
};

// -- Public Functions ---------------------------------------------------------

/**
 * Creates a probability table and returns a function which rolls a condition..
 */
export function getConditionProbabilityRoll(
  dice: Dice,
  conditionsToRoll: Condition[] = [ ...conditions ]
) {
  const probabilityTable = createProbabilityTable(conditionsToRoll, conditionScores);
  return dice.getProbabilityRoll<Condition>(probabilityTable);
}
