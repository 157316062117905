import { Typography } from '@mui/material';

import styles from './FancyBox.module.css';

/**
 * Renders a box & title with a fancy border, fancy!
 */
export default function FancyBox({
  children,
  className = '',
  component: Component = 'div',
  title,
  titleComponent = 'h1',
}: {
  children: React.ReactNode;
  className?: string;
  component?: 'article' | 'div' | 'section';
  title: React.ReactNode;
  titleComponent?: 'h1' | 'h2' | 'h3' | 'h4';
}) {
  return (
    <Component className={`${styles.box} ${className}`}>
      <Typography
        align="center"
        component={titleComponent}
        marginBottom={2.5}
        variant="h2"
      >
        {title}
      </Typography>

      {children}

      <span aria-hidden className={styles.corner} data-position="top-left" />
      <span aria-hidden className={styles.corner} data-position="top-right" />
      <span aria-hidden className={styles.corner} data-position="bottom-right" />
      <span aria-hidden className={styles.corner} data-position="bottom-left" />
    </Component>
  );
}
