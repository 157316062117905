import { Group } from 'react-konva';

import { cellPx, pixelArtScale } from '../../../../config/map';
import { DETAIL } from '../../../../lib/matrix';

import type { Coordinates } from '../../../../lib/matrix';

// -- Public Component ---------------------------------------------------------

/**
 * Wraps, positions, and scales a pixel art cell.
 */
export default function PixelArtCell({
  children,
  coordinates: [ x, y ],
  type,
}: {
  children: React.ReactNode;
  coordinates: Coordinates;
  type: DETAIL;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={type}
      data-theme="pixelArt"
      scale={pixelArtScale}
      x={xPx}
      y={yPx}
    >
      {children}
    </Group>
  );
}
