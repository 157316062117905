import { Button } from '@mui/material';
import { Layer, Stage } from 'react-konva';

import Artwork from './Artwork';
import styles from './index.module.css';
import { cellPx, mapPadding } from '../../config/map';
import { ArrowRight as ArrowRightIcon } from '../Display/Icons';
import useDimensionsObserver from '../hooks/useDimensionsObserver';
import ActionBar from '../Interface/ActionBar';

import type { ArtworkProps } from './Artwork';

// -- Config -------------------------------------------------------------------

const mapPaddingSmallScreen = -2;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a static map, displayed after generation in a read-only state.
 */
export default function StaticMap({
  onClearMap,
  onDownloadMap,
  onEditMap,
  onShowSidebar,
  ...artworkProps
}: ArtworkProps & {
  onClearMap: () => void;
  onDownloadMap: () => void;
  onEditMap: () => void;
  onShowSidebar?: () => void;
}) {
  const {
    height: stageHeight,
    ref: containerRef,
    width: stageWidth,
  } = useDimensionsObserver<HTMLDivElement>();

  const {
    matrixHeight,
    matrixWidth,
  } = artworkProps;

  const matrixWidthPx = matrixWidth * cellPx;
  const matrixHeightPx = matrixHeight * cellPx;

  const scale = getStageScale(
    matrixWidthPx,
    matrixHeightPx,
    stageWidth,
    stageHeight,
    onShowSidebar ? mapPaddingSmallScreen : mapPadding
  );

  const position = getStagePosition(
    matrixWidthPx,
    matrixHeightPx,
    stageWidth,
    stageHeight,
    scale
  );

  return (
    <div className={styles.content}>
      <ActionBar
        action={onShowSidebar
          ? (
            <Button
              onClick={() => {
                onClearMap();
                onShowSidebar();
              }}
              size="small"
              startIcon={<ArrowRightIcon aria-hidden rotate={180} />}
              variant="text"
            >
              Options
            </Button>
          ) : (
            <Button
              onClick={onEditMap}
              size="small"
              variant="outlined"
            >
              Edit Map
            </Button>
          )
        }
      >
        <Button
          onClick={onClearMap}
          size="small"
          variant="outlined"
        >
          Clear
        </Button>

        <Button
          onClick={onDownloadMap}
          size="small"
          variant="outlined"
        >
          Download
        </Button>
      </ActionBar>

      <div
        className={styles.mapCanvasContainer}
        ref={containerRef}
      >
        <Stage
          height={stageHeight}
          position={position}
          scaleX={scale}
          scaleY={scale}
          width={stageWidth}
        >
          <Layer listening={false}>
            <Artwork {...artworkProps} />
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns the minimum stage scale based on available stage space.
 */
function getStageScale(
  matrixWidthPx: number,
  matrixHeightPx: number,
  stageWidth: number,
  stageHeight: number,
  padding: number
): number {
  const fitLandscapeScale = (stageWidth - padding) / matrixWidthPx;
  const fitPortraitScale = (stageHeight - padding) / matrixHeightPx;

  const fit = Math.min(fitLandscapeScale, fitPortraitScale) * 100;

  return padding === mapPaddingSmallScreen
    ? (fit / 100)
    : (Math.round(fit) / 100);
}

/**
 * Returns stage position.
 */
function getStagePosition(
  matrixWidthPx: number,
  matrixHeightPx: number,
  stageWidth: number,
  stageHeight: number,
  scale: number
): { x: number; y: number } {
  return {
    x: (stageWidth  / 2) - ((matrixWidthPx * scale) / 2),
    y: (stageHeight / 2) - ((matrixHeightPx * scale) / 2),
  };
}
