// -- Config -------------------------------------------------------------------

/**
 * Minimum number of word required to apply the widow fix.
 */
const minWords = 4;

// -- Public Component ---------------------------------------------------------

/**
 * Inserts a non breaking space for text strings with more than 3 words.
 */
export default function WidowFix({
  children,
}: {
  children: string;
}) {
  const parts = children.split(' ');

  if (parts.length < minWords) {
    return children;
  }

  const lastPart = parts.pop();

  return (
    <>
      {parts.join(' ')}&nbsp;{lastPart}
    </>
  );
}
