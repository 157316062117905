
// -- Types --------------------------------------------------------------------

export type Material = typeof materials[number];

// -- Config -------------------------------------------------------------------

export const metals = [
  'brass',
  'copper',
  'iron',
  'steel',
] as const;

export const miscellaneous = [
  'bone',
  'ceramic',
  'ivory',
] as const;

export const stones = [
  'granite',
  'limestone',
  'marble',
  'onyx',
  'quartz',
] as const;

export const woods = [
  'ash wood',
  'beech',
  'black cherry',
  'black walnut',
  'cedar',
  'cherry wood',
  'ebony wood',
  'elm',
  'mahogany',
  'maple',
  'red oak',
  'walnut',
  'white oak',
  'yew',
] as const;

export const materials = [
  ...metals,
  ...miscellaneous,
  ...stones,
  ...woods,
] as const;
