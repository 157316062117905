import { useCallback, useState } from 'react';

import useViewport from '../../hooks/useViewport';

// -- Types --------------------------------------------------------------------

interface UseSidebar {
  onHideSidebar?: () => void;
  onShowSidebar?: () => void;
  showSidebar: boolean;
}

// -- Public Hook --------------------------------------------------------------

/**
 * Handles showing and hiding the sidebar on small viewports. On large
 * viewports, the sidebar is always shown and `onShowSidebar` and
 * `onHideSidebar` are undefined.
 */
export default function useSidebar(): UseSidebar {
  const viewport = useViewport();
  const [ showSidebar, setShowSidebar ] = useState(false);

  /** Shows the sidebar */
  const onShowSidebar = useCallback(() => {
    setShowSidebar(true);
  }, []);

  /** Hides the sidebar */
  const onHideSidebar = useCallback(() => {
    setShowSidebar(false);
  }, []);

  if (viewport === 'lg') {
    return {
      showSidebar: true,
    };
  }

  return {
    onHideSidebar,
    onShowSidebar,
    showSidebar,
  };
}
