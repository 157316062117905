import { memo } from 'react';
import { Group, Line } from 'react-konva';

import { cellPx, colors, gridStrokePx } from '../../config/map';

// -- Config -------------------------------------------------------------------

/**
 * Props for grid lines.
 */
const gridLineProps = {
  stroke: colors.gridLines,
  strokeWidth: gridStrokePx,
};

/**
 * Props for grid edge lines.
 */
const gridLineEdgeProps = {
  ...gridLineProps,
  stroke: colors.border,
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders grid lines.
 */
export default memo(function GridLines({
  height,
  width,
}: {
  height: number;
  width: number;
}) {
  const lines = [];

  // Horizontal lines

  for (let y = 0; y < (height + 1); y++) {
    const yPx = y * cellPx;
    const x1Px = 0;
    const x2Px = width * cellPx;
    const props = y === 0 || y === height
      ? gridLineEdgeProps
      : gridLineProps;

    lines.push(
      <Line
        key={`y-${y}`}
        points={[ x1Px, yPx, x2Px, yPx ]}
        {...props}
      />
    );
  }

  // Vertical lines

  for (let x = 0; x < (width + 1); x++) {
    const xPx = x * cellPx;
    const y1Px = 0;
    const y2Px = height * cellPx;
    const props = x === 0 || x === width
      ? gridLineEdgeProps
      : gridLineProps;

    lines.push(
      <Line
        key={`x-${x}`}
        points={[ xPx, y1Px, xPx, y2Px ]}
        {...props}
      />
    );
  }

  return (
    <Group data-id="map-grid">
      {lines}
    </Group>
  );
});
