import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'kitchen',
  rarity: 'abundant',
};

const kitchenSupplies: { [name: string]: Partial<LootConfig> | null } = {
  Basin: null,
  Cauldron: { rarity: 'average' },
  'Cutting board': null,
  Flask: { size: 'tiny' },
  Fork: null,
  Jug: null,
  'Kitchen knife': null,
  Ladle: { rarity: 'common' },
  'Mess kit': null,
  'Pan, iron': null,
  Pitcher: null,
  'Pot, iron': null,
  Soap: { rarity: 'uncommon' },
  Spoon: null,
  'Steak knife': null,
  Tub: null,
  Vial: { rarity: 'uncommon', size: 'tiny' },
};

const configs: LootConfigIncomplete[] = Object.entries(kitchenSupplies).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
