import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'chancery',
  rarity: 'uncommon',
};

const chanceryItems: { [name: string]: Partial<LootConfig> } = {
  Abacus: { rarity: 'rare' },
  Book: { rarity: 'common' },
  Chalk: { size: 'tiny' },
  Hourglass: { rarity: 'rare' },
  Ink: { size: 'tiny', variants: [ 'vial', 'bottle' ] },
  'Ink pen': { size: 'tiny' },
  Journal: { variants: [ 'blank', "adventurer's", "noble person's", "hermit's", "wizard's", "merchant's" ] },
  'Letter opener': { size: 'tiny' },
  Paper: { maxCount: 100 },
  Paperweight: { size: 'tiny' },
  Parchment: { maxCount: 100 },
  Pencil: { size: 'tiny' },
  "Scale, merchant's": { rarity: 'rare' },
  Scroll: { rarity: 'common', size: 'tiny' },
  'Sealing wax': { size: 'tiny' },
  'Signet ring': { size: 'tiny' },
  'Wax seal': { size: 'tiny' },
};

const configs: LootConfigIncomplete[] = Object.entries(chanceryItems).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
