import { Text } from 'react-konva';

import { cellPx, fontSizeDebug } from '../../config/map';
import { iterateMatrix } from '../../lib/matrix/utility';

import type { Coordinates } from '../../lib/matrix';
import type { DebugMatrix } from '../../lib/matrix/debug';

// -- Public Component ---------------------------------------------------------

/**
 * Renders cell values, cell coordinates, or label cells to the map.
 */
export default function DebugDisplay({ debugMatrix }: { debugMatrix: DebugMatrix }) {
  return iterateMatrix(debugMatrix, ((value, coordinates) => {
    if (value === null) {
      return null;
    }

    return (
      <DebugCell
        coordinates={coordinates}
        key={coordinates.join(',')}
        label={value.toString()}
      />
    );
  }));
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a debug cell label.
 */
function DebugCell({
  coordinates,
  label,
}: {
  coordinates: Coordinates;
  label: string;
}) {
  const [ x, y ] = coordinates;

  return (
    <Text
      align="center"
      fontSize={fontSizeDebug}
      height={cellPx}
      text={label}
      verticalAlign="middle"
      width={cellPx}
      x={x * cellPx}
      y={y * cellPx}
    />
  );
}
