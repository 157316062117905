/** Default font family */
export const defaultFontFamily = [
  'DM Sans Variable',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(',');

/** Title font family */
export const titleFontFamily = [
  'Alegreya Variable',
  'serif',
].join(',');
