import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
} from '@mui/material';
import { useCallback, useId } from 'react';

// -- Config -------------------------------------------------------------------

/** Switch control styles. */
const controlSx = {
  justifyContent: 'space-between',
  mx: 0,
  pr: 0.5,
  width: '100%',
};

/** Switch styles. */
const switchSx = { my: -1 };

/** Switch label styles. */
const labelSx = {
  color: 'text.primary',
  display: 'block',
  fontSize: 'body2.fontSize',
  mx: 1.75,
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders a controlled switch and label.
 */
export default function LabeledSwitch({
  infoText,
  isChecked,
  label,
  onChange,
  showInfo,
}: {
  infoText: React.ReactNode;
  isChecked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  showInfo: boolean;
}) {
  const id = useId();
  const labelId = `switch-label-${id}`;
  const infoTextId = `switch-desc-${id}`;

  /** Handles switch change events. */
  const onSwitchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
  }, [ onChange ]);

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            inputProps={{
              'aria-describedby': showInfo ? infoTextId : undefined,
              'aria-labelledby': labelId,
            }}
            onChange={onSwitchChange}
            sx={switchSx}
          />
        }
        label={
          <FormLabel
            component="span"
            id={labelId}
            sx={labelSx}
          >
            {label}
          </FormLabel>
        }
        labelPlacement="start"
        sx={controlSx}
      />

      {showInfo &&
        <FormHelperText id={infoTextId}>
          {infoText}
        </FormHelperText>
      }
    </div>
  );
}
