import { useEffect } from 'react';

import { appColors } from '../../config/color';
import { toDash, toWords } from '../../lib/string';

// -- Config -------------------------------------------------------------------

/**
 * A CSS color variables string which is rendered in a style tag.
 */
const cssVars = Object.entries(appColors).reduce((styles, [ name, value ]) => {
  styles += `--color-${toDash(toWords(name)).toLowerCase()}: ${value};`;
  return styles;
}, '');

// -- Public Component ---------------------------------------------------------

/**
 * Whether the CSS variables have been added to the DOM.
 */
let added = false;

/**
 * Sets CSS variables for the theme to an inline style.
 */
export default function CssVars() {
  useEffect(() => {
    if (added) {
      return;
    }

    const style = globalThis.document.createElement('style');
    style.innerHTML = `:root{${cssVars}}`;

    globalThis.document.head.appendChild(style);

    added = true;
  }, []);

  return null;
}
