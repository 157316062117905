import { memo } from 'react';
import { Line } from 'react-konva';

import { areaBorderPx, colors } from '../../../config/map';
import { connectionDirectionsMeridian, directionsCardinal } from '../../../lib/matrix';

import type { ConnectionProps } from '.';
import type { DirectionCardinal } from '../../../lib/matrix';
import type Konva from 'konva';

// -- Types --------------------------------------------------------------------

type ExteriorLineCapsProps = Pick<ConnectionProps, 'direction' | 'heightPx' | 'widthPx'>;

// -- Config -------------------------------------------------------------------

/** Length of exterior passageway line caps in pixels. */
const lineCapLength = areaBorderPx * 2;

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders exterior connection line caps for connections where are open to the
 * outside.
 */
export default memo(function ExteriorLineCaps(props: ExteriorLineCapsProps) {
  return getLineCapPoints(props).map((points, i) => (
    <Line
      key={i}
      lineCap="round"
      points={points}
      stroke={colors.border}
      strokeWidth={areaBorderPx}
    />
  ));
});

// -- Private Functions --------------------------------------------------------

/**
 * Returns `Konva.LineConfig` points for exterior passageways.
 */
function getLineCapPoints({
  direction,
  heightPx,
  widthPx,
}: ExteriorLineCapsProps): Konva.LineConfig['points'][] {
  if (!directionsCardinal.has(direction as DirectionCardinal)) {
    throw new TypeError(`Invalid exterior connection direction "${direction}" in getLineCapPoints()`);
  }

  if (connectionDirectionsMeridian.has(direction)) {
    const lineCapY = direction === 'south' ? 0 : heightPx;

    return [
      [
        0,
        lineCapY,
        lineCapLength,
        lineCapY,
      ],
      [
        widthPx,
        lineCapY,
        (widthPx - lineCapLength),
        lineCapY,
      ],
    ];
  }

  const lineCapX = direction === 'west' ? widthPx : 0;

  return [
    [
      lineCapX,
      0,
      lineCapX,
      lineCapLength,
    ],
    [
      lineCapX,
      heightPx,
      lineCapX,
      (heightPx - lineCapLength),
    ],
  ];
}
