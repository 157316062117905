import { memo } from 'react';

import Background from './Background';
import CompassRose from './Embellishment/CompassRose';
import DistanceLegend from './Embellishment/DistanceLegend';
import Title from './Embellishment/Title';
import GridLines from './GridLines';
import Regions from './Regions';

import type { MapInfo, Theme } from '../../lib/map';
import type { MatrixInstructions } from '../../lib/matrix';

// -- Types --------------------------------------------------------------------

export type ArtworkProps = Pick<MapInfo, 'areaInfo' | 'connectionInfo'> & {
  instructions: MatrixInstructions;
  matrixHeight: number;
  matrixWidth: number;
  theme: Theme;
  title: string;
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders map artwork.
 *
 * Note: Must be wrapped in a konva `<Layer>`. Moving `<Layer>` into this
 * component causes a Konva error when reading the map into a data string for
 * image downloads.
 */
export default memo(function Artwork({
  areaInfo,
  connectionInfo,
  instructions,
  matrixHeight,
  matrixWidth,
  theme,
  title,
}: ArtworkProps) {
  return (
    <>
      <Background height={matrixHeight} width={matrixWidth} />

      <Regions
        areaInfo={areaInfo}
        connectionInfo={connectionInfo}
        theme={theme}
        {...instructions}
      />

      <GridLines height={matrixHeight} width={matrixWidth} />

      <CompassRose />

      <DistanceLegend />

      <Title matrixWidth={matrixWidth} title={title} />
    </>
  );
});
