import { getAreaProbabilityRoll } from '../../../config/area';
import { sizes } from '../../../config/attributes';
import { getConditionProbabilityRoll } from '../../../config/attributes/condition';
import { getDiceBag } from '../../dice';

import type { Area } from '../../../config/area';
import type { Condition, Size } from '../../../config/attributes';
import type { Dice } from '../../dice';
import type { DETAIL } from '../../matrix';

// -- Types --------------------------------------------------------------------

export interface AreaSettings {
  areas: Area[];
  conditions: Condition[];
  details: DETAIL[];
  sizes: Size[];
  trapFrequency: number;
  trapProbability: number;
}

export type AreaResult = {
  size: Size;
  type: Area | null;
};

// -- Config -------------------------------------------------------------------

/**
 * A lookup of area sizes by area type. Area types which are not included in
 * this lookup can be of any size.
 *
 * It is critical that area sizes are listed from smallest to largest. Order is
 * used to influence the generation of area sizes based on the user's selection
 * intention when selected sizes conflict with an area's available sizes.
 */
const areaSizes: Readonly<Partial<Record<Area, Size[]>>> = {
  armory: [ 'small', 'medium', 'large' ],
  ballroom: [ 'medium', 'large', 'massive' ],
  bathhouse: [ 'small', 'medium', 'large', 'massive' ],
  bedroom: [ 'tiny', 'small', 'medium' ],
  dining: [ 'small', 'medium', 'large', 'massive' ],
  dormitory: [ 'medium', 'large', 'massive' ],
  greatHall: [ 'large', 'massive' ],
  laboratory: [ 'tiny', 'small', 'medium', 'large' ],
  library: [ 'small', 'medium', 'large', 'massive' ],
  pantry: [ 'tiny', 'small', 'medium' ],
  parlour: [ 'tiny', 'small', 'medium' ],
  smithy: [ 'small', 'medium', 'large' ],
  study: [ 'tiny', 'small', 'medium' ],
  throne: [ 'medium', 'large', 'massive' ],
};

// -- Public Functions ---------------------------------------------------------

/**
 * Generates an area for the given area settings.
 */
export default function generateArea(
  seed: string,
  areaSettings: AreaSettings,
  {
    uniqueAreaFrequency = 100,
  } = {}
): AreaResult {
  const dice = getDiceBag({ seed });

  const {
    areas: selectedAreas,
    conditions: selectedConditions,
    details,
    sizes: selectedSizes,
    trapFrequency,
    trapProbability,
  } = areaSettings;

  const type = selectedAreas.length && dice.rollPercentile(uniqueAreaFrequency) // TODO setting for basic areas on map gen
    ? getAreaProbabilityRoll(dice, selectedAreas)()
    : null;

  const condition = selectedConditions.length
    ? getConditionProbabilityRoll(dice, selectedConditions)()
    : 'average';

  const size = rollAreaSize(dice, type, selectedSizes);

  return {
    size,
    type,
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns random area size for the given area type.
 *
 * TODO, roll for uniformity, so "random" is sometimes all similar sizes.
 */
function rollAreaSize(dice: Dice, area: Area | null, selectedSizes: Size[]): Size {
  const sizesForArea = area && areaSizes[area];

  if (!sizesForArea) {
    return dice.rollArrayItem(selectedSizes);
  }

  const availableSizes = sizesForArea.filter((size) => selectedSizes.includes(size));

  if (availableSizes.length) {
    return dice.rollArrayItem(availableSizes);
  }

  // Selected sizes conflict with the area type's available sizes. Infer a size
  // "intention" based on the selected sizes.
  const { large, small }: { large: number; small: number } = selectedSizes.reduce((intention, size) => {
    const sizeIndex = sizes.indexOf(size);

    if (sizeIndex < ((sizes.length - 1) / 2)) {
      intention.small += 1;
    } else {
      intention.large += 1;
    }

    return intention;
  }, {
    large: 0,
    small: 0,
  });

  const {
    0: smallestSizeForArea,
    [sizesForArea.length - 1]: largestSizeForArea,
  } = sizesForArea;

  if (large > small) {
    return largestSizeForArea;
  }

  return smallestSizeForArea;
}
