import { useCallback, useReducer } from 'react';

import type {
  MapInfo,
  MapSnapshot,
  RegionAreaInfo,
  RegionConnectionInfo,
} from '../../../lib/map';

// -- Types --------------------------------------------------------------------

type ActionSetAreaInfo = {
  areaId: number;
  type: 'setAreaInfo';
} & RegionAreaInfo;

type ActionSetConnectionInfo = {
  connectionId: number;
  type: 'setConnectionInfo';
} & RegionConnectionInfo;

export interface MapInfoState {
  mapInfo: MapInfo;
  onSetAreaInfo: (areaId: number, info: Partial<RegionAreaInfo>) => void;
  onSetConnectionInfo: (connectionId: number, info: Partial<RegionConnectionInfo>) => void;
}

type ReducerAction = ActionSetAreaInfo
  | ActionSetConnectionInfo;

type ReducerState = MapInfo;

// -- Public Hook --------------------------------------------------------------

/**
 * Manages map info; title, theme, settings, region descriptions, etc.
 */
export default function useMapInfo({
  snapshot,
}: {
  snapshot?: MapSnapshot;
} = {}): MapInfoState {
  const [ state, dispatch ] = useReducer(infoReducer, null, () => getDefaultState(snapshot));

  const onSetAreaInfo = useCallback((areaId: number, info: Partial<RegionAreaInfo>) => {
    dispatch({ areaId, type: 'setAreaInfo', ...info });
  }, []);

  const onSetConnectionInfo = useCallback((connectionId: number, info: Partial<RegionConnectionInfo>) => {
    dispatch({ connectionId, type: 'setConnectionInfo', ...info });
  }, []);

  return {
    mapInfo: state,
    onSetAreaInfo,
    onSetConnectionInfo,
  };
}

// -- Reducer ------------------------------------------------------------------

/**
 * Map info reducer.
 */
function infoReducer(state: ReducerState, action: ReducerAction): ReducerState {

  // -- Set Region Info --------------------------------------------------------

  if (action.type === 'setAreaInfo') {
    const { areaInfo } = state;
    const { areaId, type, ...info } = action;

    return {
      ...state,
      areaInfo: {
        ...areaInfo,
        [areaId]: {
          ...areaInfo[areaId],
          ...info,
        },
      },
    };
  }

  if (action.type === 'setConnectionInfo') {
    const { connectionInfo } = state;
    const { connectionId, type, ...info } = action;

    return {
      ...state,
      connectionInfo: {
        ...connectionInfo,
        [connectionId]: {
          ...connectionInfo[connectionId],
          ...info,
        },
      },
    };
  } /* v8 ignore next 4 */

  // @ts-expect-error - Unknown action type
  throw new TypeError(`Unknown action type "${action.type}" in useMapInfo(), mapReducer()`);
}

// -- Private Functions --------------------------------------------------------

/** Initializes reducer state. */
function getDefaultState(snapshot?: MapSnapshot): ReducerState {
  return {
    areaInfo: snapshot?.areaInfo || {},
    connectionInfo: snapshot?.connectionInfo || {},
  };
}
