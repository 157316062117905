import { useState } from 'react';

// -- Types --------------------------------------------------------------------

export type TabState = {
  activeTabIndex: number;
  onSetTab: (value: number) => void;
};

// -- Public Hook --------------------------------------------------------------

/**
 * Returns state props for the `<TabSet>` component.
 */
export default function useTabState(): TabState {
  const [ value, setValue ] = useState(0);

  return {
    activeTabIndex: value,
    onSetTab: setValue,
  };
}
