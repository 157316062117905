import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import WidowFix from './components/Display/WidowFix';
import AlertDialog from './components/Interface/AlertDialog';
import CssVars from './components/Utility/CssVars';
import ErrorBoundary from './components/Utility/ErrorBoundary';
import ErrorSimulator from './components/Utility/ErrorSimulator';
import UnsavedChangesContextProvider, { UnsavedChangesContext } from './components/Utility/UnsavedChangesContextProvider';
import theme from './config/theme';
import About from './pages/about';
import Areas from './pages/areas';
import Home from './pages/home';
import Loot from './pages/loot';
import Maps from './pages/maps';
import NotFound from './pages/NotFound';
import ReleaseNotes from './pages/release-notes';
import Support from './pages/support';

// -- Public Component ---------------------------------------------------------

/**
 * Top-level App component.
 *
 * When adding a new route, make sure to include an entry in `index.php`'s
 * router so the route does not 404.
 */
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CssVars />
      <ErrorBoundary>
        <BrowserRouter>

          <UnsavedChangesContextProvider>
            <Routes>
              <Route path="/">

                <Route element={<Home />} index />

                <Route
                  element={<About />}
                  path="about"
                />

                <Route
                  element={<Areas />}
                  path="areas"
                />

                <Route
                  element={<Loot />}
                  path="loot"
                />

                <Route
                  element={<Maps />}
                  path="maps"
                />

                <Route
                  element={<ReleaseNotes />}
                  path="release-notes"
                />

                <Route
                  element={<Support />}
                  path="support"
                />

                <Route
                  element={<NotFound />}
                  path="*"
                />

              </Route>
            </Routes>

            <UnsavedChangesAlert />
          </UnsavedChangesContextProvider>

        </BrowserRouter>
        <ErrorSimulator />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the unsaved changes alert.
 */
function UnsavedChangesAlert() {
  const {
    onCancelNav,
    onConfirmNav,
    showUnsavedChangesAlert,
  } = useContext(UnsavedChangesContext);

  return (
    <AlertDialog
      buttonSize="medium"
      cancelLabel="Cancel"
      confirmLabel="Destroy it!"
      isOpen={showUnsavedChangesAlert}
      onCancel={onCancelNav}
      onConfirm={onConfirmNav}
      title="Destroy creation?"
    >
      <p>
        <WidowFix>
          Goblins are about to drop your creation into lava!
        </WidowFix>
      </p>
    </AlertDialog>
  );
}
