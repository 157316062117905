import { titleFontFamily } from './font';

import type { Dimensions } from '../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Matrix cell dimensions in pixels. */
export const cellPx = 100;

/** Area wall size in pixels. */
export const areaBorderPx = cellPx / 10;

/** Area border shadow size in pixels. */
export const areaShadowPx = areaBorderPx * 2;

/** Detail line art size in pixels. */
export const detailPx = Math.round(cellPx / 33);

/** Map colors. */
export const colors = {
  background: '#f2f2f2',
  border: '#1f1c1d',
  detail: '#ebebeb',
  detailShadow: '#b5b5b5',
  gridLines: 'rgb(0, 0, 0, 0.08)',
  label: '#1f1c1d',
  marker: '#c1c1c1',
  regionDungeon: '#ffffff',
  shadow: '#cccccc',
  shadowDetail: '#777777',
  water: '#dbdbdb',
};

/** Embellishment stroke width in pixels. */
export const embellishmentStrokePx = Math.ceil(cellPx / 20);

/** Map title font family. */
export const fontFamilyTitle = titleFontFamily;

/** Map debug font size. */
export const fontSizeDebug = cellPx / 4;

/** Map area id font size large. */
export const fontSizeMapTitleLarge = cellPx;

/** Map title font size medium. */
export const fontSizeMapTitleMedium = cellPx * 0.6;

/** Map title font size small. */
export const fontSizeMapTitleSmall = cellPx / 2;

/** Grid stroke width. */
export const gridStrokePx = Math.floor(cellPx / 36);

/**
 * Amount of padding in px around the map when centered on the stage on large
 * viewports. On small viewports -1px padding is applied.
 */
export const mapPadding = 80;

/**
 * Default dimensions for a blank map.
 *
 * Note: Some tests assume the default matrix size, 26 x 26.
 */
export const matrixDimensionsDefault: Readonly<Dimensions> = [ 26, 26 ];

/**
 * Maximum x and y matrix dimension.
 *
 * Notes:
 *
 *   - Increasing this value can result in max call stack errors when preforming
 *     flood fill operations.
 *
 *   - This value should match or exceed
 *     `complexityMax * complexityMultiplierMax` defined in `generateMap.ts`.
 */
export const matrixDimensionMax = 64;

/**
 * Minimum x and y matrix dimension.
 */
export const matrixDimensionMin = 16;

/**
 * Scale of a single "pixel art pixel" for detail drawing in (actual) pixels.
 *
 * 20 x 20 pixel art grid per cell.
 *
 *    `cellPx` / `pixelArtScale` = "pixel art" cell size in pixels
 */
const pixelArtPx = 5;

/**
 * Scale prop which should be applied to a `<Group>` konva element containing
 * pixel art.
 *
 *    ```
 *    <Group scale={pixelArtScale.x}>
 *      // Pixel art here
 *    </Group>
 *    ```
 */
export const pixelArtScale = { x: pixelArtPx, y: pixelArtPx };

/** Expansion of hover and draw preview outlines in pixels. */
export const previewExpandPx = areaBorderPx;

/** Opacity of hover and draw preview outlines and selection overlays. */
export const previewOpacity = 0.3;

/** Opacity of region and detail hover overlays. */
export const previewSelectionOpacity = 0.15;

/** Area border and detail shadow offset size in pixels. */
export const shadowOffset = -areaBorderPx;

/** Map themes. */
export const themes = [
  'classic',
  'pixelArt',
] as const;
