import { IconButton, Slider, Tooltip } from '@mui/material';
import { memo, useCallback } from 'react';

import { CenterFocus as CenterFocusIcon } from '../../Display/Icons';

// -- Config -------------------------------------------------------------------

/** Toolbar label styles. */
const sliderSx = { mr: 1, width: '12rem' };

// -- Public Component ---------------------------------------------------------

/**
 * Renders a slider which controls the zoom level.
 */
export default memo(function ZoomSlider({
  className,
  maxScale,
  minScale,
  onChangeScale,
  onResetView,
  scale,
}: {
  className: string;
  maxScale: number;
  minScale: number;
  onChangeScale: (newScale: number) => void;
  onResetView: () => void;
  scale: number;
}) {
  const value = Math.round(scale * 100);

  /** Handles zoom slider events. */
  const onChange = useCallback((event: Event, newScale: number | number[]) => {
    onChangeScale(newScale as number / 100);
  }, [ onChangeScale ]);

  return (
    <div className={className}>
      <Slider
        aria-label="Zoom Map"
        aria-valuetext={`${value}%`}
        max={maxScale * 100}
        min={minScale * 100}
        onChange={onChange}
        size="small"
        sx={sliderSx}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={(percent: number) => `${Math.round(percent)}%`}
      />

      <Tooltip
        enterDelay={500}
        placement="top-end"
        title="Fit Map (F)"
      >
        <IconButton
          aria-label="Fit Map (F)"
          color="primary"
          onClick={onResetView}
          size="small"
        >
          <CenterFocusIcon aria-hidden />
        </IconButton>
      </Tooltip>
    </div>
  );
});
