import { useCallback } from 'react';

import useAreaSettings from '../../../components/Area/hooks/useAreaSettings';
import useSeedState from '../../../components/Interface/GeneratorPanel/hooks/useSeedState';
import useLootSettings from '../../../components/Loot/hooks/useLootSettings';
import useMapSettings from '../../../components/Map/hooks/useMapSettings';
import generateMap from '../../../lib/generate/map';
import { getRandomSeed } from '../../../lib/seed';

import type { AreaSettingsAction } from '../../../components/Area/hooks/useAreaSettings';
import type { LootSettingsAction } from '../../../components/Loot/hooks/useLootSettings';
import type { MapSettingsAction } from '../../../components/Map/hooks/useMapSettings';
import type { AreaSettings } from '../../../lib/generate/area';
import type { LootSettings } from '../../../lib/generate/loot';
import type { MapSettings } from '../../../lib/generate/map';
import type { MapSnapshot } from '../../../lib/map';

// -- Types --------------------------------------------------------------------

export interface MapPageGeneratorState {
  areaSettings: AreaSettings;
  lootSettings: LootSettings;
  mapSettings: MapSettings;
  onAreaSettingChange: (action: AreaSettingsAction) => void;
  onGenerate: () => void;
  onLootSettingChange: (action: LootSettingsAction) => void;
  onMapSettingChange: (action: MapSettingsAction) => void;
  onSeedChange: (seed: string) => void;
  seed?: string;
}

interface UseMapPageGeneratorProps {
  onHideSidebar?: () => void;
  onSetMapSnapshot: (snapshot?: MapSnapshot) => void;
}

// -- Public Hook --------------------------------------------------------------

/**
 * Map page generator state hook.
 */
export default function useMapPageGenerator({
  onHideSidebar,
  onSetMapSnapshot,
}: UseMapPageGeneratorProps): MapPageGeneratorState {
  const { onSeedChange, seed } = useSeedState();
  const [ mapSettings, onMapSettingChange ] = useMapSettings();
  const [ areaSettings, onAreaSettingChange ] = useAreaSettings();
  const [ lootSettings, onLootSettingChange ] = useLootSettings();

  /** Generates a map. */
  const onGenerate = useCallback(() => {
    const mapSeed = seed || getRandomSeed(); // TDL use same pattern for loot
    const snapshot = generateMap(
      mapSeed,
      mapSettings,
      areaSettings,
      lootSettings
    );

    if (snapshot) {
      onSetMapSnapshot(snapshot);
      onHideSidebar?.();
    }
  }, [
    areaSettings,
    lootSettings,
    mapSettings,
    onHideSidebar,
    onSetMapSnapshot,
    seed,
  ]);

  return {
    areaSettings,
    lootSettings,
    mapSettings,
    onAreaSettingChange,
    onGenerate,
    onLootSettingChange,
    onMapSettingChange,
    onSeedChange,
    seed,
  };
}
