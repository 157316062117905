import styles from './Icons.module.css';

/**
 * Icon sources:
 *   - Pictogrammers: https://pictogrammers.com/library/mdi/
 *   - Material Icons: https://mui.com/material-ui/material-icons/
 */

// -- Types --------------------------------------------------------------------

/** Icon props. */
export type IconProps = {
  className?: string;
  rotate?: 180;
};

// -- Config -------------------------------------------------------------------

/* Icon props */
const iconProps = {
  fill: 'currentColor',
  role: 'img',
  viewBox: '0 0 24 24',
};

// -- Public Components --------------------------------------------------------

/**
 * Renders an SVG alert icon.
 */
export function Alert(props: IconProps) {
  return (
    <Icon {...props} name="Alert">
      <path d="M12 2C11.5 2 11 2.19 10.59 2.59L2.59 10.59C1.8 11.37 1.8 12.63 2.59 13.41L10.59 21.41C11.37 22.2 12.63 22.2 13.41 21.41L21.41 13.41C22.2 12.63 22.2 11.37 21.41 10.59L13.41 2.59C13 2.19 12.5 2 12 2M11 7H13V13H11V7M11 15H13V17H11V15Z" />
    </Icon>
  );
}

/**
 * Renders an SVG arrow right icon.
 */
export function ArrowRight(props: IconProps) {
  return (
    <Icon {...props} name="ArrowRight">
      <path d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" />
    </Icon>
  );
}

/**
 * Renders an SVG bottle icon.
 */
export function Bottle(props: IconProps) {
  return (
    <Icon {...props} name="Bottle">
      <path d="M13 11.33 18 18H6l5-6.67V6h2m2.96-2H8.04c-.42 0-.65.48-.39.81L9 6.5v4.17L3.2 18.4c-.49.66-.02 1.6.8 1.6h16c.82 0 1.29-.94.8-1.6L15 10.67V6.5l1.35-1.69c.26-.33.03-.81-.39-.81z"></path>
    </Icon>
  );
}

/**
 * Renders an SVG center focus icon.
 */
export function CenterFocus(props: IconProps) {
  return (
    <Icon {...props} name="CenterFocus">
      <path d="M12 8C8.44 8 6.65 12.31 9.17 14.83C11.69 17.35 16 15.56 16 12C16 9.79 14.21 8 12 8M5 15H3V19C3 20.1 3.9 21 5 21H9V19H5M5 5H9V3H5C3.9 3 3 3.9 3 5V9H5M19 3H15V5H19V9H21V5C21 3.9 20.1 3 19 3M19 19H15V21H19C20.1 21 21 20.1 21 19V15H19" />
    </Icon>
  );
}

/**
 * Renders an SVG check box icon.
 */
export function CheckBox(props: IconProps) {
  return (
    <Icon {...props} name="CheckBox">
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
    </Icon>
  );
}

/**
 * Renders an SVG chevron right icon.
 */
export function ChevronRight(props: IconProps) {
  return (
    <Icon {...props} name="ChevronRight">
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </Icon>
  );
}

/**
 * Renders an SVG close icon.
 */
export function Close(props: IconProps) {
  return (
    <Icon {...props} name="Close">
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </Icon>
  );
}

/**
 * Renders an SVG default cursor icon.
 */
export function CursorDefault(props: IconProps) {
  return (
    <Icon {...props} name="CursorDefault">
      <path d="M10.07,14.27C10.57,14.03 11.16,14.25 11.4,14.75L13.7,19.74L15.5,18.89L13.19,13.91C12.95,13.41 13.17,12.81 13.67,12.58L13.95,12.5L16.25,12.05L8,5.12V15.9L9.82,14.43L10.07,14.27M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z" />
    </Icon>
  );
}

/**
 * Renders an SVG move cursor icon.
 */
export function CursorPan(props: IconProps) {
  return (
    <Icon {...props} name="CursorPan">
      <path d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z" />
    </Icon>
  );
}

/**
 * Renders an SVG door icon.
 */
export function Door(props: IconProps) {
  return (
    <Icon {...props} name="Door">
      <path d="M8,3C6.89,3 6,3.89 6,5V21H18V5C18,3.89 17.11,3 16,3H8M8,5H16V19H8V5M13,11V13H15V11H13Z" />
    </Icon>
  );
}

/**
 * Renders an SVG vertical dots icon.
 */
export function DotsVertical(props: IconProps) {
  return (
    <Icon {...props} name="DotsVertical">
      <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
    </Icon>
  );
}

/**
 * Renders an SVG draw icon.
 */
export function Draw(props: IconProps) {
  return (
    <Icon {...props} name="Draw">
      <path d="M9.75 20.85C11.53 20.15 11.14 18.22 10.24 17C9.35 15.75 8.12 14.89 6.88 14.06C6 13.5 5.19 12.8 4.54 12C4.26 11.67 3.69 11.06 4.27 10.94C4.86 10.82 5.88 11.4 6.4 11.62C7.31 12 8.21 12.44 9.05 12.96L10.06 11.26C8.5 10.23 6.5 9.32 4.64 9.05C3.58 8.89 2.46 9.11 2.1 10.26C1.78 11.25 2.29 12.25 2.87 13.03C4.24 14.86 6.37 15.74 7.96 17.32C8.3 17.65 8.71 18.04 8.91 18.5C9.12 18.94 9.07 18.97 8.6 18.97C7.36 18.97 5.81 18 4.8 17.36L3.79 19.06C5.32 20 7.88 21.47 9.75 20.85M20.84 5.25C21.06 5.03 21.06 4.67 20.84 4.46L19.54 3.16C19.33 2.95 18.97 2.95 18.76 3.16L17.74 4.18L19.82 6.26M11 10.92V13H13.08L19.23 6.85L17.15 4.77L11 10.92Z" />
    </Icon>
  );
}

/**
 * Renders an SVG eraser icon.
 */
export function Eraser(props: IconProps) {
  return (
    <Icon {...props} name="Eraser">
      <path d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z" />
    </Icon>
  );
}

/**
 * Renders an SVG fill bucket icon.
 */
export function Fill(props: IconProps) {
  return (
    <Icon {...props} name="Fill">
      <path d="M16.56 8.94 7.62 0 6.21 1.41l2.38 2.38-5.15 5.15c-.59.59-.59 1.54 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12zM5.21 10 10 5.21 14.79 10H5.21zM19 11.5s-2 2.17-2 3.5c0 1.1.9 2 2 2s2-.9 2-2c0-1.33-2-3.5-2-3.5zM2 20h20v4H2v-4z" />
    </Icon>
  );
}

/**
 * Renders an SVG hexagon icon.
 */
export function Hexagon(props: IconProps) {
  return (
    <Icon {...props} name="Hexagon">
      <path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L5,8.09V15.91L12,19.85L19,15.91V8.09L12,4.15Z" />
    </Icon>
  );
}

/**
 * Renders an SVG map icon.
 */
export function Map(props: IconProps) {
  return (
    <Icon {...props} name="Map">
      <path d="M20.5,3L20.34,3.03L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3M10,5.47L14,6.87V18.53L10,17.13V5.47M5,6.46L8,5.45V17.15L5,18.31V6.46M19,17.54L16,18.55V6.86L19,5.7V17.54Z" />
    </Icon>
  );
}

/**
 * Renders an SVG pencil icon.
 */
export function Pencil(props: IconProps) {
  return (
    <Icon {...props} name="Pencil">
      <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
    </Icon>
  );
}

/**
 * Renders an SVG rectangle with a plus symbol icon.
 */
export function RectanglePlus(props: IconProps) {
  return (
    <Icon {...props} name="RectanglePlus">
      <path d="M19,6H22V8H19V11H17V8H14V6H17V3H19V6M17,17V14H19V19H3V6H11V8H5V17H17Z" />
    </Icon>
  );
}

/**
 * Renders an SVG redo icon.
 */
export function Redo(props: IconProps) {
  return (
    <Icon {...props} name="Redo">
      <path d="M10.5,7A6.5,6.5 0 0,0 4,13.5A6.5,6.5 0 0,0 10.5,20H14V18H10.5C8,18 6,16 6,13.5C6,11 8,9 10.5,9H16.17L13.09,12.09L14.5,13.5L20,8L14.5,2.5L13.08,3.91L16.17,7H10.5M18,18H16V20H18V18Z" />
    </Icon>
  );
}

/**
 * Renders an SVG stamp icon.
 */
export function Stamp(props: IconProps) {
  return (
    <Icon {...props} name="Stamp">
      <path d="M12,3A3,3 0 0,0 9,6C9,9 14,13 6,13A2,2 0 0,0 4,15V17H20V15A2,2 0 0,0 18,13C10,13 15,9 15,6C15,4 13.66,3 12,3M6,19V21H18V19H6Z" />
    </Icon>
  );
}

/**
 * Renders an SVG table of contents icon.
 */
export function TableOfContents(props: IconProps) {
  return (
    <Icon {...props} name="TableOfContents">
      <path d="M3,9H17V7H3V9M3,13H17V11H3V13M3,17H17V15H3V17M19,17H21V15H19V17M19,7V9H21V7H19M19,13H21V11H19V13Z" />
    </Icon>
  );
}

/**
 * Renders an SVG undo icon.
 */
export function Undo(props: IconProps) {
  return (
    <Icon {...props} name="Undo">
      <path d="M13.5,7A6.5,6.5 0 0,1 20,13.5A6.5,6.5 0 0,1 13.5,20H10V18H13.5C16,18 18,16 18,13.5C18,11 16,9 13.5,9H7.83L10.91,12.09L9.5,13.5L4,8L9.5,2.5L10.92,3.91L7.83,7H13.5M6,18H8V20H6V18Z" />
    </Icon>
  );
}

/**
 * Renders an SVG vector line.
 */
export function VectorLine(props: IconProps) {
  return (
    <Icon {...props} name="VectorLine">
      <path d="M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5" />
    </Icon>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders an SVG icon.
 */
function Icon({
  children,
  className = styles.icon,
  name,
  rotate,
}: IconProps & {
  children: React.ReactNode;
  name: string;
  rotate?: 180;
}) {
  return (
    <svg
      {...iconProps}
      className={className}
      data-name={name}
      data-rotate={rotate}
    >
      {children}
    </svg>
  );
}
