import { Button } from '@mui/material';
import { memo } from 'react';

import StatusIndicator from './StatusIndicator';
import ActionBar from '../../Interface/ActionBar';

import type { Brush, TOOL } from '../../../lib/matrix';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map action bar.
 *
 * TDL add an alert on download with buttons for each available download.
 */
export default memo(function Actions({
  activeBrush,
  activeTool,
  isMapEmpty,
  onClearMap,
  onDownloadMap,
  onExit,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
  isMapEmpty: boolean;
  onClearMap: () => void;
  onDownloadMap: () => void;
  onExit: () => void;
}) {
  return (
    <ActionBar
      action={
        <Button
          onClick={onExit}
          size="small"
          variant="outlined"
        >
          Exit Editor
        </Button>
      }
      status={
        <StatusIndicator
          activeBrush={activeBrush}
          activeTool={activeTool}
        />
      }
    >
      <>
        <Button
          disabled={isMapEmpty}
          onClick={onClearMap}
          size="small"
          variant="outlined"
        >
          Clear
        </Button>

        <Button
          disabled={isMapEmpty}
          onClick={onDownloadMap}
          size="small"
          variant="outlined"
        >
          Download
        </Button>
      </>
    </ActionBar>
  );
});
