import { Button } from '@mui/material';

import StaticPage from '../../components/Display/StaticPage';
import Link from '../../components/Interface/Link';
import { discordInviteUrl, patreonUrl } from '../../config';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the support page.
 */
export default function SupportPage() {
  return (
    <StaticPage
      browserTitle="Support the Generator"
      metaDescription="Support the Mystic Waffle D&D dungeon generator, suggest features, and join the community."
      title="Support the Generator ❤️"
    >
      <div>
        <p className="paragraph">
          Mystic Waffle is the result of thousands of hours of passionate work
          by a sole developer. My goal is to create the TTRPG generator and
          drawing tool that I’ve always wanted, running in web browsers so everyone can access it.
          I’m continually adding to and improving the app, and I want to know what features <em>you</em> want!
          Join us on <Link href={discordInviteUrl}>Discord</Link> to let us know what to build next!
        </p>

        <p className="paragraph">
          Just thinking about people using this app for fun makes us smile. If you
          enjoy creating content on Mystic Waffle for your games or commercial
          modules, please consider supporting the app
          by <Link href={patreonUrl}>subscribing to our Patreon</Link>.
        </p>

        <p className="paragraph">
          This app and all content generated on it is 100% free for personal and
          commercial use.
        </p>

        <p className="paragraph">
          Thanks and happy adventuring! 🚀
        </p>

        <Button
          color="secondary"
          href={patreonUrl}
          rel="noopener noreferrer"
          size="large"
          target="_blank"
          variant="contained"
        >
          Become a member on Patreon
        </Button>
      </div>

    </StaticPage>
  );
}
