
// -- Types --------------------------------------------------------------------

export type Size = typeof sizes[number];

// -- Config -------------------------------------------------------------------

/**
 * Sizes.
 *
 * Sizes must be listed from smallest to largest, which is used to infer
 * selection intention in area generation.
 */
export const sizes = [
  'tiny',
  'small',
  'medium',
  'large',
  'massive',
] as const;
