import { memo } from 'react';
import { Group, Line, Rect as Rectangle } from 'react-konva';

import {
  areaBorderPx,
  cellPx,
  colors,
} from '../../../config/map';
import { CONNECTION, connectionDirectionsMeridian, directionsCardinal } from '../../../lib/matrix';
import { connectionInsetPx } from '../../../lib/matrix/path';

import type { ConnectionProps } from './';
import type {
  ConnectionDirection,
  DirectionCardinal,
} from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Pixels to inset the door from each edge of the connection. */
const insetPx = connectionInsetPx * 2;

/** Thickness of door rectangles in pixels. */
const thickness = cellPx / 4;

/** Half the thickness of door rectangles in pixels. */
const thicknessHalf = thickness / 2;

// -- Public Component ---------------------------------------------------------

/**
 * Renders door connection details.
 */
export default memo(function Doorway(props: ConnectionProps) {
  const {
    direction,
    heightPx: boundingHeight,
    widthPx: boundingWidth,
    xPx,
    yPx,
  } = props;

  // Whether the door is horizontal or vertical.
  const isHorizontal = connectionDirectionsMeridian.has(direction);

  // Whether the door is an exterior connection.
  const isExterior = directionsCardinal.has(direction as DirectionCardinal);

  // Door rectangle width.
  const width = isHorizontal
    ? getDoorSpan({ isExterior, span: boundingWidth })
    : thickness;

  // Door rectangle height.
  const height = isHorizontal
    ? thickness
    : getDoorSpan({ isExterior, span: boundingHeight });

  const [ x, y ] = getDoorRectPosition({
    boundingHeight,
    boundingWidth,
    direction,
    height,
    isExterior,
    isHorizontal,
    width,
  });

  const cellSpan = isHorizontal ? boundingWidth : boundingHeight;

  // Whether the door is a double door.
  const isDoubleDoor = cellSpan >= (cellPx * 2);

  // Whether the door has a room divider.
  const hasDivider = !isExterior && cellSpan >= (cellPx * 3);

  return (
    <Group
      data-id={CONNECTION.WoodDoor}
      x={xPx}
      y={yPx}
    >
      {hasDivider &&
        <RoomDivider
          height={boundingHeight}
          isHorizontal={isHorizontal}
          width={boundingWidth}
        />
      }

      {isExterior && <ExteriorEdge {...props} />}

      <Rectangle
        fill={colors.regionDungeon}
        height={height}
        stroke={colors.border}
        strokeWidth={areaBorderPx}
        width={width}
        x={x}
        y={y}
      />

      {isDoubleDoor &&
        <DoubleDoorDivider
          height={height}
          isHorizontal={isHorizontal}
          width={width}
          x={x}
          y={y}
        />
      }
    </Group>
  );
});

// -- Private Components -------------------------------------------------------

/**
 * Double door divider.
 */
function DoubleDoorDivider({
  height,
  isHorizontal,
  width,
  x,
  y,
}: {
  height: number;
  isHorizontal: boolean;
  width: number;
  x: number;
  y: number;
}) {
  const x1 = x + width / 2;
  const y1 = y + height / 2;

  const points = isHorizontal
    ? [ x1, y, x1, (y + thickness) ]
    : [ x, y1, (x + width), y1 ];

  return (
    <Line
      points={points}
      stroke={colors.border}
      strokeWidth={areaBorderPx}
    />
  );
}

/**
 * Renders the exterior wall of an exterior door connection.
 */
function ExteriorEdge(props: ConnectionProps) {
  const points = getExteriorEdgePoints(props);

  return (
    <Line
      points={points}
      stroke={colors.border}
      strokeWidth={areaBorderPx}
    />
  );
}

/**
 * Room divider, shown when a door's length is less than the connection's length
 * (horizontal or vertical).
 */
function RoomDivider({
  height,
  isHorizontal,
  width,
}: {
  height: number;
  isHorizontal: boolean;
  width: number;
}) {
  const x = cellPx / 2;
  const y = cellPx / 2;

  const points = isHorizontal
    ? [ connectionInsetPx, y, (width - connectionInsetPx), y ]
    : [ x, connectionInsetPx, x, (height - connectionInsetPx) ];

  return (
    <Line
      points={points}
      stroke={colors.border}
      strokeWidth={areaBorderPx}
    />
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns a door rect's  (horizontal or vertical).
 */
function getDoorSpan({
  isExterior,
  span,
}: {
  isExterior: boolean;
  span: number;
}) {
  if (span === cellPx) {
    return span - insetPx;
  }

  if (isExterior) {
    if (span === (cellPx * 2)) {
      return span - insetPx - (insetPx / 2);
    }

    return span - (insetPx * 2);
  }

  if (span === (cellPx * 2)) {
    return span - insetPx;
  }

  return span - (insetPx * 3);
}

/**
 * Returns a door detail's position.
 */
function getDoorRectPosition({
  boundingHeight,
  boundingWidth,
  direction,
  height,
  isExterior,
  isHorizontal,
  width,
}: {
  boundingHeight: number;
  boundingWidth: number;
  direction: ConnectionDirection;
  height: number;
  isExterior: boolean;
  isHorizontal: boolean;
  width: number;
}): [ x: number, y: number] {
  // Pixels to offset the door rectangle position.
  const offset = (isHorizontal
    ? (boundingWidth - width)
    : (boundingHeight - height)
  ) / 2;

  if (isExterior) {
    switch (direction) {
      case 'north':
        return [
          offset,
          (boundingHeight - thicknessHalf),
        ];

      case 'east':
        return [
          -thicknessHalf,
          offset,
        ];

      case 'south':
        return [
          offset,
          -thicknessHalf,
        ];

      case 'west': {
        return [
          (boundingWidth - thicknessHalf),
          offset,
        ];
      }
    }
  }

  // Interior door rectangle x position.
  const x = isHorizontal
    ? offset
    : ((cellPx - width) / 2);

  // Interior door rectangle y position.
  const y = isHorizontal
    ? ((cellPx - height) / 2)
    : offset;

  return [ x, y ];
}

/**
 * Returns exterior edge points.
 */
function getExteriorEdgePoints({
  direction,
  heightPx,
  widthPx,
}: ConnectionProps): [ x1: number, y1: number, x2: number, y2: number ] {
  switch (direction) {
    case 'north':
      return [ 0, heightPx, widthPx, heightPx ];

    case 'east':
      return [ 0, 0, 0, heightPx ];

    case 'south':
      return [ 0, 0, widthPx, 0 ];

    case 'west':
      return [ widthPx, 0, widthPx, heightPx ];

    /* v8 ignore next 3 */
    default:
      throw new TypeError(`Invalid connection direction "${direction}" in getExteriorEdgePoints()`);
  }
}
