import { memo } from 'react';
import { Group, Line } from 'react-konva';

import ExteriorLineCaps from './ExteriorLineCaps';
import { areaBorderPx, cellPx, colors } from '../../../config/map';
import {
  CONNECTION,
  connectionDirectionsMeridian,
  directionsCardinal,
} from '../../../lib/matrix';

import type { ConnectionProps } from '.';
import type { DirectionCardinal } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Number of stairway detail lines. */
const lineCount = 4;

/** Distance between stairway detail lines in pixels. */
const lineSpacing = cellPx / (lineCount + 1);

/** Stairway detail line thickness in pixels. */
const lineThickness = areaBorderPx / 2;

/** Half stairway detail line width in pixels. */
const lineThicknessHalf = lineThickness / 2;

/** Stairway detail inset increments in pixels. */
const insetIncrement = cellPx / 12;

/** Stairway detail inset. */
const insetMultiCell = cellPx / 1.5;

/** Stairway detail inset. */
const insetSingleCell = cellPx / 3;

/** Amount to scale multi cell span stairway details lines by. */
const multiCellIncrementScale = 1.5;

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders stairway connection details.
 */
export default memo(function Stairway(props: ConnectionProps) {
  const isExterior = directionsCardinal.has(props.direction as DirectionCardinal);
  const linePointSets = getLinePointSets(props);

  const { xPx, yPx } = props;

  return (
    <Group
      data-id={CONNECTION.Stairway}
      x={xPx}
      y={yPx}
    >
      {linePointSets.map((points, i) => (
        <Line
          key={i}
          lineCap="round"
          points={points}
          stroke={colors.border}
          strokeWidth={lineThickness}
        />
      ))}

      {isExterior && <ExteriorLineCaps {...props} />}
    </Group>
  );
});

// -- Private Functions --------------------------------------------------------

/**
 * Returns stairway detail line points.
 */
function getLinePointSets({
  direction,
  heightPx: boundingHeight,
  widthPx: boundingWidth,
}: ConnectionProps): number[][] {
  const cellWidth = boundingWidth / cellPx;
  const cellHeight = boundingHeight / cellPx;

  const isMultiCell = (cellWidth > 1) || (cellHeight > 1);
  const inset = isMultiCell ? insetMultiCell : insetSingleCell;
  const insetHalf = inset / 2;

  const width = boundingWidth - inset;
  const height = boundingHeight - inset;

  const x = insetHalf;
  const y = insetHalf;

  const isVertical = connectionDirectionsMeridian.has(direction);

  const incrementLength = (isVertical
    ? (insetIncrement * cellWidth)
    : (insetIncrement * cellHeight)
  ) / (isMultiCell ? multiCellIncrementScale : 1);

  const linePointSets: number[][] = [];

  for (let i = 1; i <= lineCount; i++) {
    const increment = incrementLength * (i - 1);

    switch (direction) {
      case 'north-south':
      case 'north': {
        const y1 = boundingHeight
          - lineThicknessHalf
          + (lineSpacing * (i * -1));

        linePointSets.push([
          (x + increment),
          y1,
          (x + width - increment),
          y1,
        ]);
        break;
      }

      case 'east-west':
      case 'east': {
        const x1 = lineThicknessHalf
          + (lineSpacing * i);

        linePointSets.push([
          x1,
          (y + increment),
          x1,
          (y + height - increment),
        ]);
        break;
      }

      case 'south': {
        const y1 = lineThicknessHalf
          + (lineSpacing * i);

        linePointSets.push([
          (x + increment),
          y1,
          (x + width - increment),
          y1,
        ]);
        break;
      }

      case 'west': {
        const x1 = boundingWidth
          - lineThicknessHalf
          + (lineSpacing * (i * -1));

        linePointSets.push([
          x1,
          (y + increment),
          x1,
          (y + height - increment),
        ]);
        break;
      }

      default:
        throw new TypeError(`Invalid stairway direction "${direction}" in <Stairway>, getLinePointSets()`);
    }
  }

  return linePointSets;
}
