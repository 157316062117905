import { createProbabilityTable } from '../lib/dice';

import type { Dice } from '../lib/dice';

// -- Types --------------------------------------------------------------------

export type Area = typeof areas[number];

// -- Config -------------------------------------------------------------------

/**
 * Areas
 */
export const areas = [
  'armory',
  'atrium',
  'ballroom',
  'bathhouse',
  'bedroom',
  'chamber',
  'dining',
  'dormitory',
  'forge',
  'greatHall',
  'kitchen',
  'laboratory',
  'library',
  'pantry',
  'parlour',
  'prison',
  'shrine',
  'smithy',
  'storage',
  'study',
  'throne',
  'treasury',
] as const;

/**
 * Area scores. Determines the distribution of area types. A higher sore is more
 * frequent.
 */
const areaScores: Readonly<Record<Area, number>> = {
  armory: 8,
  atrium: 6,
  ballroom: 6,
  bathhouse: 6,
  bedroom: 12,
  chamber: 12,
  dining: 12,
  dormitory: 12,
  forge: 8,
  greatHall: 8,
  kitchen: 12,
  laboratory: 6,
  library: 8,
  pantry: 12,
  parlour: 12,
  prison: 8,
  shrine: 12,
  smithy: 8,
  storage: 18,
  study: 12,
  throne: 8,
  treasury: 4,
};

// -- Public Functions ---------------------------------------------------------

/**
 * Creates a probability table and returns a function which rolls an area.
 */
export function getAreaProbabilityRoll(
  dice: Dice,
  areasToRoll: Area[] = [ ...areas ]
) {
  const probabilityTable = createProbabilityTable(areasToRoll, areaScores);
  return dice.getProbabilityRoll<Area>(probabilityTable);
}
