import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Hexagon as HexagonIcon } from './Icons';
import styles from './LoadingIndicator.module.css';

// -- Config -------------------------------------------------------------------

/**
 * Delay before displaying the loading indicator to avoid flickering when
 * content loads quickly.
 */
const displayDelay = 200;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a spinning loading indicator.
 */
export default function LoadingIndicator() {
  const [ isVisible, setIsVisible ] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), displayDelay);
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      aria-busy="true"
      aria-label="Loading"
      className={styles.spinner}
      role="progressbar"
    >
      <HexagonIcon aria-hidden />
      <Typography
        component="span"
        fontWeight="500"
        mt={2}
        variant="h4"
      >
        Mumbling Incantations…
      </Typography>
    </div>
  );
}
