import { useState } from 'react';

// -- Types --------------------------------------------------------------------

export interface GeneratorPanelState {
  onToggleAdvanced: () => void;
  onToggleInfo: () => void;
  showAdvanced: boolean;
  showInfo: boolean;
}

// -- Public Hook --------------------------------------------------------------

/**
 * Generator panel state hook.
 */
export default function useGeneratorPanelState(): GeneratorPanelState {
  const [ showAdvanced, setShowAdvanced ] = useState(false);
  const [ showInfo, setShowInfo ] = useState(false);

  return {
    onToggleAdvanced: () => setShowAdvanced(!showAdvanced),
    onToggleInfo: () => setShowInfo(!showInfo),
    showAdvanced,
    showInfo,
  };
}
