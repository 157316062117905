import { useCallback, useState } from 'react';

// -- Types --------------------------------------------------------------------

export interface SeedState {
  onSeedChange: (newSeed: string) => void;
  seed?: string;
}

// -- Public Hook --------------------------------------------------------------

/**
 * Settings panel seed input state hook.
 *
 * TDL extract randomized seed after generation.
 */
export default function useSeedState(): SeedState {
  const [ seed, setSeed ] = useState<string>();

  /**
   * Handles seed input change events.
   */
  const onSeedChange = useCallback((newSeed: string) => {
    setSeed(newSeed || undefined);
  }, []);

  return {
    onSeedChange,
    seed,
  };
}
