import { useMemo } from 'react';

import Chest from './Chest';
import Column from './Column';
import Crates from './Crates';
import Fountain from './Fountain';
import PixelArtChest from './PixelArt/Chest';
import PixelArtColumn from './PixelArt/Column';
import PixelArtCrates from './PixelArt/Crates';
import PixelArtFountain from './PixelArt/Fountain';
import PixelArtRubble from './PixelArt/Rubble';
import PixelArtTable from './PixelArt/Table';
import Rubble from './Rubble';
import Table from './Table';
import Trap from './Trap';
import { getOrSetMapEntry } from '../../../lib';
import { DETAIL } from '../../../lib/matrix';
import { getCellGroups } from '../../../lib/matrix/utility';

import type { Theme } from '../../../lib/map';
import type { Coordinates, CoordinatesMap, Detail } from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

/**
 * Configuration of strategies for each detail, which can be uniform across
 * all themes, or vary per theme.
 */
type DetailStrategies = Record<DETAIL, Strategy | Record<Theme, Strategy | null>>;

/** Multicellular detail. */
interface MulticellularDetail {
  coordinates: Coordinates[];
  theme: Theme;
  type: DETAIL;
}

/** Single cellular detail. */
type SingleCellularDetail = Detail & { theme: Theme };

/** Render strategies for details. */
export type Strategy = 'single' | 'multi';

// -- Config -------------------------------------------------------------------

/**
 * Detail strategies.
 *
 *  - "single": Details with the "single" strategy must be placed on individual
 *    cells.
 *
 *  - "multi": Details with the "multi" strategy can be grouped over multiple
 *    adjacent cells.
 */
const detailStrategies: DetailStrategies = {
  [DETAIL.Chest]: 'single',
  [DETAIL.Column]: 'single',
  [DETAIL.Crates]: 'single',
  [DETAIL.Fountain]: {
    classic: 'multi',
    pixelArt: 'single',
  },
  [DETAIL.Rubble]: 'single',
  [DETAIL.Table]: 'single',
  [DETAIL.Trap]: 'single',
};

export { detailStrategies as testDetailStrategies };

// -- Public Component ---------------------------------------------------------

/**
 * Renders map details.
 */
export default function Details({
  details,
  seed,
  theme,
}: {
  details: Detail[];
  seed: string;
  theme: Theme;
}) {
  const {
    multiCell,
    singleCell,
  } = useDetailStrategies({ details, theme });

  return (
    <>
      {singleCell.map(({ coordinates, theme: cellTheme, type }, i) => {
        if (cellTheme === 'pixelArt') {
          switch (type) {
            case DETAIL.Chest:
              return <PixelArtChest coordinates={coordinates} key={i} />;

            case DETAIL.Column:
              return <PixelArtColumn coordinates={coordinates} key={i} />;

            case DETAIL.Crates:
              return <PixelArtCrates coordinates={coordinates} key={i} />;

            case DETAIL.Fountain:
              return <PixelArtFountain coordinates={coordinates} key={i} />;

            case DETAIL.Rubble:
              return <PixelArtRubble coordinates={coordinates} key={i} />;

            case DETAIL.Table:
              return <PixelArtTable coordinates={coordinates} key={i} />;
          }
        }

        switch (type) {
          case DETAIL.Chest:
            return <Chest coordinates={coordinates} key={i} />;

          case DETAIL.Column:
            return <Column coordinates={coordinates} key={i} />;

          case DETAIL.Crates:
            return <Crates coordinates={coordinates} key={i} seed={seed} />;

          case DETAIL.Rubble:
            return <Rubble coordinates={coordinates} key={i} seed={seed} />;

          case DETAIL.Table:
            return <Table coordinates={coordinates} key={i} />;

          case DETAIL.Trap:
            return <Trap coordinates={coordinates} key={i} />;

          default:
            throw new TypeError(`Missing case for single cell detail type "${type}" in <Details>`);
        }
      })}

      {multiCell.map(({ coordinates, type }, i) => {
        switch (type) {
          case DETAIL.Fountain:
            return <Fountain coordinates={coordinates} key={i} />;

          default:
            throw new TypeError(`Missing case for multi cell detail type "${type}" in <Details>`);
        }
      })}
    </>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Returns area details grouped by their detail render strategies: single-cell
 * or multi-cell.
 */
function useDetailStrategies({ details, theme }: { details: Detail[]; theme: Theme }): {
  multiCell: MulticellularDetail[];
  singleCell: SingleCellularDetail[];
} {
  return useMemo(() => {
    const multiCellByType: Map<DETAIL, CoordinatesMap> = new Map();
    const singleCell: SingleCellularDetail[] = [];

    for (const detail of details) {
      const { coordinates, type } = detail;

      const entry = detailStrategies[type];
      const isGenericEntry = typeof entry === 'string';

      const strategy = isGenericEntry
        ? entry
        : entry?.[theme] || entry?.classic;

      const cellTheme = isGenericEntry || entry?.[theme] ? theme : 'classic';

      if (strategy === 'single') {
        singleCell.push({ ...detail, theme: cellTheme });
        continue;
      }

      if (strategy === 'multi') {
        const [ x, y ] = coordinates;
        const coordinatesKey = `${x},${y}`;

        getOrSetMapEntry(multiCellByType, detail.type, new Map())
          .set(coordinatesKey, coordinates);

        continue;
      }

      throw new TypeError(`Invalid detail type "${type}" in useDetailStrategies()`);
    }

    const multiCell: MulticellularDetail[] = [];

    for (const [ type, coordinatesMap ] of multiCellByType) {
      const groups = getCellGroups(coordinatesMap);

      for (const coordinates of groups) {
        multiCell.push({
          coordinates,
          theme,
          type,
        });
      }
    }

    return {
      multiCell,
      singleCell,
    };
  }, [ details, theme ]);
}
