import { Typography } from '@mui/material';

import styles from './EmptyState.module.css';
import CenterContent from '../Display/CenterContent';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an empty state.
 */
export default function EmptyState({
  children,
  description,
  title,
}: {
  children?: React.ReactNode;
  description: React.ReactNode;
  title: string;
}) {
  return (
    <CenterContent>
      <div className={styles.emptyState}>
        <Typography
          component="h1"
          fontWeight="600"
          marginBottom={2}
          variant="h1"
        >
          {title}
        </Typography>

        <p className="paragraph muted">
          {description}
        </p>

        {children}
      </div>
    </CenterContent>
  );
}
