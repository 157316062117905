import { Shape } from 'react-konva';

import { colors } from '../../../../config/map';
import drawPaths from '../../../../lib/konva/drawPaths';

import type { Path } from '../../../../lib/matrix';

// -- Types --------------------------------------------------------------------

export type PixelColor = 'black' | 'gray10' | 'gray20' | 'gray30' | 'white';

export type Pixels = [ PixelColor, ...Path[] ][];

// -- Config -------------------------------------------------------------------

/** Pixel art colors */
const pixelColors: Record<PixelColor, string> = {
  black: colors.border,
  gray10: colors.detail,
  gray20: colors.shadow,
  gray30: colors.detailShadow,
  white: colors.regionDungeon,
};

export { pixelColors as testPixelColors };

// -- Public Component ---------------------------------------------------------

/**
 * Renders pixel art for the given pixels.
 */
export default function PixelArt({ pixels }: { pixels: Pixels }) {
  return pixels.map(([ color, ...paths ], i) => {
    return (
      <Shape
        fill={pixelColors[color]}
        key={i}
        sceneFunc={(context, shape) => drawPaths(context, shape, paths)}
      />
    );
  });
}
