import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { useCallback, useId, useState } from 'react';

import styles from './Select.module.css';
import { capitalize, toWords } from '../../lib/string';
import { CheckBox as CheckBoxIcon } from '../Display/Icons';

import type { SelectChangeEvent } from '@mui/material';

// -- Config -------------------------------------------------------------------

/** Active menu item styles. */
const menuItemSx = { pr: '0.5rem', py: '0.5rem' };

// -- Public Component ---------------------------------------------------------

/**
 * Renders a controlled select menu.
 *
 * `items` and `value`, and thus the return value of `onChange` should be camel
 * case strings which are converted to spaced title case for display only.
 */
export default function Select({
  infoText,
  items,
  label,
  onChange,
  showInfo,
  value,
}: {
  infoText: React.ReactNode;
  items: string[] | readonly string[];
  label: string;
  onChange: (newValue: string) => void;
  showInfo: boolean;
  value: string;
}) {
  const id = useId();
  const labelId = `select-label-${id}`;
  const infoTextId = `select-desc-${id}`;

  const [ isOpen, setIsOpen ] = useState(false);

  /** Handles the menu close event. */
  const handleClose = useCallback(() => setIsOpen(false), []);

  /** Handles the menu open event. */
  const handleOpen = useCallback(() => setIsOpen(true), []);

  /** Handles select change events. */
  const onSelectChange = useCallback((event: SelectChangeEvent) => {
    event.target.value && onChange(event.target.value);
  }, [ onChange ]);

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={labelId}>
          {label}
        </InputLabel>

        <MuiSelect
          aria-describedby={showInfo ? infoTextId : undefined}
          label={label}
          labelId={labelId}
          onChange={onSelectChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          renderValue={() => capitalize(toWords(value))}
          value={value}
        >
          <MenuItem
            aria-readonly
            divider
            onClick={handleClose}
          >
            <Typography
              color="primary"
              fontSize="0.75rem"
              variant="button"
            >
              Close Menu
            </Typography>
          </MenuItem>

          {items.map((item) => (
            <MenuItem
              key={item}
              sx={menuItemSx}
              value={item}
            >
              <ListItemText primary={capitalize(toWords(item))} />
              {item === value && <CheckBoxIcon aria-hidden className={styles.check} />}
            </MenuItem>
          ))}
        </MuiSelect>

        {showInfo &&
          <FormHelperText id={infoTextId}>
            {infoText}
          </FormHelperText>
        }
      </FormControl>
    </div>
  );
}
