import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Stage } from 'react-konva';

import useMapKeyboard from './hooks/useMapKeyboard';
import InteractivePreview from './InteractivePreview';
import styles from './InteractiveStage.module.css';
import HistoryButtons from './Interface/HistoryButtons';
import Toolbars from './Interface/Toolbars';
import ZoomSlider from './Interface/ZoomSlider';
import { cellPx, mapPadding } from '../../config/map';
import {
  getPointerPosition,
  getRelativePointerPosition,
  getStagePosition,
} from '../../lib/konva/event';
import {
  DRAW_OPTION,
  drawOptionsBrush,
  drawOptionsErase,
  TOOL,
} from '../../lib/matrix';
import { getCellValue } from '../../lib/matrix/utility';

import type {
  Brush,
  CellValue,
  Coordinates,
  CoordinatesKey,
  CoordinatesMap,
  MatrixImmutable,
  MatrixInstructions,
} from '../../lib/matrix';
import type Konva from 'konva';
import type React from 'react';

// -- Types --------------------------------------------------------------------

/** Cursor type used for the `data-cursor` attribute for CSS styles. */
type Cursor = 'default' | 'cell' | 'move' | 'not-allowed' | 'pointer';

/** Callback for draw events. */
type OnDraw = (coordinates: Coordinates[], drawOption: DRAW_OPTION, options?: { commit: true }) => void;

/** Callback for erase events. */
type OnErase = (coordinates: Coordinates[], drawOption: DRAW_OPTION, options?: { commit: true }) => void;

/** State object representing the stage view's position and scale. */
interface StageView {
  position: { x: number; y: number };
  scale: number;
}

// -- Config -------------------------------------------------------------------

/**
 * Maximum scale level.
 */
export const maxScale = 1.5;

/**
 * Amount of padding in px around the map when zoomed all the way out.
 */
const minZoomPadding = 300;

/**
 * Ratio to zoom in/out on keyboard zoom.
 */
const scaleZoomByKey = 1.3;

/**
 * Ratio to zoom in/out on wheel zoom.
 */
const scaleZoomByWheel = 1.1;

/**
 * A set of tools which can add or remove cells.
 */
const drawTools = new Set([
  TOOL.Draw,
  TOOL.Erase,
]);

/**
 * A set of tools which should show a hover indicator on the map.
 */
const hoverTools = new Set([
  TOOL.Draw,
  TOOL.Erase,
]);

// -- Public Component ---------------------------------------------------------

/**
 * Renders the interactive stage for the map canvas.
 */
export default function InteractiveStage({
  activeBrush,
  activeTool,
  children,
  instructions,
  matrix,
  matrixHeight,
  matrixWidth,
  onChangeBrush,
  onChangeTool,
  onDraw,
  onErase,
  onRedo,
  onSelect,
  onUndo,
  selectedRegionId,
  stageHeight,
  stageWidth,
}: {
  /** Active brush type. */
  activeBrush: Brush;

  /** Active canvas tool. */
  activeTool: TOOL;

  /** Map canvas children. */
  children: React.ReactNode;

  /** Map instructions. */
  instructions: MatrixInstructions;

  /** Multidimensional array of matrix cell values. */
  matrix: MatrixImmutable;

  /** Matrix height in cells units. */
  matrixHeight: number;

  /** Matrix width in cells units. */
  matrixWidth: number;

  /** Callback for changing the active brush. */
  onChangeBrush: (brush: Brush) => void;

  /** Callback for changing the active tool. */
  onChangeTool: (tool: TOOL) => void;

  /** Callback for drawing on the map. */
  onDraw: OnDraw;

  /** Callback for erasing pars of the map. */
  onErase: OnErase;

  /** Callback for the redo action. */
  onRedo?: () => void;

  /** Callback for selecting a region or detail on the map. */
  onSelect: (coordinates?: Coordinates) => void;

  /** Callback for the undo action. */
  onUndo?: () => void;

  /** Currently selected region. */
  selectedRegionId: CellValue;

  /** Stage height in pixels. */
  stageHeight: number;

  /** Stage width in pixels. */
  stageWidth: number;
}) {
  const {
    activeDrawOption,
    availableDrawOptions,
    cursor,
    draw,
    highlightRegionId,
    hoverPreview,
    minScale,
    onChangeDrawOption,
    onChangeScale,
    onDragBound,
    onDragEnd,
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onResetView,
    onWheel,
    stagePosition,
    stageScale,
  } = useInteraction({
    activeBrush,
    activeTool,
    matrix,
    matrixHeight,
    matrixWidth,
    onChangeBrush,
    onChangeTool,
    onDraw,
    onErase,
    onRedo,
    onSelect,
    onUndo,
    stageHeight,
    stageWidth,
  });

  const showHistoryButtons = activeTool !== TOOL.Select;

  return (
    <div
      className={styles.stageContainer}
      data-cursor={cursor}
    >
      <Stage
        dragBoundFunc={onDragBound}
        draggable={activeTool === TOOL.Pan ? true : undefined}
        height={stageHeight}
        onDragEnd={onDragEnd}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onWheel={onWheel}
        position={stagePosition}
        scaleX={stageScale}
        scaleY={stageScale}
        width={stageWidth}
      >
        <Layer listening={false}>
          {children}

          <InteractivePreview
            activeBrush={activeBrush}
            activeDrawOption={activeDrawOption}
            activeTool={activeTool}
            draw={draw}
            highlightRegionId={highlightRegionId}
            hover={hoverPreview}
            instructions={instructions}
            matrix={matrix}
            selectedRegionId={selectedRegionId}
          />
        </Layer>
      </Stage>

      <Toolbars
        activeDrawOption={activeDrawOption}
        activeTool={activeTool}
        availableDrawOptions={availableDrawOptions}
        className={styles.toolbars}
        classNameOverlay={styles.overlay}
        onChangeDrawOption={onChangeDrawOption}
        onChangeTool={onChangeTool}
      />

      {showHistoryButtons &&
        <HistoryButtons
          className={styles.historyButtons}
          onRedo={onRedo}
          onUndo={onUndo}
        />
      }

      <ZoomSlider
        className={`${styles.overlay} ${styles.zoomControls}`}
        maxScale={maxScale}
        minScale={minScale}
        onChangeScale={onChangeScale}
        onResetView={onResetView}
        scale={stageScale}
      />
    </div>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Handles interactive stage mouse and keyboard events.
 */
function useInteraction({
  activeBrush,
  activeTool,
  matrix,
  matrixHeight,
  matrixWidth,
  onChangeBrush,
  onChangeTool,
  onDraw,
  onErase,
  onRedo,
  onSelect,
  onUndo,
  stageHeight,
  stageWidth,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
  matrix: MatrixImmutable;
  matrixHeight: number;
  matrixWidth: number;
  onChangeBrush: (brush: Brush) => void;
  onChangeTool: (tool: TOOL) => void;
  onDraw: OnDraw;
  onErase: OnErase;
  onRedo?: () => void;
  onSelect: (coordinates?: Coordinates) => void;
  onUndo?: () => void;
  stageHeight: number;
  stageWidth: number;
}): {
  activeDrawOption?: DRAW_OPTION;
  availableDrawOptions?: DRAW_OPTION[];
  cursor: Cursor;
  draw: Coordinates[];
  highlightRegionId?: CellValue | null;
  hoverPreview?: Coordinates;
  minScale: number;
  onChangeDrawOption: (option: DRAW_OPTION) => void;
  onChangeScale: (newScale: number) => void;
  onDragBound: (position: Konva.Vector2d) => Konva.Vector2d;
  onDragEnd: (event: Konva.KonvaEventObject<DragEvent>) => void;
  onMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  onMouseLeave: () => void;
  onMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  onMouseUp: () => void;
  onResetView: () => void;
  onWheel: (event: Konva.KonvaEventObject<WheelEvent>) => void;
  stagePosition: { x: number; y: number };
  stageScale: number;
} {
  const [ mouseDown, setMouseDown ] = useState<Coordinates | null>(null);

  const {
    activeDrawOption,
    availableDrawOptions,
    setDrawOption,
  } = useDrawOption({ activeBrush, activeTool });

  const {
    draw,
    onDrawCancel,
    onDrawCommit,
    onDrawMove,
    onDrawStart,
  } = useDraw({
    activeDrawOption,
    activeTool,
    onDraw,
    onErase,
  });

  const {
    minScale,
    onChangePosition,
    onChangeScale,
    onDragBound,
    onResetView,
    onWheel,
    onZoomKey,
    stagePosition,
    stageScale,
  } = useView({
    matrixHeight,
    matrixWidth,
    stageHeight,
    stageWidth,
  });

  const {
    hover,
    onHoverMove,
    onHoverOff,
  } = useHover({
    matrixHeight,
    matrixWidth,
  });

  const {
    cursor,
    highlightRegionId,
    hoverPreview,
  } = usePreview({
    activeTool,
    hover,
    matrix,
    mouseDown,
  });

  // -- Interaction Functions --------------------------------------------------

  /**
   * Gets cell coordinates from a pointer position.
   */
  const getCellCoordinates = useCallback(({ x, y }: { x: number; y: number }): Coordinates => {
    return [
      Math.floor(x / cellPx),
      Math.floor(y / cellPx),
    ];
  }, []);

  // -- Draw Option Events -----------------------------------------------------

  /**
   * Handles toolbar draw option button click events.
   */
  const onChangeDrawOption = useCallback(( option: DRAW_OPTION ) => {
    setDrawOption?.(option);
  }, [ setDrawOption ]);

  // -- Drag Events ------------------------------------------------------------

  /**
   * Handles drag end events.
   */
  const onDragEnd = useCallback((event: Konva.KonvaEventObject<DragEvent>) => {
    onChangePosition(getStagePosition(event));
  }, [ onChangePosition ]);

  // -- Mouse Events -----------------------------------------------------------

  /**
   * Handles mouse down canvas events.
   */
  const onMouseDown = useCallback((event: Konva.KonvaEventObject<MouseEvent>) => {
    const coordinates = getCellCoordinates(getRelativePointerPosition(event));

    if (!isInBounds(coordinates, matrixWidth, matrixHeight)) {
      return;
    }

    setMouseDown(coordinates);

    if (!hoverTools.has(activeTool)) {
      return;
    }

    if (drawTools.has(activeTool)) {
      onDrawStart(coordinates);
      return;
    }
  }, [
    activeTool,
    getCellCoordinates,
    onDrawStart,
    matrixHeight,
    matrixWidth,
  ]);

  /**
   * Handles mouse up and mouse leave canvas events.
   */
  const onMouseUp = useCallback(() => {
    if (!mouseDown) {
      activeTool === TOOL.Select && onSelect();
      return;
    }

    setMouseDown(null);

    if (drawTools.has(activeTool)) {
      onDrawCommit();
      return;
    }

    if (activeTool === TOOL.Select) {
      onSelect(mouseDown);
    }
  }, [
    activeTool,
    mouseDown,
    onDrawCommit,
    onSelect,
  ]);

  /**
   * Handles mouse leave canvas events.
   */
  const onMouseLeave = useCallback(() => {
    mouseDown ? onMouseUp() : onDrawCancel();
    onHoverOff();
  }, [
    mouseDown,
    onDrawCancel,
    onHoverOff,
    onMouseUp,
  ]);

  /**
   * Handles mouse move canvas events.
   */
  const onMouseMove = useCallback((event: Konva.KonvaEventObject<MouseEvent>) => {
    const coordinates = getCellCoordinates(getRelativePointerPosition(event));

    onHoverMove(coordinates);

    if (!drawTools.has(activeTool)) {
      return;
    }

    if (!isInBounds(coordinates, matrixWidth, matrixHeight)) {
      if (hover && !mouseDown) {
        // Mouse moved out of bounds, clear hover preview.
        onDrawCancel();
      }

      return;
    }

    if (mouseDown) {
      // Draw preview. Note `onDrawMove()` only fires one draw event per cell.
      onDrawMove(coordinates);
      return;
    }

    const [ hoverX, hoverY ] = hover || [];
    const [ previewX, previewY ] = coordinates;

    if (hoverX === previewX && hoverY === previewY) {
      // No change in hover position
      return;
    }

    // Hover preview

    if (activeDrawOption) {
      const previewCoordinates = [ coordinates ];

      activeTool === TOOL.Draw
        ? onDraw(previewCoordinates, activeDrawOption)
        : onErase(previewCoordinates, activeDrawOption);
    }
  }, [
    activeDrawOption,
    activeTool,
    getCellCoordinates,
    hover,
    matrixHeight,
    matrixWidth,
    mouseDown,
    onDrawCancel,
    onDraw,
    onDrawMove,
    onErase,
    onHoverMove,
  ]);

  // -- Keyboard Events --------------------------------------------------------

  /**
   * Handles keyboard cancel events.
   */
  const onKeyboardCancel = useCallback(() => {
    if (activeDrawOption) {
      setMouseDown(null);
      onDrawCancel();

      if (hover) {
        activeTool === TOOL.Draw
          ? onDraw([ hover ], activeDrawOption)
          : onErase([ hover ], activeDrawOption);
      }
    }
  }, [
    activeDrawOption,
    activeTool,
    hover,
    onDraw,
    onDrawCancel,
    onErase,
  ]);

  useMapKeyboard({
    activeBrush,
    activeTool,
    isMouseDown: Boolean(mouseDown),
    onCancel: onKeyboardCancel,
    onChangeBrush,
    onChangeTool,
    onMouseLeave,
    onRedo,
    onResetView,
    onUndo,
    onZoom: onZoomKey,
  });

  // -- Return -----------------------------------------------------------------

  return useMemo(() => ({
    activeDrawOption,
    activeTool,
    availableDrawOptions,
    cursor,
    draw,
    highlightRegionId,
    hoverPreview,
    minScale,
    onChangeDrawOption,
    onChangeScale,
    onDragBound,
    onDragEnd,
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onResetView,
    onWheel,
    stagePosition,
    stageScale,
  }), [
    activeDrawOption,
    activeTool,
    availableDrawOptions,
    cursor,
    draw,
    highlightRegionId,
    hoverPreview,
    minScale,
    onChangeDrawOption,
    onChangeScale,
    onDragBound,
    onDragEnd,
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    onResetView,
    onWheel,
    stagePosition,
    stageScale,
  ]);
}

/**
 * Handles stage draw and erase events.
 *
 * TDL throttle fast mouse movements? and/or fill in the gaps?
 */
function useDraw({
  activeDrawOption,
  activeTool,
  onDraw,
  onErase,
}: {
  activeDrawOption?: DRAW_OPTION;
  activeTool: TOOL;
  onDraw: OnDraw;
  onErase: OnErase;
}): {
  draw: Coordinates[];
  onDrawCancel: () => void;
  onDrawCommit: () => void;
  onDrawMove: (coordinates: Coordinates) => void;
  onDrawStart: (coordinates: Coordinates) => void;
} {
  const [ draw, setDraw ] = useState<CoordinatesMap>(new Map());

  const onManipulate = useCallback((coordinates: CoordinatesMap, options?: { commit: true }) => {
    if (activeDrawOption) {
      setDraw(coordinates);

      const drawCoordinates = [ ...coordinates.values() ];

      activeTool === TOOL.Draw
        ? onDraw(drawCoordinates, activeDrawOption, options)
        : onErase(drawCoordinates, activeDrawOption, options);
    }
  }, [
    activeDrawOption,
    activeTool,
    onDraw,
    onErase,
  ]);

  /**
   * Initiates a draw event.
   */
  const onDrawStart = useCallback(([ x, y ]: Coordinates) => {
    const key: CoordinatesKey = `${x},${y}`;
    onManipulate(new Map([[ key, [ x, y ]]]));
  }, [ onManipulate ]);

  /**
   * Handles draw move events.
   */
  const onDrawMove = useCallback((coordinates: Coordinates) => {
    if (activeDrawOption === DRAW_OPTION.Stamp) {
      return;
    }

    const [ x, y ] = coordinates;
    const key: CoordinatesKey = `${x},${y}`;

    if (activeDrawOption === DRAW_OPTION.Rectangle || activeDrawOption === DRAW_OPTION.Line) {
      if ([ ...draw.keys() ].pop() === key) {
        return;
      }

      const [ start ] = draw;

      onManipulate(new Map([ start, [ key, coordinates ]]));
      return;
    }

    if (draw.has(key)) {
      return;
    }

    onManipulate(new Map(draw.set(key, coordinates)));
  }, [ activeDrawOption, draw, onManipulate ]);

  /**
   * Finalizes a draw or erase event.
   */
  const onDrawCommit = useCallback(() => {
    onManipulate(draw, { commit: true });
    setDraw(new Map());
  }, [ draw, onManipulate ]);

  /**
   * Cancels a draw or erase event.
   */
  const onDrawCancel = useCallback(() => {
    const emptyMap = new Map();
    onManipulate(emptyMap);
    setDraw(emptyMap);
  }, [ onManipulate ]);

  return useMemo(() => ({
    draw: [ ...draw.values() ],
    onDrawCancel,
    onDrawCommit,
    onDrawMove,
    onDrawStart,
  }), [
    draw,
    onDrawCancel,
    onDrawCommit,
    onDrawMove,
    onDrawStart,
  ]);
}

/**
 * Returns the active draw option based on the active brush and active tool,
 * and a draw option state setter.
 */
function useDrawOption({
  activeBrush,
  activeTool,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
}): {
  activeDrawOption?: DRAW_OPTION;
  availableDrawOptions?: DRAW_OPTION[];
  setDrawOption?: (drawOption: DRAW_OPTION) => void;
} {
  const [ drawOption, setDrawOption ] = useState<DRAW_OPTION>(DRAW_OPTION.Rectangle);

  if (!drawTools.has(activeTool)) {
    return {};
  }

  const availableDrawOptions = activeTool === TOOL.Erase
    ? drawOptionsErase
    : drawOptionsBrush[activeBrush];

  const activeDrawOption = availableDrawOptions.includes(drawOption)
    ? drawOption
    : availableDrawOptions[0];

  return {
    activeDrawOption,
    availableDrawOptions,
    setDrawOption,
  };
}

/**
 * Handles stage hover events.
 *
 * TDL highlight entire connection when hovering a connection with an area
 * tool.
 */
function useHover({ matrixHeight, matrixWidth }: {
  matrixHeight: number;
  matrixWidth: number;
}): {
  hover?: Coordinates;
  onHoverMove: (coordinates: Coordinates) => void;
  onHoverOff: () => void;
} {
  const [ hover, setHover ] = useState<Coordinates | undefined>(undefined);

  /**
   * Handles hover move events.
   */
  const onHoverMove = useCallback((coordinates: Coordinates) => {
    const [ x, y ] = coordinates;

    if (!isInBounds(coordinates, matrixWidth, matrixHeight)) {
      if (hover) {
        setHover(undefined);
      }
      return;
    }

    const [ hoverX, hoverY ] = hover || [];

    if (x !== hoverX || y !== hoverY) {
      setHover(coordinates);
    }
  }, [ hover, matrixHeight, matrixWidth ]);

  /**
   * Hides the hover preview.
   */
  const onHoverOff = useCallback(() => {
    setHover(undefined);
  }, []);

  return useMemo(() => ({
    hover,
    onHoverMove,
    onHoverOff,
  }), [
    hover,
    onHoverMove,
    onHoverOff,
  ]);
}

/**
 * Returns the cursor type and valid cells for interactive previews.
 */
function usePreview({
  activeTool,
  hover,
  matrix,
  mouseDown,
}: {
  activeTool: TOOL;
  hover?: Coordinates;
  matrix: MatrixImmutable;
  mouseDown: Coordinates | null;
}): {
  cursor: Cursor;
  highlightRegionId?: CellValue;
  hoverPreview?: Coordinates;
} {
  if (activeTool === TOOL.Pan) {
    return { cursor: 'move' };
  }

  if (hover && activeTool === TOOL.Select) {
    const hoverCellValue = getCellValue(matrix, hover);

    return {
      cursor: hoverCellValue ? 'pointer' : 'default',
      highlightRegionId: mouseDown
        ? getCellValue(matrix, mouseDown)
        : hoverCellValue,
    };
  }

  if (drawTools.has(activeTool)) {
    return {
      cursor: hover ? 'cell' : 'default',
      hoverPreview: hover,
    };
  }

  return { cursor: 'default' };
}

/**
 * Handles stage zoom and position events.
 */
function useView({
  matrixHeight,
  matrixWidth,
  stageHeight,
  stageWidth,
}: {
  matrixHeight: number;
  matrixWidth: number;
  stageHeight: number;
  stageWidth: number;
}): {
  minScale: number;
  onChangePosition: (position: Konva.Vector2d) => void;
  onChangeScale: (newScale: number) => void;
  onDragBound: (position: Konva.Vector2d) => Konva.Vector2d;
  onResetView: () => void;
  onWheel: (e: Konva.KonvaEventObject<WheelEvent>) => void;
  onZoomKey: (zoom: 'in' | 'out') => void;
  stagePosition: { x: number; y: number };
  stageScale: number;
} {
  const minScale = useMemo(() => getStageMinScale(
    stageHeight,
    stageWidth,
    matrixHeight,
    matrixWidth
  ), [
    matrixHeight,
    matrixWidth,
    stageHeight,
    stageWidth,
  ]);

  // -- View Functions ---------------------------------------------------------

  /**
   * Calculates a scaled position for the stage and zoom target. The current
   * position and the zoom target defaults to the center of the stage.
   */
  const getScaledPosition = useCallback((
    currentScale: number,
    newScale: number,
    currentPosition: Konva.Vector2d,
    zoomTarget: Konva.Vector2d = {
      x: stageWidth  / 2,
      y: stageHeight / 2,
    }
  ): Konva.Vector2d => {
    const zoomTo = {
      x: (zoomTarget.x - currentPosition.x) / currentScale,
      y: (zoomTarget.y - currentPosition.y) / currentScale,
    };

    const newPosition = {
      x: zoomTarget.x - (zoomTo.x * newScale),
      y: zoomTarget.y - (zoomTo.y * newScale),
    };

    return newPosition;
  }, [
    stageHeight,
    stageWidth,
  ]);

  /**
   * Calculates the initial view position and scale.
   */
  const getInitialView = useCallback(() => {
    const initialScale = getStageBestFitScale(
      stageHeight,
      stageWidth,
      matrixHeight,
      matrixWidth
    );

    const matrixHeightPx = matrixHeight * cellPx;
    const matrixWidthPx = matrixWidth * cellPx;

    const initialPosition = {
      x: (stageWidth  / 2) - ((matrixWidthPx * initialScale) / 2),
      y: (stageHeight / 2) - ((matrixHeightPx * initialScale) / 2),
    };

    return {
      position: initialPosition,
      scale: initialScale,
    };
  }, [
    matrixHeight,
    matrixWidth,
    stageHeight,
    stageWidth,
  ]);

  // -- State ------------------------------------------------------------------

  const [{ position, scale }, setView ] = useState<StageView>(getInitialView());

  // -- View Events -----------------------------------------------------------

  /**
   * Handles zoom in and out events.
   */
  const onZoom = useCallback((
    zoom: 'in' | 'out',
    scaleBy: number,
    pointerPosition?: Konva.Vector2d
  ) => {
    if ((zoom === 'in' && scale === maxScale) || (zoom === 'out' && scale === minScale)) {
      // At min or max scale, noop.
      return;
    }

    const newScale = zoom === 'in'
      ? Math.min((scale * scaleBy), maxScale)
      : Math.max((scale / scaleBy), minScale);

    const scaledPosition = getScaledPosition(
      scale,
      newScale,
      position,
      pointerPosition
    );

    setView({
      position: applyStageBounds(
        scaledPosition,
        matrixWidth,
        matrixHeight,
        stageHeight,
        stageWidth,
        newScale
      ),
      scale: newScale,
    });
  }, [
    getScaledPosition,
    matrixHeight,
    matrixWidth,
    position,
    scale,
    stageHeight,
    stageWidth,
    minScale,
  ]);

  /**
   * Handles keyboard zoom.
   */
  const onZoomKey = useCallback((zoom: 'in' | 'out') => {
    onZoom(zoom, scaleZoomByKey);
  }, [ onZoom ]);

  /**
   * Handles stage mouse wheel and pinch to zoom events.
   *
   * TDL pinch zoom is too sensitive.
   */
  const onWheel = useCallback((event: Konva.KonvaEventObject<WheelEvent>) => {
    // Prevent default scrolling
    event.evt.preventDefault();

    const zoom = event.evt.deltaY > 0 ? 'out' : 'in';
    const pointerPosition = getPointerPosition(event);

    onZoom(zoom, scaleZoomByWheel, pointerPosition);
  }, [ onZoom ]);

  /**
   * Handles scale change events from the scale slider.
   */
  const onChangeScale = useCallback((newScale: number) => {
    setView({
      position: getScaledPosition(scale, newScale, position),
      scale: newScale,
    });
  }, [
    getScaledPosition,
    position,
    scale,
  ]);

  /**
   * Handles stage drag end events.
   */
  const onChangePosition = useCallback((newPosition: Konva.Vector2d) => {
    setView({
      position: newPosition,
      scale,
    });
  }, [ scale ]);

  /**
   * Prevents the stage from being dragged out of bounds.
   */
  const onDragBound = useCallback((newPosition: Konva.Vector2d): Konva.Vector2d => applyStageBounds(
    newPosition,
    matrixWidth,
    matrixHeight,
    stageHeight,
    stageWidth,
    scale
  ), [
    matrixHeight,
    matrixWidth,
    scale,
    stageHeight,
    stageWidth,
  ]);

  /**
   * Resets the stage view to the initial state.
   */
  const onResetView = useCallback(() => {
    setView(getInitialView());
  }, [ getInitialView ]);

  useEffect(() => {
    // Reset view when the matrix dimensions change.
    setView(getInitialView());
  }, [
    getInitialView,
    matrixHeight,
    matrixWidth,
  ]);

  return useMemo(() => ({
    minScale,
    onChangePosition,
    onChangeScale,
    onDragBound,
    onResetView,
    onWheel,
    onZoomKey,
    stagePosition: position,
    stageScale: scale,
  }), [
    onChangePosition,
    onChangeScale,
    onDragBound,
    onResetView,
    onWheel,
    onZoomKey,
    position,
    scale,
    minScale,
  ]);
}

// -- Private Functions --------------------------------------------------------

/**
 * Applies minimum and maximum stage position.
 */
function applyStageBounds(
  { x, y }: Konva.Vector2d,
  matrixWidth: number,
  matrixHeight: number,
  stageHeight: number,
  stageWidth: number,
  scale: number
): Konva.Vector2d {
  const matrixWidthPx = matrixWidth * cellPx * scale;
  const matrixHeightPx = matrixHeight * cellPx * scale;

  const halfStageWidth = Math.round(stageWidth / 2);
  const halfStageHeight = Math.round(stageHeight / 2);

  const minX = halfStageWidth - matrixWidthPx;
  const maxX = halfStageWidth;

  const minY = halfStageHeight - matrixHeightPx;
  const maxY = halfStageHeight;

  return {
    x: Math.max(minX, Math.min(maxX, x)),
    y: Math.max(minY, Math.min(maxY, y)),
  };
}

/**
 * Returns the minimum stage scale based on available stage space.
 */
function getStageMinScale(
  stageHeight: number,
  stageWidth: number,
  matrixHeight: number,
  matrixWidth: number
): number {
  const matrixHeightPx = matrixHeight * cellPx;
  const matrixWidthPx = matrixWidth * cellPx;

  const fitLandscapeScale = (stageWidth - minZoomPadding) / matrixWidthPx;
  const fitPortraitScale = (stageHeight - minZoomPadding) / matrixHeightPx;

  return Math.round(Math.min(fitLandscapeScale, fitPortraitScale) * 100) / 100;
}

/**
 * Returns the minimum stage scale based on available stage space.
 */
function getStageBestFitScale(
  stageHeight: number,
  stageWidth: number,
  matrixHeight: number,
  matrixWidth: number
): number {
  const matrixHeightPx = matrixHeight * cellPx;
  const matrixWidthPx = matrixWidth * cellPx;

  const fitLandscapeScale = (stageWidth - mapPadding) / matrixWidthPx;
  const fitPortraitScale = (stageHeight - mapPadding) / matrixHeightPx;

  return Math.min((Math.round(Math.min(fitLandscapeScale, fitPortraitScale) * 100) / 100), maxScale);
}

/**
 * Determines if the coordinates are in the matrix's bounds.
 */
function isInBounds([ x, y ]: Coordinates, matrixWidth: number, matrixHeight: number): boolean {
  return x >= 0 && x < matrixWidth && y >= 0 && y < matrixHeight;
}
