/**
 * Capitalizes the first letter in a string.
 */
export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Capitalizes each word in a string.
 */
export function capitalizeWords(words: string) {
  return words.split(' ').map((word) => capitalize(word)).join(' ');
}

/**
 * Converts spaces to dashes.
 */
export function toDash(text: string): string {
  return text.replace(/\s+/g, '-');
}

/**
 * Converts a camel case string to words.
 */
export function toWords(text: string): string {
  return text.replace(/([A-Z])/g, ' $1').trim();
}
