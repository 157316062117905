import { useEffect, useState } from 'react';

import { AREA, Brush, brushes, CONNECTION, DETAIL, TOOL } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Input tag names which ignore map keydown events when focused. */
const inputMatchers = [ 'input', 'textarea' ].join(', ');

/** The first area in the area palette. */
const [ firstArea ] = Object.values(AREA);

/** The first connection in the connection palette. */
const [ firstConnection ] = Object.values(CONNECTION);

/** The first detail in the detail palette. */
const [ firstDetail ] = Object.values(DETAIL);

// -- Public Hook --------------------------------------------------------------

/**
 * Handles map keyboard events.
 *
 * TDL delete selected
 */
export default function useMapKeyboard({
  activeBrush,
  activeTool,
  isMouseDown,
  onCancel,
  onChangeBrush,
  onChangeTool,
  onMouseLeave,
  onRedo,
  onResetView,
  onUndo,
  onZoom,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
  isMouseDown: boolean;
  onCancel: () => void;
  onChangeBrush: (brush: Brush) => void;
  onChangeTool: (tool: TOOL) => void;
  onMouseLeave: () => void;
  onRedo?: () => void;
  onResetView: () => void;
  onUndo?: () => void;
  onZoom: (zoom: 'in' | 'out') => void;
}) {
  const [ previousTool, setPreviousTool ] = useState(activeTool);

  useEffect(() => {
    /** Handles key down events. */
    function onKeyDown(event: KeyboardEvent) {
      event.stopImmediatePropagation();

      const {
        ctrlKey,
        key,
        metaKey,
        repeat,
        shiftKey,
        target,
      } = event;

      if (target instanceof Element && target.matches(inputMatchers)) {
        return;
      }

      if (key === '=' || key === '+') {
        onZoom('in');
        return;
      }

      if (key === '-') {
        onZoom('out');
        return;
      }

      if (repeat) {
        return;
      }

      if (key === 'Escape') {
        onCancel();
        return;
      }

      if (isMouseDown) {
        return;
      }

      if (key === 'd') {
        onChangeTool(TOOL.Draw);
        return;
      }

      if (key === 'e') {
        onChangeTool(TOOL.Erase);
        return;
      }

      if (key === 's') {
        onChangeTool(TOOL.Select);
        return;
      }

      if (key === 'v') {
        onChangeTool(TOOL.Pan);
        return;
      }

      if (key === ' ') {
        setPreviousTool(activeTool);
        onChangeTool(TOOL.Pan);
        return;
      }

      if (key === 'a') {
        onChangeBrush(firstArea);
        return;
      }

      if (key === 'c') {
        onChangeBrush(firstConnection);
        return;
      }

      if (key === 'x') {
        onChangeBrush(firstDetail);
        return;
      }

      if (key === 'f') {
        onResetView();
        return;
      }

      if (activeTool !== TOOL.Select) {
        if (key === 'z' && (ctrlKey || metaKey) && shiftKey) {
          onRedo?.();
          return;
        }

        if (key === 'z' && (ctrlKey || metaKey)) {
          onUndo?.();
          return;
        }
      }

      if ((key === 'ArrowDown' || key === 'ArrowUp') && activeTool !== TOOL.Draw) {
        onChangeBrush(brushes[0]);
        return;
      }

      if (key === 'ArrowDown') {
        const index = brushes.indexOf(activeBrush);
        const newBrush = index === brushes.length - 1
          ? brushes[0]
          : brushes[index + 1];

        onChangeBrush(newBrush);
        return;
      }

      if (key === 'ArrowUp') {
        const index = brushes.indexOf(activeBrush);
        const newBrush = index === 0
          ? brushes[brushes.length - 1]
          : brushes[index - 1];

        onChangeBrush(newBrush);
        return;
      }
    }

    /** Handles key up events. */
    function onKeyUp(event: KeyboardEvent) {
      if (event.target instanceof Element && event.target.matches(inputMatchers)) {
        return;
      }

      if (event.repeat) {
        return;
      }

      if (event.key === ' ') {
        isMouseDown && onMouseLeave();
        onChangeTool(previousTool);
      }
    }

    globalThis.document.addEventListener('keydown', onKeyDown);
    globalThis.document.addEventListener('keyup', onKeyUp);

    return () => {
      globalThis.document.removeEventListener('keydown', onKeyDown);
      globalThis.document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    activeTool,
    activeBrush,
    isMouseDown,
    onCancel,
    onChangeBrush,
    onChangeTool,
    onMouseLeave,
    onRedo,
    onResetView,
    onUndo,
    onZoom,
    previousTool,
  ]);
}
