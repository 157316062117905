import {
  diagonalOffsets,
  offsets,
  orthogonalOffsetMap,
  orthogonalOffsets,
  toCoordinatesMap,
} from './utility';
import { getOrSetMapEntry } from '..';

import type {
  Coordinates,
  CoordinatesKey,
  CoordinatesMap,
  DirectionCardinal,
  DirectionMeridian,
  DirectionOrdinal,
  DirectionParallel,
} from '.';

// -- Types --------------------------------------------------------------------

/** The results of an area analysis. */
export type AreaAnalysis = {
  heatGroups: HeatGroups;
  heatMap: HeatMap;
};

type HeatLevel = typeof heatLevel[keyof typeof heatLevel];

/**
 * A map of cell heat levels, keyed by their coordinates keys. The more interior
 * a cell is, the higher its heat score.
 */
type HeatMap = Map<CoordinatesKey, HeatLevel>;

/** A map of coordinates maps, keyed by their heat score. */
type HeatGroups = Map<HeatLevel, CoordinatesMap>;

// -- Config --------------------------------------------------------------------

export const heatLevel: {
  /* eslint-disable typescript-sort-keys/interface */
  none: -1;
  deadEnd: 0;
  hallway: 1;
  corner: 2;
  perimeter: 3;
  mid: 4;
  corePerimeter: 5;
  core: 6;
  /* eslint-enable typescript-sort-keys/interface */
} = {
  /* eslint-disable sort-keys */
  none: -1,
  deadEnd: 0,
  hallway: 1,
  corner: 2,
  perimeter: 3,
  mid: 4,
  corePerimeter: 5,
  core: 6,
  /* eslint-enable sort-keys */
};

// -- Public Functions ---------------------------------------------------------

/**
 * Analyzes an area's coordinates and returns a heat map with interior cells
 * scoring higher, 2 levels of inner cell groups, and an array of perimeter
 * cells.
 *
 * TDL needs to accommodate scoring cells adjacent to connections.
 * TDL tests
 */
export function analyzeArea(coordinates: Coordinates[]): AreaAnalysis {
  const coordinatesMap = toCoordinatesMap(coordinates);
  const {
    heatGroups,
    heatMap,
  } = getHeatMap(coordinatesMap);

  return {
    heatGroups,
    heatMap,
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Analyzes an area's coordinates and returns a heat map with interior cells
 * scoring higher.
 */
function getHeatMap(coordinatesMap: CoordinatesMap): {
  heatGroups: HeatGroups;
  heatMap: HeatMap;
} {
  const heatGroups: HeatGroups = new Map();
  const heatMap: HeatMap = new Map();

  // Calculate and set initial heat.

  for (const cellCoordinates of coordinatesMap.values()) {
    const [ x, y ] = cellCoordinates;
    const coordinatesKey: CoordinatesKey = `${x},${y}`;

    let heat: HeatLevel = 0;

    for (const [ xOffset, yOffset ] of orthogonalOffsets) {
      if (coordinatesMap.has(`${x + xOffset},${y + yOffset}`)) {
        heat += 1;
      }
    }

    switch (heat) {
      case 1: {
        heat = heatLevel.deadEnd;
        break;
      }

      case 2: {
        const orientation = getCellOrientation(cellCoordinates, coordinatesMap);

        heat = orientation === 'east-west' || orientation === 'north-south'
          ? heatLevel.hallway
          : heatLevel.corner;
        break;
      }

      case 4: {
        for (const [ xOffset, yOffset ] of diagonalOffsets) {
          if (!coordinatesMap.has(`${x + xOffset},${y + yOffset}`)) {
            heat = heatLevel.none;
            break;
          }
        }
        break;
      }
    }

    getOrSetMapEntry<HeatLevel, CoordinatesMap>(heatGroups, heat as HeatLevel, new Map())
      .set(coordinatesKey, cellCoordinates);

    heatMap.set(coordinatesKey, heat as HeatLevel);
  }

  const mids = heatGroups.get(heatLevel.mid);

  if (!mids) {
    return {
      heatGroups,
      heatMap,
    };
  }

  // Second pass to detect and set core & core perimeter cells.

  heatGroups.delete(heatLevel.mid); // Clear initial interior cell calculation.

  let corePerimeter: CoordinatesMap = new Map();

  for (const [ coordinatesKey, cellCoordinates ] of mids) {
    const [ x, y ] = cellCoordinates;
    const perimeterMap: CoordinatesMap = new Map();

    let isCore = true;

    for (const [ xOffset, yOffset ] of offsets) {
      const offsetCoordinates: Coordinates = [ x + xOffset, y + yOffset ];
      const [ x2, y2 ] = offsetCoordinates;
      const offsetCoordinatesKey: CoordinatesKey = `${x2},${y2}`;

      if (!mids.has(offsetCoordinatesKey)) {
        isCore = false;
        break;
      }

      if (!heatGroups.get(heatLevel.core)?.has(offsetCoordinatesKey)) {
        perimeterMap.set(offsetCoordinatesKey, offsetCoordinates);
      }
    }

    if (isCore) {
      getOrSetMapEntry<HeatLevel, CoordinatesMap>(heatGroups, heatLevel.core, new Map())
        .set(coordinatesKey, cellCoordinates);

      heatMap.set(coordinatesKey, heatLevel.core);

      corePerimeter.delete(coordinatesKey);
      corePerimeter = new Map([ ...corePerimeter, ...perimeterMap ]);
      continue;
    }

    getOrSetMapEntry<HeatLevel, CoordinatesMap>(heatGroups, heatLevel.mid, new Map())
      .set(coordinatesKey, cellCoordinates);

    heatMap.set(coordinatesKey, heatLevel.mid);
  }

  heatGroups.set(heatLevel.corePerimeter, corePerimeter);

  for (const coordinatesKey of corePerimeter.keys()) {
    heatMap.set(coordinatesKey, heatLevel.corePerimeter);
  }

  return {
    heatGroups,
    heatMap,
  };
}

/**
 * Returns an edge cell's orientation. Corner cells have an ordinal orientation
 * (north-east, south-west, etc) while hallway cells have a parallel (east-west)
 * or meridian (north-south) orientation.
 */
function getCellOrientation(
  coordinates: Coordinates,
  coordinatesMap: CoordinatesMap
): DirectionMeridian | DirectionParallel | DirectionOrdinal {
  const [ x, y ] = coordinates;
  const adjacentCells: Set<DirectionCardinal> = new Set();

  for (const [ direction, [ xOffset, yOffset ]] of orthogonalOffsetMap) {
    if (!coordinatesMap.has(`${x + xOffset},${y + yOffset}`)) {
      continue;
    }

    adjacentCells.add(direction);
  }

  if (adjacentCells.size !== 2) {
    throw new TypeError(`Cell at "${x},${y}" is not an edge cell in getCellOrientation()`);
  }

  if (adjacentCells.has('north') && adjacentCells.has('south')) {
    return 'north-south';
  }

  if (adjacentCells.has('east') && adjacentCells.has('west')) {
    return 'east-west';
  }

  if (adjacentCells.has('east') && adjacentCells.has('south')) {
    return 'north-west';
  }

  if (adjacentCells.has('west') && adjacentCells.has('south')) {
    return 'north-east';
  }

  if (adjacentCells.has('north') && adjacentCells.has('west')) {
    return 'south-east';
  }

  if (adjacentCells.has('north') && adjacentCells.has('east')) {
    return 'south-west';
  }

  throw new TypeError(`Cell at "${x},${y}" has an invalid orientation in getCellOrientation()`);
}
