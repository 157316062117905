import { memo } from 'react';
import {
  Circle,
  Group,
  Star,
  Text,
} from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentStrokePx,
  fontFamilyTitle,
} from '../../../config/map';

// -- Config -------------------------------------------------------------------

/** The width & height of the compass rose's bounding box. */
const size = cellPx * 2.5;

/** Inner radius of the outer ordinal. */
const starCardinalInnerRadius = size / 10;

/** Outer radius of the outer ordinal. */
const starCardinalOuterRadius = size / 2;

/** Center coordinates relative to the compass rose's bounding box. */
const starCenterXy = size / 2;

/** Inner radius of the outer ordinal. */
const starOrdinalInnerRadius = size / 8;

/** Outer radius of the outer ordinal. */
const starOrdinalOuterRadius = size / 2.75;

/** Radius of the compass roses's circle. */
const starCircleRadius = size / 4;

/** X position of the compass rose's bounding box. */
const x = cellPx * 0.75;

/** Y position of the compass rose's bounding box. */
const y = cellPx * 1.5;

/** Font size of compass rose's direction indicator. */
const textSize = size / 5;

/** Y position of the compass rose's direction indicator. */
const textY = textSize * -1.4;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a compass rose.
 */
export default memo(function CompassRose() {
  return (
    <Group
      data-id="map-compass-rose"
      height={size}
      width={size}
      x={x}
      y={y}
    >
      <Circle
        fill={colors.shadow}
        radius={starCircleRadius}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterXy}
        y={starCenterXy}
      />

      <Star
        fill={colors.background}
        innerRadius={starOrdinalInnerRadius}
        lineJoin="round"
        numPoints={4}
        outerRadius={starOrdinalOuterRadius}
        rotation={45}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterXy}
        y={starCenterXy}
      />

      <Star
        fill={colors.background}
        innerRadius={starCardinalInnerRadius}
        lineJoin="round"
        numPoints={4}
        outerRadius={starCardinalOuterRadius}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        x={starCenterXy}
        y={starCenterXy}
      />

      <Text
        align="center"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={textSize}
        fontStyle="600"
        height={textSize}
        text="N"
        verticalAlign="top"
        width={size}
        x={0}
        y={textY}
      />
    </Group>
  );
});
