import { memo } from 'react';
import {
  Group,
  Rect as Rectangle,
  Text,
} from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentStrokePx,
  fontFamilyTitle,
} from '../../../config/map';

// -- Config -------------------------------------------------------------------

/** The height the legends's bounding box. */
const height = cellPx / 4;

/** The width of the legends's bounding box. */
const width = cellPx * 2;

/** X position of the legends's bounding box. */
const x = cellPx;

/** Y position of the legends's bounding box. */
const y = cellPx * 5;

/** Font size of the legends's unit labels. */
const textSize = cellPx / 2;

/** X offset of the legends's unit labels. */
const textOffsetX = textSize * -0.5;

/** Font size of the legends's "Feet" label. */
const textFeetLabelSize = cellPx / 3;

/** Y offset of the legends's "Feet" label. */
const textFeetLabelOffsetY = textFeetLabelSize * 1.25;

/** Legend unit label props. */
const textProps = {
  align: 'center',
  fill: colors.label,
  fontFamily: fontFamilyTitle,
  fontSize: textSize,
  fontStyle: '600',
  height: textSize,
  verticalAlign: 'top',
  width: textSize,
  y: textSize * -1.2,
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders a distance legend.
 */
export default memo(function DistanceLegend() {
  return (
    <Group
      data-id="map-distance-legend"
      height={height}
      width={width}
      x={x}
      y={y}
    >
      <Rectangle
        fill={colors.background}
        height={height}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={cellPx}
        x={0}
        y={0}
      />

      <Rectangle
        fill={colors.shadow}
        height={height}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={cellPx}
        x={cellPx}
        y={0}
      />

      <Text
        {...textProps}
        text="0"
        x={textOffsetX}
      />

      <Text
        {...textProps}
        text="5"
        x={cellPx + textOffsetX}
      />

      <Text
        {...textProps}
        text="10"
        x={(cellPx * 2) + textOffsetX}
      />

      <Text
        align="center"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={textFeetLabelSize}
        fontStyle="600"
        height={textFeetLabelSize}
        letterSpacing={2}
        text="Feet"
        verticalAlign="top"
        width={width}
        x={0}
        y={textFeetLabelOffsetY}
      />
    </Group>
  );
});
