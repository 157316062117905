import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import styles from './LootTable.module.css';
import { capitalize, toWords } from '../../lib/string';

import type { Item } from '../../lib/generate/item';

// -- Config -------------------------------------------------------------------

/** Props for loot info cells. */
const lootInfoCellProps = {
  color: 'text.muted',
  fontSize: 'body2.fontSize',
};

/** Count column table cell styles. */
const countCellSx = { pr: 0 };

/** Loot column table cell styles. */
const lootCellSx = { minWidth: 200, pl: 0 };

// -- Public Component ---------------------------------------------------------

/**
 * Renders a loot table for the given items.
 */
export default function LootTable({
  items,
  label,
  showCondition,
}: {
  items: Item[];
  label: string;
  showCondition: boolean;
}) {
  return (
    <TableContainer className={styles.tableContainer}>
      <Table
        aria-label={label}
        stickyHeader
      >
        <TableHead>
          <TableRow>

            <TableCell sx={countCellSx}>
              <Box sx={visuallyHidden}>
                Count
              </Box>
            </TableCell>

            <TableCell sx={lootCellSx}>
              Loot
            </TableCell>

            <TableCell>Size</TableCell>

            <TableCell>Rarity</TableCell>

            {showCondition && <TableCell>Condition</TableCell>}

          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((item) => (
            <TableRow key={item.name}>
              <TableCell
                align="center"
                sx={countCellSx}
              >
                {item.count > 1 &&
                  <>
                    {item.count}
                    <span
                      aria-hidden
                      className={styles.itemCountMultiplication}
                    >
                      ×
                    </span>
                  </>
                }
              </TableCell>

              <TableCell sx={lootCellSx}>
                {item.variant
                  ? `${item.name}, ${item.variant}`
                  : item.name
                }
              </TableCell>

              <TableCell>
                <Typography {...lootInfoCellProps}>
                  {capitalize(item.size)}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography {...lootInfoCellProps}>
                  {capitalize(toWords(item.rarity))}
                </Typography>
              </TableCell>

              {showCondition &&
                <TableCell>
                  <Typography {...lootInfoCellProps}>
                    {capitalize(item.condition)}
                  </Typography>
                </TableCell>
              }

            </TableRow>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  );
}
