import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'tools',
};

const tools: { [name: string]: Partial<LootConfig> | null } = {
  "Alchemist's supplies": { rarity: 'veryRare' },
  'Ball bearings': { maxCount: 100, minCount: 10, rarity: 'uncommon', variants: [ 'pouch', 'jar', 'scattered' ] },
  'Block and tackle': { size: 'medium'  },
  "Brewer's supplies": { rarity: 'uncommon'  },
  "Calligrapher's supplies": { rarity: 'rare' },
  "Carpenter's tools": { rarity: 'common' },
  "Cartographer's tools": { rarity: 'rare' },
  'Case, crossbow bolt': null,
  Chain: { variants: [ 'heavy', 'light' ] },
  "Cobbler's tools": null,
  Compass: {  rarity: 'veryRare', size: 'tiny' },
  "Cook's utensils": null,
  Crowbar: {  size: 'medium'  },
  'Dice set': { variants: [ 'bone', 'wood', 'ivory' ] },
  'Disguise kit': { rarity: 'rare' },
  'Dragonchess set': { rarity: 'rare' },
  'Forgery kit': { rarity: 'veryRare' },
  "Glassblower's tools": {  rarity: 'rare' },
  'Grappling hook': {  rarity: 'uncommon'  },
  Hammer: null,
  'Hammer, sledge': { size: 'large'  },
  "Healer's kit": { rarity: 'rare' },
  'Herbalism kit': { rarity: 'rare' },
  "Jeweler's tools": { rarity: 'rare' },
  Ladder: { size: 'large', variants: [ 'stepping', '5 foot', '10 foot', '20 foot' ] },
  'Lantern, bullseye': null,
  'Lantern, hooded': null,
  "Leatherworker's tools": null,
  Lock: { variants: [ 'door', 'bolt', 'combination' ] },
  'Magnifying glass': { rarity: 'veryRare' },
  "Mason's tools": null,
  'Mirror, steel': { rarity: 'uncommon'  },
  "Navigator's tools": { rarity: 'uncommon'  },
  "Painter's supplies": null,
  "Pick, miner's": { size: 'medium'  },
  'Playing card set': null,
  "Poisoner's kit": { rarity: 'veryRare' },
  Pole: { size: 'large', variants: [ '5 foot', '10 foot', '20 foot' ] },
  'Pole, 1 foot': null,
  "Potter's tools": null,
  Quiver: null,
  'Ram, portable': { size: 'large'  },
  'Rope, hempen': { size: 'medium', variants: [ '10 feet', '25 feet', '50 feet', '100 feet' ] },
  'Rope, silk': { size: 'medium', variants: [ '10 feet', '25 feet', '50 feet', '100 feet' ] },
  'Sewing kit': { size: 'tiny' },
  Shovel: { size: 'medium'  },
  "Smith's tools": { rarity: 'uncommon'  },
  'Spikes, iron': { maxCount: 100 },
  Spyglass: { rarity: 'veryRare' },
  "Thieves' tools": { rarity: 'rare' },
  'Three-Dragon Antet set': { rarity: 'rare' },
  "Tinker's tools": { rarity: 'rare' },
  "Weaver's tools": null,
  Whetstone: { rarity: 'uncommon'  },
  'Woodcarving tools': null,
  'Wooden staff': { size: 'medium'  },
};

const configs: LootConfigIncomplete[] = Object.entries(tools).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
