import { Fragment } from 'react';
import { Line } from 'react-konva';

import AreaLabel from './Area/Label';
import Connection from './Connection';
import Detail from './Detail';
import useRegionPaths from './hooks/useRegionPaths';
import RegionShape from './RegionShape';
import {
  areaBorderPx,
  areaShadowPx,
  colors,
  shadowOffset,
} from '../../config/map';

import type { MapInfo, Theme } from '../../lib/map';
import type { MatrixInstructions } from '../../lib/matrix';

// -- Public Component ---------------------------------------------------------

/**
 * Renders all gird areas, connections, shadows, and details.
 */
export default function Regions({
  areaInfo,
  areas,
  connectionInfo,
  connections,
  theme,
}: {
  theme: Theme;
} & MatrixInstructions
& Pick<MapInfo, 'areaInfo' | 'connectionInfo'>) {
  const {
    areaBorders,
    areaPaths,
    connectionBorders,
    connectionPaths,
    shadowPaths,
  } = useRegionPaths({ areas, connections });

  const borderPaths = [ ...areaBorders, ...connectionBorders ];
  const regionPaths = areaPaths;

  if (connectionPaths.length) {
    regionPaths.push(connectionPaths);
  }

  return (
    <>
      {shadowPaths.map((paths, i) => (
        <RegionShape
          fill={colors.shadow}
          key={i}
          paths={paths}
        />
      ))}

      {regionPaths.map((paths, i) => (
        <RegionShape
          fill={colors.regionDungeon}
          key={i}
          paths={paths}
        />
      ))}

      {borderPaths.map((paths, i) => paths.map((segment, j) => (
        <Line
          key={`${i}-${j}`}
          lineCap="square"
          offsetX={shadowOffset}
          offsetY={shadowOffset}
          points={segment.flat()}
          stroke={colors.shadow}
          strokeWidth={areaShadowPx}
        />
      )))}

      {[ ...areas.values() ].map(({ areaId, details, label, seed }, i) => (
        <Fragment key={i}>
          {details &&
            <Detail
              details={details}
              key={i}
              seed={seed}
              theme={theme}
            />
          }

          {label &&
            <AreaLabel
              {...label}
              title={areaInfo[areaId]?.title}
            />
          }
        </Fragment>
      ))}

      {borderPaths.map((paths, i) => paths.map((segment, j) => (
        <Line
          key={`${i}-${j}`}
          lineCap="round"
          lineJoin="round"
          points={segment.flat()}
          stroke={colors.border}
          strokeWidth={areaBorderPx}
        />
      )))}

      {[ ...connections.values() ].map((connectionInstructions, i) => {
        const info = connectionInfo[connectionInstructions.connectionId];

        return (
          <Connection
            key={i}
            locked={info?.locked}
            {...connectionInstructions}
          />
        );
      })}
    </>
  );
}
