import { Stack, Typography } from '@mui/material';

import styles from './styles.module.css';
import CenterContent from '../../components/Display/CenterContent';
import {
  Bottle as BottleIcon,
  Door as DoorIcon,
  Map as MapIcon,
} from '../../components/Display/Icons';
import Logo from '../../components/Display/Logo';
import WarningBox from '../../components/Display/WarningBox';
import WidowFix from '../../components/Display/WidowFix';
import BigJuicyButton from '../../components/Interface/BigJuicyButton';
import Link from '../../components/Interface/Link';
import Layout from '../../components/Layout';
import Metadata from '../../components/Utility/Metadata';
import { appTitle, discordInviteUrl, v1Url } from '../../config';

/**
 * Renders the homepage.
 */
export default function HomePage() {
  return (
    <>
      <Metadata
        description="D&D dungeon, map, and loot generator for TTRPG games. Draw a map from scratch or generate one instantly, then edit every detail."
        title="D&D Generator"
      />

      <Layout>
        <CenterContent>
          <div className={styles.content}>

            <Logo className={styles.logo} />

            <Typography
              component="h1"
              fontWeight="600"
              marginTop={1}
              variant="h4"
            >
              {appTitle}
            </Typography>

            <Typography
              component="h2"
              fontSize="1.25rem"
              marginBottom={1}
              marginTop={1}
              variant="h4"
            >
              <WidowFix>
                Welcome to Mystic Waffle, D&D / TTRPG dungeon map generator, map drawing app, and loot randomizer.
              </WidowFix>
            </Typography>

            <Typography
              color="text.muted"
              component="p"
              marginY={1}
              variant="body1"
            >
              Choose a generator then click <em className="emphasize">Generate</em> to instantly generate content for your TTRPG games.
              All content, including map images, area descriptions, and generated loot is copyright free for your personal and commercial use.
            </Typography>

            <Stack
              className={styles.buttons}
              direction="row"
              mb={4}
              mt={4}
              spacing={4}
              useFlexGap
            >
              <BigJuicyButton
                icon={MapIcon}
                label="Create Maps"
                to="/maps"
              />

              <BigJuicyButton
                icon={DoorIcon}
                label="Create Areas"
                to="/areas"
              />

              <BigJuicyButton
                icon={BottleIcon}
                label="Create Loot"
                to="/loot"
              />
            </Stack>

            <WarningBox
              className={styles.warning}
              component="aside"
            >
              <p>
                D&D Generator is in perpetual Beta; we intend to add new features indefinitely.
                Join our <Link href={discordInviteUrl}>Discord</Link> to suggest new features and get updates.
              </p>
            </WarningBox>

            <Typography
              color="text.muted"
              component="p"
              marginTop={1}
              variant="body1"
            >
              D&D Generator v2 builds on top of the procedural generation algorithm developed in the <Link href={v1Url}>Alpha v1 dungeon generator</Link>,
              but boasts a complete suite for drawing and editing dungeon maps, improved generator options, and more loot.
            </Typography>

          </div>
        </CenterContent>
      </Layout>
    </>
  );
}
