import Footer from './Footer';
import Header from './Header';
import styles from './index.module.css';
import Sidebar from './Sidebar';

/**
 * Renders the application layout.
 */
export default function Layout({
  children,
  sidebar,
}: {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}) {
  return (
    <div
      className={styles.layout}
      data-sidebar={Boolean(sidebar) || null}
    >
      <Header className={styles.header} />

      {sidebar &&
        <Sidebar className={styles.sidebar}>
          {sidebar}
        </Sidebar>
      }

      <main className={styles.main}>
        {children}
      </main>

      <Footer className={`${styles.footer} hide-sm`}/>
    </div>
  );
}
