import { Button, Divider, Drawer as MuiDrawer } from '@mui/material';
import { useState } from 'react';

import styles from './Drawer.module.css';
import Footer from './Footer';
import MainNav from './MainNav';
import {
  Close as CloseIcon,
  DotsVertical as DotsVerticalIcon,
} from '../Display/Icons';

// -- Config -------------------------------------------------------------------

const drawerButtonSx = {
  borderRadius: '50%',
  minWidth: 'auto',
  padding: '0.25rem 0.25rem',
};

const drawerPaperProps = {
  sx: { background: 'var(--color-paper-accent)' },
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders the application's mobile navigation drawer.
 */
export default function Drawer({ className }: { className: string }) {
  const [ isOpen, setIsOpen ] = useState(false);

  /** Opens the drawer. */
  function open() {
    setIsOpen(true);
  }

  /** Closes the drawer. */
  function close() {
    setIsOpen(false);
  }

  return (
    <div className={className}>
      <Button
        aria-label="Open navigation"
        onClick={open}
        size="large"
        sx={drawerButtonSx}
        variant="outlined"
      >
        <DotsVerticalIcon aria-hidden className={styles.buttonIcon} />
      </Button>

      <MuiDrawer
        anchor="right"
        onClose={close}
        open={isOpen}
        PaperProps={drawerPaperProps}
      >
        <div className={styles.drawerHeader}>
          <Button
            aria-label="Close navigation"
            onClick={close}
            size="large"
            sx={drawerButtonSx}
          >
            <CloseIcon aria-hidden className={styles.buttonIcon} />
          </Button>
        </div>

        <Divider />

        <div className={styles.content}>
          <MainNav className={styles.mainNav} />
          <Footer className={styles.footer} />
        </div>
      </MuiDrawer>
    </div>
  );
}
