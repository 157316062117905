import { Alert as AlertIcon } from './Icons';
import styles from './WarningBox.module.css';

/**
 * Renders a warning box.
 */
export default function WarningBox({
  children,
  className = '',
  component: Component = 'div',
  role,
}: {
  children: React.ReactNode;
  className?: string;
  component?: 'aside' | 'div';
  role?: 'alert';
}) {
  return (
    <Component className={`${styles.warning} ${className}`} role={role}>
      <AlertIcon
        aria-hidden
        className={styles.icon}
      />

      {children}
    </Component>
  );
}
