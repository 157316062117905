/** Application colors */
export const appColors = {
  background: '#0c0f0f',
  backgroundMapCanvas: '#e1e1e1',
  divider: '#424242',
  error: '#f44336',
  info: '#276091',
  neutral: '#9e9e9e',
  overlay: 'rgb(0 0 0 / 75%)',
  paper: '#1a1a1a',
  paperAccent: '#222424',
  primary: '#19cbda',
  primaryLight: '#00def5',
  secondary: '#ea1265',
  success: '#12ea65',
  textMuted: '#cccccc',
  textPrimary: '#ffffff',
  textSecondary: '#cfcfcf',
  warning: '#f48836',
};

/** Canvas UI colors */
export const canvasUiColors = {
  preview: appColors.primary,
};
