import { memo } from 'react';

import PixelArt from './';
import Cell from './Cell';
import { DETAIL } from '../../../../lib/matrix';

import type { Pixels } from './';
import type { Coordinates } from '../../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Chest pixels: v1 */
const pixels: Pixels = [
  [ 'black', [[ 11, 13 ], [ 9, 13 ], [ 9, 17 ], [ 11, 17 ]], [[ 15, 10 ], [ 13, 10 ], [ 13, 11 ], [ 15, 11 ]], [[ 5, 7 ], [ 4, 7 ], [ 4, 6 ], [ 5, 6 ]], [[ 16, 7 ], [ 15, 7 ], [ 15, 6 ], [ 16, 6 ]], [[ 8, 12 ], [ 7, 12 ], [ 7, 17 ], [ 8, 17 ]], [[ 13, 12 ], [ 12, 12 ], [ 12, 17 ], [ 13, 17 ]], [[ 9, 12 ], [ 11, 12 ], [ 11, 11 ], [ 12, 11 ], [ 12, 10 ], [ 8, 10 ], [ 8, 11 ], [ 9, 11 ]], [[ 7, 10 ], [ 5, 10 ], [ 5, 11 ], [ 7, 11 ]], [[ 13, 6 ], [ 12, 6 ], [ 12, 9 ], [ 13, 9 ]], [[ 15, 6 ], [ 14, 6 ], [ 14, 7 ], [ 15, 7 ], [ 15, 8 ], [ 14, 8 ], [ 14, 9 ], [ 16, 9 ], [ 16, 7 ], [ 17, 7 ], [ 17, 16 ], [ 16, 16 ], [ 16, 12 ], [ 14, 12 ], [ 14, 16 ], [ 16, 16 ], [ 16, 17 ], [ 14, 17 ], [ 14, 18 ], [ 6, 18 ], [ 6, 17 ], [ 4, 17 ], [ 4, 16 ], [ 6, 16 ], [ 6, 12 ], [ 4, 12 ], [ 4, 16 ], [ 3, 16 ], [ 3, 7 ], [ 4, 7 ], [ 4, 9 ], [ 6, 9 ], [ 6, 6 ], [ 5, 6 ], [ 5, 5 ], [ 15, 5 ]], [[ 11, 8 ], [ 10, 8 ], [ 10, 7 ], [ 11, 7 ], [ 11, 6 ], [ 9, 6 ], [ 9, 9 ], [ 11, 9 ]], [[ 8, 6 ], [ 7, 6 ], [ 7, 9 ], [ 8, 9 ]]],
  [ 'gray10', [[ 8, 12 ], [ 8, 17 ], [ 7, 17 ], [ 7, 12 ]], [[ 13, 12 ], [ 13, 17 ], [ 12, 17 ], [ 12, 12 ]], [[ 5, 12 ], [ 5, 16 ], [ 4, 16 ], [ 4, 12 ]], [[ 12, 11 ], [ 11, 11 ], [ 11, 12 ], [ 9, 12 ], [ 9, 11 ], [ 8, 11 ], [ 8, 10 ], [ 12, 10 ]], [[ 13, 6 ], [ 13, 9 ], [ 12, 9 ], [ 12, 6 ]], [[ 8, 6 ], [ 8, 9 ], [ 7, 9 ], [ 7, 6 ]], [[ 5, 8 ], [ 4, 8 ], [ 4, 7 ], [ 5, 7 ]]],
  [ 'gray20', [[ 11, 13 ], [ 11, 17 ], [ 9, 17 ], [ 9, 13 ]], [[ 15, 12 ], [ 15, 16 ], [ 14, 16 ], [ 14, 12 ]], [[ 6, 12 ], [ 6, 16 ], [ 5, 16 ], [ 5, 12 ]], [[ 15, 9 ], [ 14, 9 ], [ 14, 8 ], [ 15, 8 ]], [[ 9, 6 ], [ 11, 6 ], [ 11, 7 ], [ 10, 7 ], [ 10, 8 ], [ 11, 8 ], [ 11, 9 ], [ 9, 9 ]], [[ 6, 6 ], [ 6, 9 ], [ 4, 9 ], [ 4, 8 ], [ 5, 8 ], [ 5, 6 ]], [[ 15, 7 ], [ 14, 7 ], [ 14, 6 ], [ 15, 6 ]]],
  [ 'gray30', [[ 16, 16 ], [ 17, 16 ], [ 17, 14 ], [ 18, 14 ], [ 18, 17 ], [ 17, 17 ], [ 17, 18 ], [ 15, 18 ], [ 15, 19 ], [ 6, 19 ], [ 6, 18 ], [ 14, 18 ], [ 14, 17 ], [ 16, 17 ]], [[ 4, 17 ], [ 6, 17 ], [ 6, 18 ], [ 4, 18 ]], [[ 16, 12 ], [ 16, 16 ], [ 15, 16 ], [ 15, 12 ]], [[ 13, 10 ], [ 15, 10 ], [ 15, 11 ], [ 13, 11 ]], [[ 5, 10 ], [ 7, 10 ], [ 7, 11 ], [ 5, 11 ]], [[ 16, 7 ], [ 16, 9 ], [ 15, 9 ], [ 15, 7 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a pixel art chest.
 */
export default function Chest({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Chest}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
