import type { LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'trinkets',
  rarity: 'exotic',
};

const trinkets = [
  'A mummified goblin hand',
  'A piece of crystal that faintly glows in the moonlight',
  'A gold coin minted in an unknown land',
  "A diary written in a language you don't know",
  'A brass ring that never tarnishes',
  'An old chess piece made from glass',
  'A pair of knucklebone dice, each with a skull symbol on the side that would normally show six pips',
  'A small idol depicting a nightmarish creature that gives you unsettling dreams when you sleep near it',
  'A rope necklace from which dangles four mummified elf fingers',
  'The deed for a parcel of land in a realm unknown to you',
  'A 1-ounce block made from an unknown material',
  'A small cloth doll skewered with needles',
  'A tooth from an unknown beast',
  'An enormous scale, perhaps from a dragon',
  'A bright green feather',
  'An old divination card bearing your likeness',
  'A glass orb filled with moving smoke',
  'A 1-pound egg with a bright red shell',
  'A pipe that blows bubbles',
  'A glass jar containing a weird bit of flesh floating in pickling fluid',
  'A tiny gnome-crafted music box that plays a song you dimly remember from your childhood',
  'A small wooden statuette of a smug halfling',
  'A brass orb etched with strange runes',
  'A multicolored stone disk',
  'A tiny silver icon of a raven',
  'A bag containing forty-seven humanoid teeth, one of which is rotten',
  'A shard of obsidian that always feels warm to the touch',
  "A dragon's bony talon hanging from a plain leather necklace",
  'A pair of old socks',
  'A blank book whose pages refuse to hold ink, chalk, graphite, or any other substance or marking',
  'A silver badge in the shape of a five-pointed star',
  'A knife that belonged to a relative',
  'A glass vial filled with nail clippings',
  'A rectangular metal device with two tiny metal cups on one end that throws sparks when wet',
  'A white, sequined glove sized for a human',
  'A vest with one hundred tiny pockets',
  'A small, weightless stone block',
  'A tiny sketch portrait of a goblin',
  'An empty glass vial that smells of perfume when opened',
  'A gemstone that looks like a lump of coal when examined by anyone but you',
  'A scrap of cloth from an old banner',
  'A rank insignia from a lost legionnaire',
  'A tiny silver bell without a clapper',
  'A mechanical canary inside a gnome-crafted lamp',
  'A tiny chest carved to look like it has numerous feet on the bottom',
  'A dead sprite inside a clear glass bottle',
  'A metal can that has no opening but sounds as if it is filled with liquid',
  'A glass orb filled with water, in which swims a clockwork goldfish',
  'A silver spoon with an M engraved on the handle',
  'A whistle made from gold-colored wood',
  'A dead scarab beetle the size of your hand',
  'Two toy soldiers, one with a missing head',
  'A small box filled with different-sized buttons',
  "A candle that can't be lit",
  'A tiny cage with no door',
  'An old key',
  'An indecipherable treasure map',
  'A hilt from a broken sword',
  "A rabbit's foot",
  'A glass eye',
  'A cameo carved in the likeness of a hideous person',
  'A silver skull the size of a coin',
  'An alabaster mask',
  'A pyramid of sticky black incense that smells very bad',
  'A nightcap that, when worn, gives you pleasant dreams',
  'A single caltrop made from bone',
  'A gold monocle frame without the lens',
  'A 1-inch cube, each side painted a different color',
  'A crystal knob from a door',
  'A small packet filled with pink dust',
  'A fragment of a beautiful song, written as musical notes on two pieces of parchment',
  'A silver teardrop earring made from a real teardrop',
  'The shell of an egg painted with scenes of human misery in disturbing detail',
  'A fan that, when unfolded, shows a sleeping cat',
  'A set of bone pipes',
  'A four-leaf clover pressed inside a book discussing manners and etiquette',
  'A sheet of parchment upon which is drawn a complex mechanical contraption',
  'An ornate scabbard that fits no blade you have found so far',
  'An invitation to a party where a murder happened',
  "A bronze pentacle with an etching of a rat's head in its center",
  'A purple handkerchief embroidered with the name of a powerful archmage',
  'Half of a floorplan for a temple, castle, or some other structure',
  'A bit of folded cloth that, when unfolded, turns into a stylish cap',
  'A receipt of deposit at a bank in a far-flung city',
  'A diary with seven missing pages',
  'An empty silver snuffbox bearing an inscription on the surface that says “dreams”',
  'An iron holy symbol devoted to an unknown god',
  "A book that tells the story of a legendary hero's rise and fall, with the last chapter missing",
  'A vial of dragon blood',
  'An ancient arrow of elven design',
  'A needle that never bends',
  'An ornate brooch of dwarven design',
  'An empty wine bottle bearing a pretty label',
  'A mosaic tile with a multicolored, glazed surface',
  'A petrified mouse',
  "A black pirate flag adorned with a dragon's skull and crossbones",
  "A tiny mechanical crab or spider that moves about when it's not being observed",
  'A glass jar containing lard with a label that reads: “Griffon Grease”',
  'A wooden box with a ceramic bottom that holds a living worm with a head on each end of its body',
  'A metal urn containing the ashes of a hero',
];

const configs: LootConfigIncomplete[] = trinkets.map((name) => ({ ...defaults, name }));

export default configs;
