import { memo } from 'react';
import { Label, Line, Rect as Rectangle, Tag, Text } from 'react-konva';

import {
  cellPx,
  colors,
  embellishmentStrokePx,
  fontFamilyTitle,
  fontSizeMapTitleMedium,
  matrixDimensionMin,
} from '../../../config/map';

// -- Config -------------------------------------------------------------------

/** Label width. */
const labelWidth = (matrixDimensionMin - 8) * cellPx;

/** Label height. */
const labelHeight = cellPx;

/** Label y position. */
const labelY = cellPx / 25;

/** Left flag detail offset x. */
const flagDetailOffsetXLeft = labelHeight * -0.5;

/** Right flag detail offset x. */
const flagDetailOffsetXRight = labelHeight * 0.5;

/** Flag detail offset y. */
const flagDetailOffsetY = cellPx * 0.25;

/** Y position of the title. */
const y = cellPx;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map title.
 */
export default memo(function Title({
  matrixWidth,
  title,
}: {
  matrixWidth: number;
  title: string;
}) {
  if (!title.trim()) {
    return null;
  }

  const x = ((matrixWidth * cellPx) / 2) - (labelWidth / 2);

  return (
    <Label x={x} y={y}>
      <Rectangle
        fill={colors.background}
        height={labelHeight}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={labelHeight}
        x={flagDetailOffsetXLeft}
        y={flagDetailOffsetY}
      />

      <Line
        points={[
          0, labelHeight,
          (labelHeight + flagDetailOffsetXLeft), (labelHeight + flagDetailOffsetY),
        ]}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
      />

      <Rectangle
        fill={colors.background}
        height={labelHeight}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
        width={labelHeight}
        x={labelWidth - flagDetailOffsetXRight}
        y={flagDetailOffsetY}
      />

      <Line
        points={[
          labelWidth, labelHeight,
          (labelWidth - flagDetailOffsetXRight), (labelHeight + flagDetailOffsetY),
        ]}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
      />

      <Tag
        fill={colors.background}
        stroke={colors.border}
        strokeWidth={embellishmentStrokePx}
      />

      <Text
        align="center"
        fill={colors.label}
        fontFamily={fontFamilyTitle}
        fontSize={fontSizeMapTitleMedium}
        height={labelHeight}
        text={title.trim()}
        verticalAlign="middle"
        width={labelWidth}
        y={labelY}
      />
    </Label>
  );
});
