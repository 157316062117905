import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'coins',
  maxCount: 100,
};

/* eslint-disable sort-keys */
const coins: { [name: string]: Partial<LootConfig> | null } = {
  'Copper piece': { rarity: 'common' },
  'Silver piece': { rarity: 'uncommon' },
  'Electrum piece': { rarity: 'exotic' },
  'Gold piece': { rarity: 'rare' },
  'Platinum piece': { rarity: 'exotic' },
  Unidentifiable: { rarity: 'exotic' },
};
/* eslint-enable sort-keys */

const configs: LootConfigIncomplete[] = Object.entries(coins).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
