import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'ammo',
  maxCount: 50,
  rarity: 'common',
};

const magicDefaults: Partial<LootConfig> = {
  isMagic: true,
  maxCount: 3,
};

const ammunition = [
  'Arrow',
  'Blowgun needle',
  'Crossbow bolt (hand)',
  'Crossbow bolt (light/heavy)',
  'Sling bullet',
];

const magicalVariants: Partial<LootConfig>[] = [
  { name: '+1', rarity: 'uncommon' },
  { name: '+2', rarity: 'rare' },
  { name: '+3', rarity: 'veryRare' },
];

const magicalAmmunition: Omit<LootConfigIncomplete, 'category'>[] = ammunition.flatMap((ammo) => {
  return magicalVariants.map(({ name: bonus, rarity }) => ({
    name: `${ammo}, ${bonus}`,
    rarity,
  }));
});

magicalAmmunition.push({
  name: 'Arrow of Slaying',
  rarity: 'veryRare',
});

const configs: LootConfigIncomplete[] = [

  // -- Basic ammunition -------------------------------------------------------

  ...ammunition.map((name) => ({ ...defaults, name })),

  // -- Magical ammunition -----------------------------------------------------

  ...magicalAmmunition.map((config) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
  })),
];

export default configs;
