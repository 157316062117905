import CenterContent from '../components/Display/CenterContent';
import EmptyMessage from '../components/Display/EmptyMessage';
import Link from '../components/Interface/Link';
import Layout from '../components/Layout';
import Metadata from '../components/Utility/Metadata';

/**
 * Renders a 404 page.
 */
export default function NotFoundPage() {
  return (
    <>
      <Metadata
        description="404 Not Found"
        title="404 Not Found"
      />

      <Layout>
        <CenterContent>

          <EmptyMessage
            message={[ 'These are not the mischievous kobolds you are looking for.' ]}
            title="404"
          >
            <p>
              <Link to="/">Go home</Link>
            </p>
          </EmptyMessage>

        </CenterContent>
      </Layout>
    </>
  );
}
