import { memo } from 'react';

import PixelArt from '.';
import Cell from './Cell';
import { DETAIL } from '../../../../lib/matrix';

import type { Pixels } from '.';
import type { Coordinates } from '../../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Fountain pixels: v1 */
const pixels: Pixels = [
  [ 'black', [[ 5, 17 ], [ 15, 17 ], [ 15, 18 ], [ 11, 18 ], [ 11, 19 ], [ 15, 19 ], [ 15, 20 ], [ 5, 20 ], [ 5, 19 ], [ 9, 19 ], [ 9, 18 ], [ 5, 18 ]], [[ 16, 19 ], [ 15, 19 ], [ 15, 18 ], [ 16, 18 ]], [[ 5, 19 ], [ 4, 19 ], [ 4, 18 ], [ 5, 18 ]], [[ 15, 16 ], [ 17, 16 ], [ 17, 15 ], [ 18, 15 ], [ 18, 17 ], [ 17, 17 ], [ 17, 18 ], [ 16, 18 ], [ 16, 17 ], [ 15, 17 ]], [[ 2, 15 ], [ 3, 15 ], [ 3, 16 ], [ 5, 16 ], [ 5, 17 ], [ 4, 17 ], [ 4, 18 ], [ 3, 18 ], [ 3, 17 ], [ 2, 17 ]], [[ 6, 15 ], [ 14, 15 ], [ 14, 14 ], [ 16, 14 ], [ 16, 15 ], [ 15, 15 ], [ 15, 16 ], [ 5, 16 ], [ 5, 15 ], [ 4, 15 ], [ 4, 14 ], [ 6, 14 ]], [[ 19, 13 ], [ 19, 15 ], [ 18, 15 ], [ 18, 13 ]], [[ 2, 13 ], [ 2, 15 ], [ 1, 15 ], [ 1, 13 ]], [[ 8, 14 ], [ 7, 14 ], [ 7, 13 ], [ 8, 13 ]], [[ 13, 14 ], [ 12, 14 ], [ 12, 13 ], [ 13, 13 ]], [[ 9, 13 ], [ 11, 13 ], [ 11, 14 ], [ 9, 14 ]], [[ 6, 11 ], [ 6, 13 ], [ 5, 13 ], [ 5, 11 ]], [[ 15, 11 ], [ 15, 13 ], [ 14, 13 ], [ 14, 11 ]], [[ 18, 13 ], [ 17, 13 ], [ 17, 12 ], [ 18, 12 ]], [[ 3, 13 ], [ 2, 13 ], [ 2, 12 ], [ 3, 12 ]], [[ 9, 13 ], [ 8, 13 ], [ 8, 11 ], [ 6, 11 ], [ 6, 10 ], [ 5, 10 ], [ 5, 9 ], [ 7, 9 ], [ 7, 8 ], [ 9, 8 ], [ 9, 9 ], [ 8, 9 ], [ 8, 10 ], [ 9, 10 ], [ 9, 11 ], [ 11, 11 ], [ 11, 10 ], [ 12, 10 ], [ 12, 9 ], [ 11, 9 ], [ 11, 8 ], [ 13, 8 ], [ 13, 9 ], [ 15, 9 ], [ 15, 10 ], [ 14, 10 ], [ 14, 11 ], [ 12, 11 ], [ 12, 13 ], [ 11, 13 ], [ 11, 12 ], [ 9, 12 ]], [[ 17, 12 ], [ 16, 12 ], [ 16, 11 ], [ 17, 11 ]], [[ 4, 12 ], [ 3, 12 ], [ 3, 11 ], [ 4, 11 ]], [[ 14, 6 ], [ 14, 8 ], [ 13, 8 ], [ 13, 6 ]], [[ 7, 6 ], [ 7, 8 ], [ 6, 8 ], [ 6, 6 ]], [[ 9, 7 ], [ 8, 7 ], [ 8, 5 ], [ 7, 5 ], [ 7, 3 ], [ 8, 3 ], [ 8, 4 ], [ 9, 4 ], [ 9, 5 ], [ 11, 5 ], [ 11, 4 ], [ 12, 4 ], [ 12, 3 ], [ 13, 3 ], [ 13, 5 ], [ 12, 5 ], [ 12, 7 ], [ 11, 7 ], [ 11, 6 ], [ 9, 6 ]], [[ 9, 3 ], [ 8, 3 ], [ 8, 2 ], [ 9, 2 ]], [[ 12, 3 ], [ 11, 3 ], [ 11, 2 ], [ 12, 2 ]], [[ 9, 1 ], [ 11, 1 ], [ 11, 2 ], [ 9, 2 ]]],
  [ 'gray10', [[ 5, 18 ], [ 8, 18 ], [ 8, 19 ], [ 5, 19 ]], [[ 5, 18 ], [ 4, 18 ], [ 4, 17 ], [ 5, 17 ]], [[ 5, 16 ], [ 11, 16 ], [ 11, 17 ], [ 5, 17 ]], [[ 3, 14 ], [ 4, 14 ], [ 4, 15 ], [ 5, 15 ], [ 5, 16 ], [ 3, 16 ]], [[ 5, 5 ], [ 5, 14 ], [ 4, 14 ], [ 4, 5 ]], [[ 16, 5 ], [ 16, 14 ], [ 15, 14 ], [ 15, 5 ]], [[ 9, 14 ], [ 8, 14 ], [ 8, 13 ], [ 9, 13 ]], [[ 10, 13 ], [ 9, 13 ], [ 9, 12 ], [ 10, 12 ]], [[ 9, 10 ], [ 8, 10 ], [ 8, 9 ], [ 9, 9 ]], [[ 11, 7 ], [ 12, 7 ], [ 12, 6 ], [ 13, 6 ], [ 13, 8 ], [ 8, 8 ], [ 8, 7 ], [ 9, 7 ], [ 9, 6 ], [ 11, 6 ]], [[ 7, 6 ], [ 6, 6 ], [ 6, 5 ], [ 7, 5 ]], [[ 14, 6 ], [ 13, 6 ], [ 13, 5 ], [ 14, 5 ]], [[ 6, 5 ], [ 5, 5 ], [ 5, 4 ], [ 6, 4 ]], [[ 15, 5 ], [ 14, 5 ], [ 14, 4 ], [ 15, 4 ]], [[ 11, 2 ], [ 11, 3 ], [ 12, 3 ], [ 12, 4 ], [ 11, 4 ], [ 11, 5 ], [ 9, 5 ], [ 9, 4 ], [ 10, 4 ], [ 10, 2 ]]],
  [ 'gray20', [[ 12, 19 ], [ 11, 19 ], [ 11, 18 ], [ 12, 18 ]], [[ 9, 19 ], [ 8, 19 ], [ 8, 18 ], [ 9, 18 ]], [[ 11, 16 ], [ 15, 16 ], [ 15, 17 ], [ 11, 17 ]], [[ 15, 15 ], [ 17, 15 ], [ 17, 16 ], [ 15, 16 ]], [[ 15, 14 ], [ 14, 14 ], [ 14, 15 ], [ 6, 15 ], [ 6, 13 ], [ 7, 13 ], [ 7, 14 ], [ 13, 14 ], [ 13, 13 ], [ 15, 13 ]], [[ 11, 13 ], [ 10, 13 ], [ 10, 12 ], [ 11, 12 ]], [[ 6, 11 ], [ 5, 11 ], [ 5, 10 ], [ 6, 10 ]], [[ 11, 8 ], [ 11, 11 ], [ 9, 11 ], [ 9, 8 ]]],
  [ 'gray30', [[ 18, 17 ], [ 18, 16 ], [ 19, 16 ], [ 19, 18 ], [ 18, 18 ], [ 18, 19 ], [ 17, 19 ], [ 17, 20 ], [ 15, 20 ], [ 15, 19 ], [ 16, 19 ], [ 16, 18 ], [ 17, 18 ], [ 17, 17 ]], [[ 12, 18 ], [ 15, 18 ], [ 15, 19 ], [ 12, 19 ]], [[ 16, 18 ], [ 15, 18 ], [ 15, 17 ], [ 16, 17 ]], [[ 18, 15 ], [ 16, 15 ], [ 16, 12 ], [ 17, 12 ], [ 17, 13 ], [ 18, 13 ]], [[ 4, 14 ], [ 3, 14 ], [ 3, 13 ], [ 4, 13 ]], [[ 6, 14 ], [ 5, 14 ], [ 5, 13 ], [ 6, 13 ]], [[ 12, 14 ], [ 11, 14 ], [ 11, 13 ], [ 12, 13 ]], [[ 8, 13 ], [ 6, 13 ], [ 6, 11 ], [ 8, 11 ]], [[ 14, 13 ], [ 12, 13 ], [ 12, 11 ], [ 14, 11 ]], [[ 15, 11 ], [ 14, 11 ], [ 14, 10 ], [ 15, 10 ]], [[ 12, 10 ], [ 11, 10 ], [ 11, 9 ], [ 12, 9 ]], [[ 8, 6 ], [ 7, 6 ], [ 7, 5 ], [ 8, 5 ]], [[ 13, 6 ], [ 12, 6 ], [ 12, 5 ], [ 13, 5 ]]],
  [ 'white', [[ 3, 13 ], [ 3, 15 ], [ 2, 15 ], [ 2, 13 ]], [[ 4, 13 ], [ 3, 13 ], [ 3, 12 ], [ 4, 12 ]], [[ 8, 6 ], [ 8, 8 ], [ 7, 8 ], [ 7, 6 ]], [[ 8, 4 ], [ 8, 3 ], [ 9, 3 ], [ 9, 2 ], [ 10, 2 ], [ 10, 4 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a pixel art fountain.
 */
export default function Fountain({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Fountain}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
