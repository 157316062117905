import { useMemo } from 'react';

/**
 * Returns a the OS's modifier key for keyboard shortcuts.
 */
export default function useModifierKey() {
  return useMemo(() => globalThis.navigator.platform.startsWith('Mac')
    ? 'Cmd'
    : 'Ctrl'
  , []);
}
