import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';
import type { Size } from '../../attributes';

// -- Types --------------------------------------------------------------------

type LiquidVariant = typeof variantContainers[number];

// -- Config -------------------------------------------------------------------

const variantContainers = [
  'barrel',
  'beaker',
  'bottle',
  'flask',
  'jar',
  'jug',
  'tankard',
  'vat',
  'vial',
  'waterskin',
] as const;

const variantSizes: Record<LiquidVariant, Size> = {
  barrel: 'large',
  beaker: 'small',
  bottle: 'small',
  flask: 'small',
  jar: 'small',
  jug: 'medium',
  tankard: 'small',
  vat: 'large',
  vial: 'tiny',
  waterskin: 'medium',
};

const defaults: LootConfigDefaults  = {
  category: 'liquids',
  rarity: 'uncommon',
};

const drinkVariants: LiquidVariant[] = [ 'barrel', 'bottle', 'flask', 'jar', 'jug', 'tankard', 'vat' ];

const alchemicalVariants: LiquidVariant[] = [ 'beaker', 'bottle', 'jar', 'vial' ];

const variants: LiquidVariant[] = [ ...variantContainers ];

const liquids: { [name: string]: Partial<LootConfig> | null } = {
  Acid: { variants: alchemicalVariants },
  "Alchemist's fire": { variants: [ ...alchemicalVariants, 'flask' ] },
  Ale: { variants: drinkVariants },
  Antitoxin: { variants: alchemicalVariants },
  'Cider, apple': { variants: drinkVariants },
  'Cider, hard': { variants: drinkVariants },
  Grog: { variants: drinkVariants },
  'Holy water': null,
  'Oil, lamp': null,
  'Poison, basic': { rarity: 'rare', variants },
  'Poison, deadly': { rarity: 'veryRare', variants: [ 'beaker', 'bottle', 'flask', 'jar', 'vial' ] },
  'Water, clean': { rarity: 'common', variants },
  'Water, dirty': { rarity: 'common', variants },
  Whisky: { variants: drinkVariants },
  'Wine, common': { variants: [ ...drinkVariants, 'vial' ] },
  'Wine, fine': { rarity: 'rare', variants: [ ...drinkVariants, 'vial' ] },
};

const configs: LootConfigIncomplete[] = Object.entries(liquids).reduce((liquidsInContainers, [ name, config ]) => {
  const { variants: itemVariants, ...itemConfig } = config || {};
  const liquidVariants: LiquidVariant[] = (itemVariants as LiquidVariant[]) || variants;

  for (const variant of liquidVariants) {
    liquidsInContainers.push({
      ...defaults,
      ...itemConfig,
      name: `${name}, ${variant}`,
      size: variantSizes[variant],
    });
  }

  return liquidsInContainers;
}, [] as LootConfigIncomplete[]);

export default configs;
