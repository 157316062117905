import { Stack } from '@mui/material';

import styles from './ActionBar.module.css';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an action bar wrapper which supports an optional action button,
 * optional status indicator, and a horizontal stack of action buttons provided
 * as children.
 */
export default function ActionBar({
  action,
  children,
  status,
}: {
  action?: React.ReactNode;
  children: React.ReactNode;
  status?: React.ReactNode;
}) {
  return (
    <aside
      aria-label="Actions"
      className={styles.actionBar}
    >
      {action &&
        <div className={styles.action}>
          {action}
        </div>
      }

      {status &&
        <span className={styles.status} role="status">
          {status}
        </span>
      }

      <Stack
        className={styles.actions}
        direction="row"
        spacing={1}
      >
        {children}
      </Stack>
    </aside>
  );
}
