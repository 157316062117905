import { useState } from 'react';

import {
  CONNECTION,
  connections,
  connectionsLockable,
} from '../../../lib/matrix';
import LabeledSwitch from '../../Input/LabeledSwitch';
import Select from '../../Input/Select';
import InputPanel from '../../Interface/InputPanel';

import type { RegionConnectionInfo } from '../../../lib/map';
import type { MatrixInstructionsConnection } from '../../../lib/matrix';
import type { InteractiveMapState } from '../hooks/useInteractiveMap';
import type { MapInfoState } from '../hooks/useMapInfo';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map connection editor.
 */
export default function ConnectionEditorInputs({
  connectionId,
  info,
  onSetConnectionInfo,
  onUpdateRegion,
  type,
}: {
  connectionId: number;
  info?: RegionConnectionInfo;
} & Pick<InteractiveMapState, 'onUpdateRegion'>
  & Pick<MapInfoState, 'onSetConnectionInfo'>
  & Pick<MatrixInstructionsConnection, 'type'>
) {
  const [ showInfo, setShowInfo ] = useState(false);

  return (
    <InputPanel
      aria-label="Connection settings"
      onSetShowInfo={setShowInfo}
      showInfo={showInfo}
    >
      <Select
        infoText="Change connection type."
        items={[ ...connections ]}
        label="Type"
        onChange={(newType) => onUpdateRegion(connectionId, { type: newType as CONNECTION })}
        showInfo={showInfo}
        value={type}
      />

      {connectionsLockable.has(type) &&
        <LabeledSwitch
          infoText="This should slow them down."
          isChecked={info?.locked || false}
          label="Locked"
          onChange={(checked: boolean) => onSetConnectionInfo(connectionId, { locked: checked })}
          showInfo={showInfo}
        />
      }
    </InputPanel>
  );
}
