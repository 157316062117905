import { Typography } from '@mui/material';
import { memo } from 'react';

import { TOOL } from '../../../lib/matrix';
import { toWords } from '../../../lib/string';
import {
  CursorDefault as CursorIcon,
  Eraser as EraserIcon,
  CursorPan as PanIcon,
  Pencil as PencilIcon,
} from '../../Display/Icons';

import type { Brush } from '../../../lib/matrix';

// -- Public Component ---------------------------------------------------------

/**
 * Renders a status indicator for the interactive map. This should be passed
 * to the `<ActionBar>` component's `status` prop.
 */
export default memo(function StatusIndicator({
  activeBrush,
  activeTool,
}: {
  activeBrush: Brush;
  activeTool: TOOL;
}) {
  const { Icon, label } = getStatus(activeTool, activeBrush);

  return (
    <>
      <Icon aria-hidden />
      <Typography
        marginLeft={1}
        variant="button"
      >
        <span>{label}</span>
      </Typography>
    </>
  );
});

// -- Private Functions --------------------------------------------------------

/**
 * Returns the status label.
 */
function getStatus(activeTool: TOOL, brush: Brush): {
  Icon: JSX.ElementType;
  label: string;
} {
  switch (activeTool) {
    case TOOL.Draw: {
      return {
        Icon: PencilIcon,
        label: `Draw ${toWords(brush)}`,
      };
    }

    case TOOL.Erase:
      return {
        Icon: EraserIcon,
        label: 'Erase',
      };

    case TOOL.Pan:
      return {
        Icon: PanIcon,
        label: 'Pan Canvas',
      };

    case TOOL.Select:
      return {
        Icon: CursorIcon,
        label: 'Select',
      };
  }

  throw new TypeError(`Invalid tool "${activeTool}" in <StatusIndicator>, getStatus()`);
}
