import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Types --------------------------------------------------------------------

type LootEntries = { [name: string]: Partial<LootConfig> | null };

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'armor',
  rarity: 'uncommon',
  size: 'medium',
};

const magicDefaults: Partial<LootConfig> = {
  isMagic: true,
};

const armor: LootEntries = {
  Breastplate: { rarity: 'rare' },
  'Chain mail': { rarity: 'rare' },
  'Chain shirt': null,
  'Half plate armor': { rarity: 'rare' },
  'Hide armor': null,
  'Leather armor': null,
  'Padded armor': null,
  'Plate armor': { rarity: 'rare' },
  'Ring mail armor': null,
  'Scale mail armor': null,
  Shield: null,
  'Splint armor': { rarity: 'rare' },
  'Studded leather armor': null,
};

const magicalVariants: Partial<LootConfig>[] = [
  { name: '+1', rarity: 'rare' },
  { name: '+2', rarity: 'veryRare' },
  { name: '+3', rarity: 'legendary' },
];

const magicalArmor: Omit<LootConfigIncomplete, 'category'>[] = Object.entries(armor).flatMap(([ name, config ]) => {
  return magicalVariants.map(({ name: bonus, rarity }) => ({
    ...config,
    name: `${name}, ${bonus}`,
    rarity,
  }));
});

const specialMagicalArmor: LootEntries = {
  'Adamantine Armor': { rarity: 'uncommon' },
  'Animated Shield': { rarity: 'veryRare' },
  'Armor of Invulnerability': { rarity: 'legendary' },
  'Armor of Resistance': { rarity: 'rare' },
  'Armor of Vulnerability': { rarity: 'rare' },
  'Arrow-Catching Shield': { rarity: 'rare' },
  'Demon Armor': { rarity: 'veryRare' },
  'Dragon Scale Mail': { rarity: 'veryRare', variants: [ 'Blue', 'Brass', 'Bronze', 'Copper', 'Gold', 'Green', 'Red', 'Silver', 'White' ] },
  'Dwarven Plate': { rarity: 'veryRare' },
  'Elven Chain': { rarity: 'rare' },
  'Glamoured Studded Leather Armor': { rarity: 'rare' },
  'Mithral Armor': { rarity: 'uncommon' },
  'Plate Armor of Etherealness': { rarity: 'legendary' },
  'Shield of Missile Attraction': { rarity: 'rare' },
  'Spellguard Shield': { rarity: 'veryRare' },
};

const configs: LootConfigIncomplete[] = [

  // -- Basic armor ------------------------------------------------------------

  ...Object.entries(armor).map(([ name, config ]) => ({
    ...defaults,
    ...config,
    name,
  })),

  // -- Magical armor ----------------------------------------------------------

  ...magicalArmor.map((config) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
  })),

  // -- Special magical armor --------------------------------------------------

  ...Object.entries(specialMagicalArmor).map(([ name, config ]) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
    name,
  })),

];

export default configs;
