/**
 * Returns a map entry, or throws an error if it doesn't exist.
 */
export function getMapEntryRequired<K, V>(map: Map<K, V>, key: K): V {
  if (!map.has(key)) {
    throw new TypeError(`Map entry not found for key "${key}" in getMapEntryRequired()`);
  }

  return map.get(key)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

/**
 * Gets or sets, and then returns, a map entry.
 */
export function getOrSetMapEntry<K, V>(map: Map<K, V>, key: K, defaultValue: V): V {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return map.get(key) || map.set(key, defaultValue).get(key)!;
}

/**
 * Returns a boolean indicating whether the given value is not null or
 * undefined. Useful for filtering out null or undefined values from arrays.
 *
 * Example:
 *
 * ```ts
 * const items = [ 1, null, 2, undefined, 3 ];
 * const filtered = items.filter(isNonNullable); // result: [ 1, 2, 3 ]
 * ```
 */
export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined' && value !== null;
}
