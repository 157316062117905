import { FormControl, TextField } from '@mui/material';
import { useState } from 'react';

import InputPanel from '../../Interface/InputPanel';

import type { RegionAreaInfo } from '../../../lib/map';
import type { MapInfoState } from '../hooks/useMapInfo';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map area editor.
 */
export default function AreaEditorInputs({
  areaId,
  info,
  onSetAreaInfo,
}: {
  areaId: number;
  info?: RegionAreaInfo;
} & Pick<MapInfoState, 'onSetAreaInfo'>) {
  const [ showInfo, setShowInfo ] = useState(false);

  return (
    <InputPanel
      aria-label="Area settings"
      onSetShowInfo={setShowInfo}
      showInfo={showInfo}
    >
      <FormControl>
        <TextField
          autoFocus
          helperText={showInfo ? 'Give the area a title.' : undefined}
          label="Title"
          name="area-title"
          onChange={(e) => onSetAreaInfo(areaId, { title: e.target.value })}
          value={info?.title || ''}
        />
      </FormControl>

      <FormControl>
        <TextField
          helperText={showInfo ? 'Describe the area.' : undefined}
          label="Description"
          minRows={3}
          multiline
          name="area-description"
          onChange={(e) => onSetAreaInfo(areaId, { description: e.target.value })}
          value={info?.description || ''}
        />
      </FormControl>
    </InputPanel>
  );
}
