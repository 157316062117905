import styles from './Sidebar.module.css';

/**
 * Renders the application sidebar.
 */
export default function Sidebar({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  return (
    <aside
      aria-label="Sidebar"
      className={`${styles.sidebar} ${className}`}
    >
      {children}
    </aside>
  );
}
