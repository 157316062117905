import type Konva from 'konva';

/**
 * Returns the pointer position from a Konva wheel event.
 */
export function getPointerPosition(event: Konva.KonvaEventObject<WheelEvent>): Konva.Vector2d {
  const position = event.target.getStage()?.getPointerPosition();

  if (!position) {
    throw new TypeError('Pointer position is undefined in getPointerPosition()');
  }

  return position;
}

/**
 * Returns the relative pointer position from a Konva mouse event.
 */
export function getRelativePointerPosition(event: Konva.KonvaEventObject<MouseEvent>) {
  const position = event.target.getStage()?.getRelativePointerPosition();

  if (!position) {
    throw new TypeError('Relative pointer position is undefined in getRelativePointerPosition()');
  }

  return position;
}

/**
 * Returns the current state position from a Konva drag event.
 */
export function getStagePosition(event: Konva.KonvaEventObject<DragEvent>): Konva.Vector2d {
  const position = event.target.getStage()?.position();

  if (!position) {
    throw new TypeError('Stage position is undefined in getStagePosition()');
  }

  return position;
}
