import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import styles from './styles.module.css';
import FancyBox from '../../components/Display/FancyBox';
import LoadingIndicator from '../../components/Display/LoadingIndicator';
import StaticPage from '../../components/Display/StaticPage';
import WarningBox from '../../components/Display/WarningBox';
import WidowFix from '../../components/Display/WidowFix';

// -- Types --------------------------------------------------------------------
export interface ReleaseNote {
  body?: string[];
  date: string;
  img?: boolean;
  notes?: string[];
  title: string;
  version: string;
}

// -- Config -------------------------------------------------------------------

/** Path to the release notes data file. */
const releaseNotesPath = '/data/release-notes.json';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the release notes page.
 */
export default function ReleaseNotesPage() {
  const { isError, isLoading, releaseNotes } = useReleaseNotes();

  return (
    <StaticPage
      metaDescription="Release notes for Mystic Waffle's D&D/TTRPG dungeon, area, and loot generator."
      title="Release Notes"
    >
      {isError &&
        <WarningBox role="alert">
          Oh no! Goblins have hacked into the JavaScript and now the release notes can’t be loaded!
          Please report this error to AJ.
        </WarningBox>
      }

      {isLoading && <LoadingIndicator />}

      {releaseNotes?.map(({ body, date, img, notes, title, version }) => (
        <Note
          body={body}
          date={date}
          img={img}
          key={version}
          notes={notes}
          title={title}
          version={version}
        />
      ))}
    </StaticPage>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a release note.
 */
function Note({
  body,
  date,
  img,
  notes,
  title,
  version,
}: {
  body?: string[];
  date: string;
  img?: boolean;
  notes?: string[];
  title: string;
  version: string;
}) {
  return (
    <FancyBox
      className={styles.box}
      component="article"
      title={<WidowFix>{title}</WidowFix>}
      titleComponent="h2"
    >
      <Typography
        align="center"
        color="primary"
        component="p"
        fontWeight="600"
      >
        Version {version}
      </Typography>

      <Typography
        align="center"
        component="p"
      >
        <time dateTime={date}>
          {new Date(date).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' })}
        </time>
      </Typography>

      {body?.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}

      {notes &&
        <ul>
          {notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      }

      {img &&
        <img
          alt={`${title} Screenshot`}
          className={styles.screenshot}
          loading="lazy"
          src={`/img/release-notes/${version}.jpg`}
        />
      }
    </FancyBox>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Fetches the release notes.
 */
function useReleaseNotes() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ releaseNotes, setReleaseNotes ] = useState<ReleaseNote[]>();

  useEffect(() => {
    if (isError || isLoading || releaseNotes?.length) {
      return;
    }

    /** Fetches release notes. */
    async function fetchReleaseNotes() {
      setIsLoading(true);

      let notes;

      try {
        const response = await globalThis.fetch(releaseNotesPath);
        notes = await response.json();
      } catch {
        setIsError(true);
        setIsLoading(false);
        return;
      }

      setReleaseNotes(notes);
      setIsLoading(false);
    }

    fetchReleaseNotes();
  }, [
    isError,
    isLoading,
    releaseNotes,
  ]);

  return {
    isError,
    isLoading,
    releaseNotes,
  };
}
