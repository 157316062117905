import type { Path } from '../matrix';
import type Konva from 'konva';

/**
 * Draws paths for Konva's `<Shape>` component via its `sceneFunc` prop.
 *
 * @example
 *
 * import { Shape } from 'react-konva';
 * import drawPaths from '../../../lib/konva/drawPaths';
 *
 * const paths = [
 *   [[ 0,  0  ], [ 100, 0  ], [ 100, 100 ], [ 0,  100 ]],
 *   [[ 10, 10 ], [ 90,  10 ], [ 90,  90  ], [ 10, 90  ]],
 * ];
 *
 * function CoolShapeDude() {
 *   return (
 *     <Shape
 *       fill="blue"
 *       sceneFunc={(context, shape) => drawPaths(context, shape, paths)}
 *     />
 *   );
 * }
 */
export default function drawPaths(
  context: Konva.Context,
  shape: Konva.Shape,
  paths: Path[]
) {
  context.beginPath();

  for (const path of paths) {
    const [[ x1, y1 ], ...points ] = path;
    context.moveTo(x1, y1);

    for (const [ x, y ] of points) {
      context.lineTo(x, y);
    }

    context.closePath();
  }

  context.fillStrokeShape(shape);
}
