import {
  rollArea,
  rollAreaGenesis,
  rollConnection,
  rollMatrixDimensions,
} from './roll';
import { appVersion } from '../../../config';
import { Area } from '../../../config/area';
import { getDiceBag } from '../../dice';
import Matrix, {
  AREA,
  CONNECTION,
  Coordinates,
  DRAW_OPTION,
} from '../../matrix';
import { createBlankMatrix } from '../../matrix/utility';
import { getRandomSeed } from '../../seed';
import { capitalizeWords, toWords } from '../../string';
import generateArea from '../area';

import type { RolledArea } from './roll';
import type { Dice } from '../../dice';
import type { MapInfo, MapSnapshot } from '../../map';
import type { MatrixMutable } from '../../matrix';
import type { MatrixShapeWalls } from '../../matrix/shape';
import type { AreaResult, AreaSettings } from '../area';
import type { LootSettings } from '../loot';

// -- Types --------------------------------------------------------------------

export interface AppliedArea {
  coordinates: Coordinates[];
  type: Area | null;
  walls: MatrixShapeWalls;
}

export interface AppliedConnection {
  coordinates: Coordinates[];
}

export interface MapSettings {
  complexity: number;
  connectionFrequency: number;
  connections: CONNECTION[];
  title: string;
}

// -- Config -------------------------------------------------------------------

/**
 * Minimum X coordinate for the available canvas on the matrix. Cells to the
 * left of the minimum are reserved for the compass rose and map legend.
 */
export const canvasMinX = 4;

/** Maximum map complexity. */
export const complexityMax = 11;

/** Minimum map complexity. */
export const complexityMin = 2;

/** Maximum connection frequency. */
export const connectionFrequencyMax = 11;

/** Minimum connection frequency. */
export const connectionFrequencyMin = 1;

/**
 * Complexity multiplier which determines how many areas to generate.
 *
 * Note that some generated areas may not be used.
 */
const complexityMultiplierAreaCount = 5;

/**
 * Generator matrix cell values. Used to temporarily mark cells on a
 * MutableMatrix during generation.
 */
enum GENERATOR_VALUE {
  AreaCell,
  ConnectionCell,
}

// -- Public Functions ---------------------------------------------------------

/**
 * Generates a map for the given map settings.
 *
 * TODO external connections
 * TODO details
 * TODO additional connections
 * TODO forking
 * TODO hallways
 * TODO locked doors & key distribution
 *
 * TDL add loot
 * TDL add area descriptions
 */
export default function generateMap(
  seed: string,
  mapSettings: MapSettings,
  areaSettings: AreaSettings,
  lootSettings: LootSettings
): MapSnapshot | undefined {
  if (areaSettings.sizes.length === 0) {
    return;
  }

  const dice = getDiceBag({ seed });

  const {
    complexity,
    connections,
    title,
  } = mapSettings;

  const matrixDimensions = rollMatrixDimensions(dice, complexity);
  const matrix = createBlankMatrix(matrixDimensions);
  const areas = generateAreas(dice, complexity, areaSettings);

  const {
    appliedAreas,
    appliedConnections,
  } = procedurallyDrawAreas(dice, matrix, areas);

  const mapMatrix = new Matrix({ dimensions: matrixDimensions }, dice);
  const areaInfo: MapInfo['areaInfo'] = {};

  let areaId = 1;

  for (const area of appliedAreas) {
    mapMatrix.draw(area.coordinates, AREA.Dungeon, DRAW_OPTION.Free);
    areaInfo[areaId] = {
      title: area.type ? capitalizeWords(toWords(area.type)) : '',
    };

    areaId++;
  }

  for (const connection of appliedConnections) {
    const connectionType = rollConnection(dice, connections);
    mapMatrix.draw(connection.coordinates, connectionType, DRAW_OPTION.Rectangle);
  }

  return {
    areaInfo,
    connectionInfo: {},
    theme: 'classic',
    title,
    v: appVersion,
    ...mapMatrix.getHistoryEntry(),
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Applies areas to the matrix.
 */
function applyAreas(
  dice: Dice,
  matrix: MatrixMutable,
  areas: AreaResult[]
): {
  appliedAreas: AppliedArea[];
  appliedConnections: AppliedConnection[];
  skippedAreas: AreaResult[];
} {
  const appliedAreas: AppliedArea[] = [];
  const appliedConnections: AppliedConnection[] = [];
  const skippedAreas: AreaResult[] = [];

  let prevArea: AppliedArea | null = null;

  for (const area of areas) {
    const result: RolledArea = prevArea
      ? rollArea(dice, matrix, area, prevArea)
      : rollAreaGenesis(dice, matrix, area);

    if (!result) {
      skippedAreas.push(area);
      continue;
    }

    const { appliedArea, appliedConnection }: NonNullable<RolledArea> = result;

    setCells(matrix, appliedArea.coordinates, GENERATOR_VALUE.AreaCell);

    appliedAreas.push(appliedArea);

    if (appliedConnection) {
      setCells(matrix, appliedConnection.coordinates, GENERATOR_VALUE.ConnectionCell);

      appliedConnections.push(appliedConnection);
    }

    prevArea = appliedArea;
  }

  return {
    appliedAreas,
    appliedConnections,
    skippedAreas,
  };
}

/**
 * Generates area configs for the given settings.
 */
function generateAreas(
  dice: Dice,
  complexity: number,
  areaSettings: AreaSettings
): AreaResult[] {
  const areas: AreaResult[] = [];
  const count = complexity * complexityMultiplierAreaCount;

  for (let i = 1; i <= count; i++) {
    const seed = getRandomSeed(dice);
    areas.push(generateArea(seed, areaSettings, { uniqueAreaFrequency: 25 })); // TODO wire up uniqueAreaFrequency
  }

  return areas;
}

/**
 * Randomizes and writes areas to the matrix.
 */
function procedurallyDrawAreas(
  dice: Dice,
  matrix: MatrixMutable,
  areas: AreaResult[]
): {
  appliedAreas: AppliedArea[];
  appliedConnections: AppliedConnection[];
} {
  const {
    appliedAreas,
    appliedConnections,
    skippedAreas,
  } = applyAreas(dice, matrix, areas);

  return {
    appliedAreas,
    appliedConnections,
  };
}

/**
 * Applies a value to each coordinate on the mutable matrix.
 */
function setCells(
  matrix: MatrixMutable,
  coordinates: Coordinates[],
  value: GENERATOR_VALUE
): void {
  for (const [ x, y ] of coordinates) {
    matrix[x][y] = value;
  }
}
