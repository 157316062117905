/**
 * Number of spacing units between each setting control. This should be applied
 * to the `spacing` prop of a vertical `<Stack>` component containing inputs.
 */
export const inputStackSpacing = 3;

/**
 * Padding units around an input stack. This should be applied to the `p` or
 * `px` prop of a vertical `<Stack>` component containing inputs.
 */
export const inputStackPadding = 2;
