import { memo } from 'react';
import { Circle, Group, Rect as Rectangle } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

const tableWidth = cellPx * 0.8;
const tableHeight = cellPx * 0.6;
const tableX = (cellPx - tableWidth) / 2;
const tableY = (cellPx - tableHeight) / 2;

const paperWidth = cellPx * 0.18;
const paperHeight = cellPx * 0.26;

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a table.
 */
export default function Table({
  coordinates: [ x, y ],
}: {
  coordinates: Coordinates;
}) {
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={DETAIL.Table}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      <DetailsMemo />
    </Group>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders table details.
 *
 * TDL shuffle table contents.
 */
function Details() {
  return (
    <>
      <Rectangle
        fill={colors.detail}
        height={tableHeight}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={tableWidth}
        x={tableX}
        y={tableY}
      />

      <Rectangle
        fill={colors.background}
        height={paperHeight}
        rotation={-10}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={paperWidth}
        x={cellPx * 0.2}
        y={cellPx * 0.35}
      />

      <Rectangle
        fill={colors.background}
        height={paperHeight}
        rotation={10}
        stroke={colors.border}
        strokeWidth={detailPx}
        width={paperWidth}
        x={cellPx * 0.36}
        y={cellPx * 0.4}
      />

      <Circle
        fill={colors.shadow}
        radius={cellPx * 0.08}
        stroke={colors.border}
        strokeWidth={detailPx}
        x={cellPx * 0.7}
        y={cellPx * 0.4}
      />
    </>
  );
}
