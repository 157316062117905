import { useCallback, useRef, useState } from 'react';

import { downloadFile, getFilenameStem, getJsonDataUrl } from '../../../lib/download';
import { isArea, isConnection } from '../../../lib/matrix/utility';

import type { MapInfo, MapSnapshot } from '../../../lib/map';
import type Konva from 'konva';
import type React from 'react';

// -- Public Hook --------------------------------------------------------------

/**
 * Returns state for the `<DownloadStage>` component. `<DownloadStage>` should
 * be rendered when `renderDownloadStage` is `true`. In the next animation
 * frame, the canvas data url will be extracted and downloaded along with the
 * map snapshot in JSON format, then `renderDownloadStage` will be set to
 * `false`.
 */
export default function useDownloadMap(snapshot: MapSnapshot): {
  onDownloadMap: () => void;
  renderDownloadStage: boolean;
  stageRef: React.RefObject<Konva.Stage>;
} {
  const stageRef = useRef<Konva.Stage>(null);
  const [ renderDownloadMap, setRenderDownloadMap ] = useState(false);

  const onDownloadMap = useCallback(() => {
    setRenderDownloadMap(true);

    const requestId = globalThis.requestAnimationFrame(() => {
      const data = stageRef.current?.toDataURL();

      if (!data) {
        throw new TypeError('Failed to extract map data url in useDownloadMap()');
      }

      triggerDownload(snapshot, data);
      setRenderDownloadMap(false);
    });

    return () => globalThis.cancelAnimationFrame(requestId);
  }, [ snapshot ]);

  return {
    onDownloadMap,
    renderDownloadStage: renderDownloadMap,
    stageRef,
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Downloads the map image and JSON snapshot.
 */
function triggerDownload(snapshot: MapSnapshot, mapDataUrl: string) {
  const info: MapInfo = {
    areaInfo: {},
    connectionInfo: {},
  };

  // Filter any deleted regions from map info.
  for (const { id } of snapshot?.regions || []) {
    if (isArea(id)) {
      info.areaInfo[id] = snapshot.areaInfo[id];
    }

    if (isConnection(id)) {
      info.connectionInfo[id] = snapshot.connectionInfo[id];
    }
  }

  const downloadSnapshot: MapSnapshot = {
    ...snapshot,
    ...info,
  };

  const { title } = snapshot;
  const filenameStem = title ? getFilenameStem(title) : 'map';

  downloadFile(mapDataUrl, `${filenameStem}.mw.png`);
  downloadFile(getJsonDataUrl(JSON.stringify(downloadSnapshot)), `${filenameStem}.mw.json`);
}
