import { canvasMinX, complexityMin } from '.';
import { matrixDimensionMax, matrixDimensionMin } from '../../../config/map';
import {
  CONNECTION,
  Coordinates,
  directionsCardinal,
} from '../../matrix';
import { Rectangle } from '../../matrix/shape';
import {
  getCellValueWeak,
  getCoordinatesInDirection,
  getMatrixDimensions,
  getOppositeDirectionCardinal,
} from '../../matrix/utility';

import type { AppliedArea, AppliedConnection } from '.';
import type { Size } from '../../../config/attributes/size';
import type { Dice } from '../../dice';
import type { Dimensions, DirectionCardinal, MatrixMutable } from '../../matrix';
import type { Range } from '../../number';
import type { AreaResult } from '../area';

// -- Types --------------------------------------------------------------------

export type RolledArea = {
  appliedArea: AppliedArea;
  appliedConnection?: AppliedConnection;
} | null;

interface CanvasBounds {
  maxX: number;
  maxY: number;
  minX: number;
  minY: number;
}

// -- Config -------------------------------------------------------------------

/**
 * Area size dimension ranges.
 */
export const areaDimensionRanges: Readonly<Record<Size, Range>> = {
  /* eslint-disable sort-keys */
  tiny: [ 3, 4 ],
  small: [ 4, 5 ],
  medium: [ 4, 6 ],
  large: [ 5, 12 ],
  massive: [ 6, 15 ],
  /* eslint-enable sort-keys */
};

/** Complexity multiplier which determines the maximum complexity value. */
const complexityMultiplierDimensionsMax = 4;

/** Complexity multiplier which determines the minimum complexity value. */
const complexityMultiplierDimensionsMin = 2;

/** Array of cardinal directions for placing areas. */
const placementDirections = [ ...directionsCardinal ];

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a rolled area to be drawn on the matrix, or null if no compatible
 * space is available.
 */
export function rollArea(
  dice: Dice,
  matrix: MatrixMutable,
  area: AreaResult,
  prevArea: AppliedArea
): RolledArea {
  const { size, type } = area;
  const { walls } = prevArea;
  const bounds = getCanvasDrawBounds(matrix);
  const areaDimensions = rollAreaDimensions(dice, size);
  const [ width, height ] = areaDimensions;

  for (const direction of dice.shuffle(placementDirections)) {
    const checkDistance = direction === 'east' || direction === 'west' ? width : height;

    if (!isInBounds(getCoordinatesInDirection(walls[direction][0], direction, checkDistance), bounds)) {
      continue;
    }

    for (const connectionCoordinates of dice.shuffle(walls[direction])) {
      const rect = rollAreaShape(
        dice,
        matrix,
        areaDimensions,
        connectionCoordinates,
        direction
      );

      if (!rect) {
        continue;
      }

      return {
        appliedArea: {
          coordinates: rect.coordinates,
          type,
          walls: rect.walls,
        },
        appliedConnection: {
          coordinates: [ connectionCoordinates ],
        },
      };
    }
  }

  return null;
}

/**
 * Returns randomized dimensions for the given area size.
 */
export function rollAreaDimensions(
  dice: Dice,
  areaSize: Size
): Dimensions {
  const [ min, max ] = areaDimensionRanges[areaSize];

  const width  = dice.roll(min, max);
  const height = dice.roll(min, max);

  return [ width, height ];
}

/**
 * Returns a starting applied area to draw on the matrix, or null if no
 * compatible space is available.
 *
 * Area genesis may be be rolled a number of times if the returned applied area
 * cannot be placed on the map.
 */
export function rollAreaGenesis(
  dice: Dice,
  matrix: MatrixMutable,
  area: AreaResult
): RolledArea {
  const { size, type } = area;
  const areaDimensions = rollAreaDimensions(dice, size);
  const result = rollStartingAnchor(dice, matrix, areaDimensions);

  if (!result) {
    return null;
  }

  const { anchor, edge } = result;

  const rect = new Rectangle(areaDimensions);
  rect.setRelativeOrigin(anchor);

  const connectionCoordinates = dice.rollArrayItem(rect.getWall(edge));

  return {
    appliedArea: {
      coordinates: rect.coordinates,
      type,
      walls: rect.walls,
    },
    appliedConnection: {
      coordinates: [ connectionCoordinates ],
    },
  };
}

/**
 * Returns a matrix shape for the area bounding box, connection coordinates, and
 * connection direction, if one can be fit to the given connection coordinates.
 */
export function rollAreaShape(
  dice: Dice,
  matrix: MatrixMutable,
  areaDimensions: Dimensions,
  connectionCoordinates: Coordinates,
  direction: DirectionCardinal
): Rectangle | null {
  const rect = new Rectangle(areaDimensions);
  const relativeWall = rect.getWall(getOppositeDirectionCardinal(direction));

  for (const [ x, y ] of dice.shuffle(relativeWall)) {
    rect.setRelativeOrigin(connectionCoordinates, [ x, y ]);

    if (!isAvailableCoordinates(matrix, rect.corners)) {
      continue;
    }

    if (!isAvailableCoordinates(matrix, Object.values(rect.walls).flat())) {
      continue;
    }

    if (!isAvailableCoordinates(matrix, rect.coordinates)) {
      continue;
    }

    return rect;
  }

  return null;
}

/**
 * Rolls connection properties.
 */
export function rollConnection(
  dice: Dice,
  selectedConnections: CONNECTION[]
): CONNECTION {
  return selectedConnections.length
    ? dice.rollArrayItem(selectedConnections)
    : CONNECTION.Connection;
}

/**
 * Returns grid dimensions for the generated map.
 */
export function rollMatrixDimensions(dice: Dice, complexity: number): Dimensions {
  const min = complexity - complexityMin;
  const mid = complexity * complexityMultiplierDimensionsMin;
  const max = complexity * complexityMultiplierDimensionsMax;

  const dimensionMin = matrixDimensionMin + dice.roll(min, mid);
  const dimensionMax = matrixDimensionMin + dice.roll(mid, max);

  const width  = dice.roll(dimensionMin, dimensionMax);
  const height = dice.roll(dimensionMin, dimensionMax);

  return [
    Math.min(Math.max(width, matrixDimensionMin), matrixDimensionMax),
    Math.min(Math.max(height, matrixDimensionMin), matrixDimensionMax),
  ];
}

/**
 * Returns a random starting point for the first room on one of the map's edges.
 */
export function rollStartingAnchor(
  dice: Dice,
  matrix: MatrixMutable,
  areaDimensions: Dimensions
): {
  anchor: Coordinates;
  edge: DirectionCardinal;
} | null {
  const {
    maxX: canvasMaxX,
    maxY: canvasMaxY,
    minX,
    minY,
  } = getCanvasDrawBounds(matrix);

  const [ areaWidth, areaHeight ] = areaDimensions;

  const maxX = canvasMaxX - areaWidth;
  const maxY = canvasMaxY - areaHeight;

  if (maxX < minX || maxY < minY) {
    return null;
  }

  const edge = dice.rollArrayItem(placementDirections);

  switch (edge) {
    case 'north':
      return {
        anchor: [ dice.roll(minX, maxX), minY + 1 ],
        edge,
      };

    case 'east':
      return {
        anchor: [ maxX - 1, dice.roll(minY, maxY) ],
        edge,
      };

    case 'south':
      return {
        anchor: [ dice.roll(minX, maxX), maxY - 1 ],
        edge,
      };

    case 'west':
      return {
        anchor: [ minX + 1, dice.roll(minY, maxY) ],
        edge,
      };
  }
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns bounds representing the portion of the matrix which can be drawn on
 * during generation.
 */
function getCanvasDrawBounds(matrix: MatrixMutable): CanvasBounds {
  const [ width, height ] = getMatrixDimensions(matrix);

  return {
    maxX: width - 1,
    maxY: height - 1,
    minX: canvasMinX + 1,
    minY: 1,
  };
}

/**
 * Returns bounds representing the portion of the matrix which is valid for
 * placing area _edge_ (edge and corner) cells during generation.
 */
function getCanvasEdgeBounds(matrix: MatrixMutable): CanvasBounds {
  const [ width, height ] = getMatrixDimensions(matrix);

  return {
    maxX: width,
    maxY: height,
    minX: canvasMinX,
    minY: 0,
  };
}

/**
 * Returns whether the given coordinates are in bounds and empty.
 */
function isAvailableCoordinates(
  matrix: MatrixMutable,
  coordinates: Coordinates[]
): boolean {
  const bounds = getCanvasEdgeBounds(matrix);

  for (const cellCoordinates of coordinates) {
    if (!isInBounds(cellCoordinates, bounds)) {
      return false;
    }

    if (getCellValueWeak(matrix, cellCoordinates) !== null) {
      return false;
    }
  }

  return true;
}

/**
 * Returns whether the given coordinates are within the available canvas.
 */
function isInBounds([ x, y ]: Coordinates, bounds: CanvasBounds): boolean {
  const { maxX, maxY, minX, minY } = bounds;

  return x >= minX && x <= maxX && y >= minY && y <= maxY;
}
