import { FormControl, Stack, TextField } from '@mui/material';

import { availableConnections } from './hooks/useMapSettings';
import { inputStackPadding, inputStackSpacing } from '../../config/ui';
import {
  complexityMax,
  complexityMin,
  connectionFrequencyMax,
  connectionFrequencyMin,
} from '../../lib/generate/map';
import WidowFix from '../Display/WidowFix';
import LabeledSlider from '../Input/LabeledSlider';
import Multiselect from '../Input/Multiselect';

import type { MapSettingsAction } from './hooks/useMapSettings';
import type { MapSettings } from '../../lib/generate/map';
import type { CONNECTION } from '../../lib/matrix';

// -- Types --------------------------------------------------------------------

interface MapInputProps {
  onSettingChange: (action: MapSettingsAction) => void;
  showInfo: boolean;
}

// -- Public Component ---------------------------------------------------------

/**
 * Renders map generator inputs.
 */
export default function MapInputs({
  complexity,
  connectionFrequency,
  connections: selectedConnections,
  title,
  ...inputProps
}: MapInputProps & MapSettings) {
  return (
    <Stack p={inputStackPadding} spacing={inputStackSpacing}>
      <TitleInput
        {...inputProps}
        title={title}
      />

      <ComplexitySlider
        {...inputProps}
        complexity={complexity}
      />

      <ConnectionFrequencySlider
        {...inputProps}
        connectionFrequency={connectionFrequency}
      />

      <ConnectionsMultiselect
        {...inputProps}
        selectedConnections={selectedConnections}
      />

    </Stack>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the dungeon title input.
 */
function TitleInput({
  onSettingChange,
  showInfo,
  title,
}: MapInputProps & {
  title: string;
}) {
  return (
    <FormControl>
      <TextField
        helperText={showInfo ? 'Give your map a title.' : undefined}
        label="Title"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSettingChange({ title: e.target.value })}
        value={title}
      />
    </FormControl>
  );
}

/**
 * Renders the dungeon complexity.
 */
function ComplexitySlider({
  complexity,
  onSettingChange,
  showInfo,
}: MapInputProps & {
  complexity: number;
}) {
  return (
    <LabeledSlider
      infoText={
        <WidowFix>
          Map complexity; map size, area count, and detail density.
        </WidowFix>
      }
      label="Map Complexity"
      max={complexityMax}
      min={complexityMin}
      onChange={(value: number) => onSettingChange({ complexity: value as number })}
      showInfo={showInfo}
      value={complexity}
    />
  );
}

/**
 * Renders the dungeon connection frequency slider.
 */
function ConnectionFrequencySlider({
  connectionFrequency,
  onSettingChange,
  showInfo,
}: MapInputProps & {
  connectionFrequency: number;
}) {
  return null; // TODO hook up to generator
  return (
    <LabeledSlider
      infoText={
        <WidowFix>
          The frequency in which adjacent areas will have connections placed between them.
          Setting this to one will make the dungeon linear, cranking it up to 11 should place doorways and connections all over the place.
        </WidowFix>
      }
      label="Connection Frequency"
      max={connectionFrequencyMax}
      min={connectionFrequencyMin}
      onChange={(value: number) => onSettingChange({ connectionFrequency: value as number })}
      showInfo={showInfo}
      value={connectionFrequency}
    />
  );
}

/**
 * Renders connections multiselect.
 */
function ConnectionsMultiselect({
  onSettingChange,
  selectedConnections,
  showInfo,
}: MapInputProps & {
  selectedConnections: CONNECTION[];
}) {
  return (
    <Multiselect
      allowEmpty
      infoText={
        <WidowFix>
          The types of connections between areas.
          If no connection types are selected, all connections will use the generic connection type.
        </WidowFix>
      }
      items={availableConnections}
      label="Connections"
      onChange={(items: string[]) => onSettingChange({ connections: items as CONNECTION[] })}
      selectedItems={selectedConnections}
      showInfo={showInfo}
    />
  );
}
