import { memo } from 'react';

import PixelArt from './';
import Cell from './Cell';
import { DETAIL } from '../../../../lib/matrix';

import type { Pixels } from './';
import type { Coordinates } from '../../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Table pixels: v1 */
const pixels: Pixels = [
  [ 'black', [[ 1, 15 ], [ 1, 5 ], [ 2, 5 ], [ 2, 6 ], [ 3, 6 ], [ 3, 7 ], [ 2, 7 ], [ 2, 8 ], [ 3, 8 ], [ 3, 9 ], [ 2, 9 ], [ 2, 10 ], [ 5, 10 ], [ 5, 11 ], [ 2, 11 ], [ 2, 12 ], [ 3, 12 ], [ 3, 13 ], [ 2, 13 ], [ 2, 14 ], [ 3, 14 ], [ 3, 15 ], [ 17, 15 ], [ 17, 14 ], [ 18, 14 ], [ 18, 13 ], [ 17, 13 ], [ 17, 12 ], [ 18, 12 ], [ 18, 11 ], [ 15, 11 ], [ 15, 10 ], [ 18, 10 ], [ 18, 9 ], [ 15, 9 ], [ 15, 8 ], [ 18, 8 ], [ 18, 7 ], [ 17, 7 ], [ 17, 6 ], [ 18, 6 ], [ 18, 5 ], [ 19, 5 ], [ 19, 15 ], [ 18, 15 ], [ 18, 18 ], [ 17, 18 ], [ 17, 16 ], [ 16, 16 ], [ 16, 18 ], [ 17, 18 ], [ 17, 19 ], [ 15, 19 ], [ 15, 16 ], [ 5, 16 ], [ 5, 19 ], [ 3, 19 ], [ 3, 18 ], [ 4, 18 ], [ 4, 16 ], [ 3, 16 ], [ 3, 18 ], [ 2, 18 ], [ 2, 15 ]], [[ 3, 13 ], [ 17, 13 ], [ 17, 14 ], [ 3, 14 ]], [[ 11, 11 ], [ 10, 11 ], [ 10, 10 ], [ 11, 10 ]], [[ 7, 10 ], [ 9, 10 ], [ 9, 11 ], [ 7, 11 ]], [[ 6, 8 ], [ 13, 8 ], [ 13, 9 ], [ 6, 9 ]], [[ 14, 6 ], [ 16, 6 ], [ 16, 7 ], [ 14, 7 ]], [[ 9, 6 ], [ 12, 6 ], [ 12, 7 ], [ 9, 7 ]], [[ 4, 6 ], [ 8, 6 ], [ 8, 7 ], [ 4, 7 ]], [[ 2, 4 ], [ 18, 4 ], [ 18, 5 ], [ 2, 5 ]]],
  [ 'gray10', [[ 4, 18 ], [ 3, 18 ], [ 3, 17 ], [ 4, 17 ]], [[ 4, 15 ], [ 3, 15 ], [ 3, 14 ], [ 4, 14 ]], [[ 3, 14 ], [ 2, 14 ], [ 2, 13 ], [ 3, 13 ]], [[ 16, 6 ], [ 14, 6 ], [ 14, 7 ], [ 16, 7 ]], [[ 11, 10 ], [ 7, 10 ], [ 7, 11 ], [ 11, 11 ]], [[ 14, 8 ], [ 6, 8 ], [ 6, 9 ], [ 14, 9 ]], [[ 13, 6 ], [ 9, 6 ], [ 9, 7 ], [ 13, 7 ]], [[ 18, 6 ], [ 17, 6 ], [ 17, 7 ], [ 18, 7 ], [ 18, 8 ], [ 15, 8 ], [ 15, 9 ], [ 18, 9 ], [ 18, 10 ], [ 13, 10 ], [ 13, 11 ], [ 18, 11 ], [ 18, 12 ], [ 17, 12 ], [ 17, 13 ], [ 11, 13 ], [ 11, 12 ], [ 8, 12 ], [ 8, 13 ], [ 3, 13 ], [ 3, 12 ], [ 2, 12 ], [ 2, 11 ], [ 6, 11 ], [ 6, 10 ], [ 2, 10 ], [ 2, 9 ], [ 4, 9 ], [ 4, 8 ], [ 2, 8 ], [ 2, 7 ], [ 8, 7 ], [ 8, 6 ], [ 2, 6 ], [ 2, 5 ], [ 18, 5 ]]],
  [ 'gray20', [[ 17, 18 ], [ 16, 18 ], [ 16, 17 ], [ 17, 17 ]], [[ 4, 14 ], [ 12, 14 ], [ 12, 15 ], [ 4, 15 ]], [[ 14, 15 ], [ 13, 15 ], [ 13, 14 ], [ 14, 14 ]], [[ 8, 12 ], [ 11, 12 ], [ 11, 13 ], [ 8, 13 ]], [[ 6, 11 ], [ 5, 11 ], [ 5, 10 ], [ 6, 10 ]], [[ 14, 11 ], [ 13, 11 ], [ 13, 10 ], [ 14, 10 ]], [[ 4, 9 ], [ 3, 9 ], [ 3, 8 ], [ 4, 8 ]], [[ 14, 9 ], [ 13, 9 ], [ 13, 8 ], [ 14, 8 ]], [[ 13, 7 ], [ 12, 7 ], [ 12, 6 ], [ 13, 6 ]]],
  [ 'gray30', [[ 19, 15 ], [ 19, 10 ], [ 20, 10 ], [ 20, 17 ], [ 19, 17 ], [ 19, 19 ], [ 18, 19 ], [ 18, 20 ], [ 15, 20 ], [ 15, 19 ], [ 17, 19 ], [ 17, 18 ], [ 18, 18 ], [ 18, 15 ]], [[ 15, 16 ], [ 15, 18 ], [ 7, 18 ], [ 7, 19 ], [ 6, 19 ], [ 6, 20 ], [ 3, 20 ], [ 3, 19 ], [ 5, 19 ], [ 5, 16 ]], [[ 4, 17 ], [ 3, 17 ], [ 3, 16 ], [ 4, 16 ]], [[ 17, 17 ], [ 16, 17 ], [ 16, 16 ], [ 17, 16 ]], [[ 14, 14 ], [ 17, 14 ], [ 17, 15 ], [ 14, 15 ]], [[ 13, 15 ], [ 12, 15 ], [ 12, 14 ], [ 13, 14 ]], [[ 18, 14 ], [ 17, 14 ], [ 17, 13 ], [ 18, 13 ]], [[ 10, 11 ], [ 9, 11 ], [ 9, 10 ], [ 10, 10 ]], [[ 15, 11 ], [ 14, 11 ], [ 14, 10 ], [ 15, 10 ]], [[ 4, 7 ], [ 3, 7 ], [ 3, 6 ], [ 4, 6 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a pixel art table.
 */
export default function Table({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Table}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
