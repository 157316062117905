import ammo from './categories/ammo';
import armor from './categories/armor';
import chancery from './categories/chancery';
import clothing from './categories/clothing';
import coins from './categories/coins';
import containers from './categories/containers';
import food from './categories/food';
import kitchen from './categories/kitchen';
import liquids from './categories/liquids';
import miscellaneous from './categories/miscellaneous';
import mystical from './categories/mystical';
import potions from './categories/potions';
import rings from './categories/rings';
import survival from './categories/survival';
import tack from './categories/tack';
import tools from './categories/tools';
import treasure from './categories/treasure';
import trinkets from './categories/trinkets';
import weapons from './categories/weapons';
import wondrous from './categories/wondrous';

import type { Rarity, Size } from '../attributes';

// -- Types --------------------------------------------------------------------

export type LootCategory = typeof lootCategories[number];

/**
 * Incomplete loot config, requiring only the loot `name` property. All other
 * loot properties are optional and will be auto-filled with default values if
 * undefined.
 */
export type LootConfigIncomplete = Partial<LootConfig> & { category: LootCategory; name: string };

/**
 * Incomplete loot config used for setting defaults of a category, requiring
 * only the loot `category` property, and prohibiting the `name` property.
 */
export type LootConfigDefaults = Exclude<Partial<LootConfig> & { category: LootCategory }, 'name'>;

export interface LootConfig {
  category: LootCategory;
  isContainer?: boolean;
  isMagic?: boolean;
  maxCount: number;
  minCount: number;
  name: string;
  rarity: Rarity;
  size: Size;
  variants?: string[];
}

export type MagicOrMundane = 'magic' | 'mundane';

interface LootManifest {
  loot: Record<string, LootConfig>;
  taxonomy: Map<LootCategory, Map<Rarity, Map<MagicOrMundane, string[]>>>;
}

// -- Config -------------------------------------------------------------------

/**
 * Loot categories.
 */
export const lootCategories = [
  'ammo',
  'armor',
  'chancery',
  'clothing',
  'coins',
  'containers',
  'food',
  'kitchen',
  'liquids',
  'miscellaneous',
  'mystical',
  'potions',
  'rings',
  'survival',
  'tack',
  'tools',
  'treasure',
  'trinkets',
  'weapons',
  'wondrous',
] as const;

/**
 * Incomplete loot configs.
 */
const incompleteLootConfigs: { [K in LootCategory]: LootConfigIncomplete[] } = {
  ammo,
  armor,
  chancery,
  clothing,
  coins,
  containers,
  food,
  kitchen,
  liquids,
  miscellaneous,
  mystical,
  potions,
  rings,
  survival,
  tack,
  tools,
  treasure,
  trinkets,
  weapons,
  wondrous,
};

/**
 * Loot config defaults.
 */
const defaults: Pick<LootConfig, 'maxCount' | 'rarity' | 'size'> = {
  maxCount: 1,
  rarity: 'average',
  size: 'small',
};

/**
 * Item rarities that should be indicated in item descriptions.
 */
export const indicateItemRarity: ReadonlySet<Rarity> = new Set([
  'rare',
  'exotic',
  'legendary',
]);

/**
 * Rarities that should be indicated for a set of items.
 */
export const indicateItemSetRarity: ReadonlySet<Rarity> = new Set([
  'uncommon',
  'rare',
  'exotic',
  'legendary',
]);

/**
 * Loot objects grouped by category, mundane or magical, and finally rarity.
 */
export const lootManifest: LootManifest = (() => {
  const manifest = {
    loot: {},
    taxonomy: new Map(),
  } as LootManifest;

  for (const configs of Object.values(incompleteLootConfigs)) {
    for (const config of configs) {
      manifest.loot[config.name] = {
        ...defaults,
        ...config,
      } as LootConfig;

      const lootConfig = manifest.loot[config.name];

      // addToRarityLookup(manifest, lootConfig);
      addToCategoryLookup(manifest, lootConfig);
    }
  }

  return manifest;
})();

// -- Private Functions --------------------------------------------------------

/**
 * Adds an item by category, magic or mundane, then rarity to the given loot
 * manifest.
 *
 * This lookup should be used when rolling loot which has a category "affinity",
 * such as a an armory would be more likely to contain weapons then potpourri.
 */
function addToCategoryLookup(manifest: LootManifest, lootConfig: LootConfig) {
  const { category, isMagic, name, rarity } = lootConfig;
  const magicOrMundane: MagicOrMundane = isMagic ? 'magic' : 'mundane';

  if (!manifest.taxonomy.has(category)) {
    manifest.taxonomy.set(category, new Map());
  }

  if (!manifest.taxonomy.get(category)?.get(rarity)) {
    manifest.taxonomy.get(category)?.set(rarity, new Map());
  }

  if (!manifest.taxonomy.get(category)?.get(rarity)?.get(magicOrMundane)) {
    manifest.taxonomy.get(category)?.get(rarity)?.set(magicOrMundane, []);
  }

  manifest.taxonomy.get(category)?.get(rarity)?.get(magicOrMundane)?.push(name);
}
