/** App domain. */
export const appDomain = 'www.mysticwaffle.com';

/** Application title. */
export const appTitle = 'D&D Dungeon Generator';

/** Minimum supported matrix version. */
export const appVersionMin = 2.0;

/** Current application & matrix version. */
export const appVersion = 2.1;

/** Brand name. */
export const brandName = 'Mystic Waffle';

/** Discord invite url. */
export const discordInviteUrl = 'https://discord.gg/KPQzRVsFTx';

/** Patreon url. */
export const patreonUrl = 'https://www.patreon.com/mysticwaffle';

/** D&D generator v1 url. */
export const v1Url = 'https://v1.mysticwaffle.com';

/**
 * Responsive breakpoint. Viewports smaller than `breakpoint` are rendered as
 * small screens. Viewports greater than or equal to `breakpoint` are rendered
 * as large screens.
 */
export const breakpoint = 1024;
