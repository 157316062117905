import { memo } from 'react';
import { Text } from 'react-konva';

import {
  cellPx,
  colors,
  fontFamilyTitle,
  fontSizeMapTitleLarge,
  fontSizeMapTitleMedium,
  fontSizeMapTitleSmall,
} from '../../../config/map';

import type { MatrixInstructionsArea } from '../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Minimum dimension for a rect to display a numerical label. */
const areaIdLabelRectMinDimensions = 3;

// -- Public Component (Memoized) ----------------------------------------------

export default memo(AreaLabel, (oldProps, newProps) => {
  return Object.values(oldProps.rect).join(',') === Object.values(newProps.rect).join(',')
    && oldProps.number === newProps.number
    && oldProps?.title === newProps?.title;
});

// -- Private Components -------------------------------------------------------

/**
 * Renders an area label.
 */
function AreaLabel({
  number,
  rect,
  title,
}: NonNullable<MatrixInstructionsArea['label']> & { title?: string }) {
  const { height, width, x, y } = rect;

  const fontSize = getFontSize(width, height, title);
  const text = title ? `${number}. ${title}` : number.toString();

  return (
    <Text
      align="center"
      fill={colors.label}
      fontFamily={fontFamilyTitle}
      fontSize={fontSize}
      fontStyle="400"
      height={height * cellPx}
      text={text}
      verticalAlign="middle"
      width={width * cellPx}
      wrap="word"
      x={x * cellPx}
      y={y * cellPx}
    />
  );
}

// -- Private Functions --------------------------------------------------------

/** Returns title font size. */
function getFontSize(width: number, height: number, title?: string) {
  if (width < areaIdLabelRectMinDimensions || height < areaIdLabelRectMinDimensions) {
    return fontSizeMapTitleSmall;
  }

  if (title) {
    return fontSizeMapTitleMedium;
  }

  return fontSizeMapTitleLarge;
}
