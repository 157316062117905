import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { memo } from 'react';

import styles from './TableOfContents.module.css';
import { lootManifest } from '../../config/loot';
import { capitalize, toWords } from '../../lib/string';

const { loot } = lootManifest;

/**
 * Renders the loot table of contents.
 */
export default memo(function TableOfContents() {
  return (
    <TableContainer>
      <Table
        aria-label="Loot Table of Contents"
        stickyHeader
      >
        <TableHead>
          <TableRow>

            <TableCell>Loot</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Rarity</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Notes</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(loot).map(({
            category,
            isContainer,
            isMagic,
            maxCount,
            name,
            rarity,
            size,
            variants,
          }) => {
            const notes = [];

            if (isMagic) {
              notes.push('Magical');
            }

            if (isContainer) {
              notes.push('Container');
            }

            if (maxCount !== 1) {
              notes.push(`Max Count: ${maxCount}`);
            }

            if (variants?.length) {
              notes.push(`Variants: ${variants?.join(', ')}`);
            }

            return (
              <TableRow key={name}>

                <TableCell component="th" scope="row">{name}</TableCell>
                <TableCell>{capitalize(toWords(category))}</TableCell>
                <TableCell>{capitalize(toWords(rarity))}</TableCell>
                <TableCell>{capitalize(toWords(size))}</TableCell>
                <TableCell>
                  {notes.map((note, i) => (
                    <p className={styles.note} key={i}>
                      {note}
                    </p>)
                  )}
                </TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
