import { memo } from 'react';
import { Rect as Rectangle } from 'react-konva';

import { cellPx, colors } from '../../config/map';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map background.
 */
export default memo(function GridLines({
  height,
  width,
}: {
  height: number;
  width: number;
}) {
  return (
    <Rectangle
      data-id="map-background"
      fill={colors.background}
      height={height * cellPx}
      width={width * cellPx}
    />
  );
});
