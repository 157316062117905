import { useCallback, useState } from 'react';

import Artwork from './Artwork';
import DebugDisplay from './DebugDisplay';
import useMapSelection from './hooks/useMapSelection';
import styles from './index.module.css';
import InteractiveStage from './InteractiveStage';
import Actions from './Interface/Actions';
import MapEditorOptions from './Interface/MapEditorOptions';
import { AREA, DRAW_OPTION, TOOL } from '../../lib/matrix';
import useDimensionsObserver from '../hooks/useDimensionsObserver';
import useTabState from '../Interface/hooks/useTabState';
import Layout from '../Layout';

import type { InteractiveMapState } from './hooks/useInteractiveMap';
import type { MapInfoState } from './hooks/useMapInfo';
import type { Brush, Coordinates } from '../../lib/matrix';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an interactive map.
 */
export default function MapCanvas({
  debugMatrix,
  instructions,
  isEmpty,
  mapInfo,
  matrix,
  matrixHeight,
  matrixWidth,
  onClearMap,
  onDownloadMap,
  onDraw,
  onErase,
  onExit,
  onRedo,
  onSetAreaInfo,
  onSetConnectionInfo,
  onSetDimensions,
  onSetTheme,
  onSetTitle,
  onUndo,
  onUpdateRegion,
  theme,
  title,
}: Omit<InteractiveMapState, 'onDownloadMap' | 'currentHistoryEntry'> & MapInfoState & {
  isEmpty: boolean;
  onDownloadMap: () => void;
  onExit: () => void;
}) {
  const {
    height: stageHeight,
    ref: containerRef,
    width: stageWidth,
  } = useDimensionsObserver<HTMLDivElement>();

  const [ activeTool, setActiveTool ] = useState<TOOL>(TOOL.Draw);
  const [ activeBrush, setActiveBrush ] = useState<Brush>(AREA.Dungeon);
  const { activeTabIndex, onSetTab } = useTabState();

  const {
    onSelect,
    selectedRegionId,
  } = useMapSelection({ instructions, matrix });

  const onChangeTool = useCallback((tool: TOOL) => {
    onSelect();

    if (tool === TOOL.Select) {
      onDraw([], AREA.Dungeon, DRAW_OPTION.Free);
    }

    setActiveTool(tool);

    if (tool === TOOL.Draw && activeTabIndex !== 0) {
      onSetTab(0);
    }
  }, [ activeTabIndex, onDraw, onSetTab, onSelect ]);

  const onDrawWithBrush = useCallback((coordinates: Coordinates[], drawOption: DRAW_OPTION, options?: { commit: true }) => {
    onDraw(coordinates, activeBrush, drawOption, options);
  }, [ activeBrush, onDraw ]);

  const onChangeBrush = useCallback((brush: Brush) => {
    setActiveTool(TOOL.Draw);
    setActiveBrush(brush);
  }, []);

  return (
    <Layout
      sidebar={
        <MapEditorOptions
          activeBrush={activeTool === TOOL.Draw ? activeBrush : undefined}
          activeTabIndex={activeTabIndex}
          activeTool={activeTool}
          instructions={instructions}
          isMapEmpty={isEmpty}
          mapInfo={mapInfo}
          matrixHeight={matrixHeight}
          matrixWidth={matrixWidth}
          onChangeBrush={onChangeBrush}
          onSetAreaInfo={onSetAreaInfo}
          onSetConnectionInfo={onSetConnectionInfo}
          onSetDimensions={onSetDimensions}
          onSetTab={onSetTab}
          onSetTheme={onSetTheme}
          onSetTitle={onSetTitle}
          onUpdateRegion={onUpdateRegion}
          selectedRegionId={selectedRegionId}
          theme={theme}
          title={title}
        />
      }
    >
      <div className={styles.content}>
        <Actions
          activeBrush={activeBrush}
          activeTool={activeTool}
          isMapEmpty={isEmpty}
          onClearMap={onClearMap}
          onDownloadMap={onDownloadMap}
          onExit={onExit}
        />

        <div
          className={styles.mapCanvasContainer}
          ref={containerRef}
        >
          {Boolean(stageWidth) && Boolean(stageHeight) && (
            <InteractiveStage
              activeBrush={activeBrush}
              activeTool={activeTool}
              instructions={instructions}
              matrix={matrix}
              matrixHeight={matrixHeight}
              matrixWidth={matrixWidth}
              onChangeBrush={onChangeBrush}
              onChangeTool={onChangeTool}
              onDraw={onDrawWithBrush}
              onErase={onErase}
              onRedo={onRedo}
              onSelect={onSelect}
              onUndo={onUndo}
              selectedRegionId={selectedRegionId}
              stageHeight={stageHeight}
              stageWidth={stageWidth}
            >
              <Artwork
                areaInfo={mapInfo.areaInfo}
                connectionInfo={mapInfo.connectionInfo}
                instructions={instructions}
                matrixHeight={matrixHeight}
                matrixWidth={matrixWidth}
                theme={theme}
                title={title}
              />

              {debugMatrix && <DebugDisplay debugMatrix={debugMatrix} />}
            </InteractiveStage>
          )}
        </div>
      </div>
    </Layout>
  );
}
