import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'food',
};

const food: { [name: string]: Partial<LootConfig> | null } = {
  'Bread, loaf': { maxCount: 3 },
  Butter: { variants: [ 'bucket', 'bowl', 'block' ] },
  'Cheese, round': { maxCount: 4 },
  'Cheese, wedge': { maxCount: 4 },
  'Cinnamon, pouch': { rarity: 'rare', size: 'tiny' },
  'Cloves, pouch': { rarity: 'rare', size: 'tiny' },
  Egg: { maxCount: 24, size: 'tiny' },
  Figs: { size: 'tiny' },
  Fish: { variants: [ 'salmon', 'trout', 'carp', 'herring' ] },
  Flour: { variants: [ 'pouch', 'bag', 'sack' ]  },
  'Ginger, pouch': { rarity: 'rare', size: 'tiny' },
  Grain: { maxCount: 10 },
  Honey: { maxCount: 10, rarity: 'uncommon' },
  Lentils: { variants: [ 'pouch', 'bag', 'sack' ] },
  Meat: { variants: [ 'chicken', 'dog', 'horse', 'pig', 'deer', 'sheep', 'goat', 'duck' ] },
  Nuts: { maxCount: 50, size: 'tiny' },
  Pepper: { rarity: 'rare', size: 'tiny', variants: [ 'pouch', 'grinder' ] },
  Raisins: { maxCount: 50, size: 'tiny' },
  'Rations, dried': { maxCount: 50 },
  Rice: { variants: [ 'pouch', 'bag', 'sack' ] },
  'Saffron, pouch': { rarity: 'rare', size: 'tiny' },
  'Salt, pouch': { rarity: 'rare', size: 'tiny' },
  Soup: { variants: [ 'meat', 'vegetables', 'potato' ] },
  'Spice, pouch': { rarity: 'rare', size: 'tiny', variants: [ 'exotic', 'rare', 'uncommon' ] },
  'Sugar, pouch': { rarity: 'rare', size: 'tiny' },
  Vegetables: { maxCount: 20 },
  Wheat: { variants: [ 'pouch', 'bag', 'sack' ]  },
};

const configs: LootConfigIncomplete[] = Object.entries(food).map(([ name, config ]) => ({
  ...defaults,
  ...config,
  name,
}));

export default configs;
