import { useEffect }  from 'react';

import { cellPx } from '../../../config/map';
import { CONNECTION, connectionDirectionsMeridian, directionsCardinal } from '../../../lib/matrix';
import {
  getCellPath,
  getConnectionBorderPaths,
  getRectanglePath,
} from '../../../lib/matrix/path';
import {
  getBoundingRect,
  getConnectionDirection,
} from '../../../lib/matrix/utility';
import { differencePaths, unionPaths } from '../../../lib/polygon';

import type {
  ConnectionDirection,
  Coordinates,
  DirectionCardinal,
  MatrixInstructions,
  MatrixInstructionsArea,
  MatrixInstructionsConnection,
  Path,
} from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

/** A path cache entry. */
interface CacheEntry { key: string; paths: Path[] }

/** Region shape, border, and shadow paths. */
export interface RegionPaths {
  areaBorders: Path[][];
  areaPaths: Path[][];
  connectionBorders: Path[][];
  connectionPaths: Path[];
  shadowPaths: Path[][];
}

// -- Config -------------------------------------------------------------------

/**
 * A cache of area border paths, keyed by area id.
 *
 * The cache key is a compound of the area's shape paths cache key and a comma
 * separated string of the area's connection cell coordinates.
 */
const areaBorderCache: Map<number, CacheEntry> = new Map();

/**
 * A cache of area shape paths, keyed by area id.
 *
 * The cache key is a comma separated string of the area's cell coordinates.
 */
const areaPathCache: Map<number, CacheEntry> = new Map();

/**
 * A cache of connection border paths (which are also used as shape paths),
 * keyed by connection id.
 *
 * The cache key a compound of the connection's type, direction, and a comma
 * separated string of the connection's cell coordinates.
 */
const connectionBorderCache: Map<number, CacheEntry> = new Map();

/**
 * A cache of shadow shape paths (cells directly surrounding area and exterior
 * area connections), keyed by area id.
 *
 * The cache key is a comma separated string of the shadow's cell coordinates.
 */
const shadowPathCache: Map<number, CacheEntry> = new Map();

export {
  areaBorderCache as testAreaBorderCache,
  areaPathCache as testAreaPathCache,
  connectionBorderCache as testConnectionBorderCache,
  shadowPathCache as testShadowPathCache,
};

// -- Public Hook --------------------------------------------------------------

/**
 * Returns memoized region, region shadow, and region border paths.
 */
export default function useRegionPaths({
  areas,
  connections,
}: MatrixInstructions): RegionPaths {
  const paths: RegionPaths = {
    areaBorders: [],
    areaPaths: [],
    connectionBorders: [],
    connectionPaths: [],
    shadowPaths: [],
  };

  for (const area of areas.values()) {

    // Area paths

    const {
      key: areaPathsKey,
      paths: areaPaths,
    } = getAreaPaths(area);

    // Area border paths

    const areaBorders = getAreaBorderPaths(
      area,
      areaPaths,
      areaPathsKey,
      connections
    );

    // Shadow paths

    const shadowPaths = getShadowPaths(area, areaPaths);

    paths.areaBorders.push(areaBorders);
    paths.areaPaths.push(areaPaths);
    paths.shadowPaths.push(shadowPaths);
  }

  for (const connection of connections.values()) {

    // Connection paths

    const connectionPaths = getConnectionPaths(connection);

    if (connectionPaths) {
      const { connectionBorders, connectionShape } = connectionPaths;

      paths.connectionBorders.push(connectionBorders);
      paths.connectionPaths.push(connectionShape);
    }
  }

  useEffect(() => () => {
    areaBorderCache.clear();
    areaPathCache.clear();
    connectionBorderCache.clear();
    shadowPathCache.clear();
  }, []);

  return paths;
}

// -- Private Functions --------------------------------------------------------

/**
 * Returns area border paths.
 */
function getAreaBorderPaths(
  area: MatrixInstructionsArea,
  areaPaths: Path[],
  areaPathsKey: string,
  connections: MatrixInstructions['connections']
): Path[] {
  const { areaId, connectionIds } = area;
  const connectionOpenings = getAreaBorderConnectionOpenings(connections, connectionIds);
  const key = `${areaPathsKey}:${connectionOpenings.join(',')}`;

  let entry = areaBorderCache.get(areaId);

  if (entry?.key !== key) {
    const paths = getAreaBorders(areaPaths, connectionOpenings);

    entry = { key, paths };
    areaBorderCache.set(areaId, entry);
  }

  return entry.paths;
}

/**
 * Returns an area's border paths with connection cells omitted.
 */
function getAreaBorders(areaPaths: Path[], connectionOpenings: Path[]) {
  const paths: Path[] = [];

  for (const path of areaPaths) {
    paths.push([ ...path, path[0] ]); // Close paths
  }

  if (!connectionOpenings.length) {
    return paths;
  }

  return differencePaths(paths, connectionOpenings);
}

/**
 * Returns an area's paths.
 */
function getAreaPaths({ areaId, coordinates }: MatrixInstructionsArea): CacheEntry {
  const key = coordinates.join(',');

  let entry = areaPathCache.get(areaId);

  if (entry?.key !== key) {
    const paths = unionPaths(
      coordinates
        // Sorting by `x` coordinates ensures a correctly unioned cell path.
        .sort(([ ax ], [ bx ]) => ax - bx)
        .map(getCellPath));

    entry = { key, paths };
    areaPathCache.set(areaId, entry);
  }

  return entry;
}

/**
 * Returns an array of area opening paths due to connection cells for the given
 * connection ids. Used for omitting connection segments in area border paths.
 */
function getAreaBorderConnectionOpenings(
  connections: MatrixInstructions['connections'],
  connectionIds: Set<number>
): Path[] {
  const connectionPaths: Path[] = [];

  for (const connectionId of connectionIds) {
    const connection = connections.get(connectionId);

    if (!connection) {
      throw new TypeError(`Invalid connection "${connectionId}" in getAreaBorderConnectionCoordinates() in useRegionPaths()`);
    }

    const { areaDirections, coordinates, type } = connection;
    const connectionDirection = getConnectionDirection(areaDirections);

    if (isInvisibleConnection(type, connectionDirection)) {
      continue;
    }

    const isMeridian = connectionDirectionsMeridian.has(connectionDirection);

    if (connection.type === CONNECTION.SecretDoor) {
      // Exclude only a single edge for secret doorways.
      const paths = coordinates.map((cellCoordinates) => {
        return isMeridian
          ? [ getAreaBorderOpeningPath(cellCoordinates, 'south') ]
          : [ getAreaBorderOpeningPath(cellCoordinates, 'east') ];
      }).flat();

      connectionPaths.push(...paths);
      continue;
    }

    const paths = coordinates.map((cellCoordinates) => {
      return isMeridian
        ? [ getAreaBorderOpeningPath(cellCoordinates, 'north'), getAreaBorderOpeningPath(cellCoordinates, 'south') ]
        : [ getAreaBorderOpeningPath(cellCoordinates, 'east'), getAreaBorderOpeningPath(cellCoordinates, 'west') ];
    }).flat();

    connectionPaths.push(...paths);
  }

  return connectionPaths;
}

/**
 * Returns a 2px wide or tall path for subtracting cell edges from area borders.
 */
function getAreaBorderOpeningPath(coordinates: Coordinates, edge: DirectionCardinal): Path {
  let [ x, y ] = coordinates;

  x = x * cellPx;
  y = y * cellPx;

  switch (edge) {
    case 'north':
      return getRectanglePath({ height: 2, width: cellPx, x, y: y - 1 });

    case 'east':
      return getRectanglePath({ height: cellPx, width: 2, x: x + cellPx - 1, y });

    case 'south':
      return getRectanglePath({ height: 2, width: cellPx, x, y: y + cellPx - 1 });

    case 'west':
      return getRectanglePath({ height: cellPx, width: 2, x: x - 1, y });
  }
}

/**
 * Returns connection shape and border paths.
 */
function getConnectionPaths(connection: MatrixInstructionsConnection): {
  connectionBorders: Path[];
  connectionShape: Path;
} | undefined {
  const { areaDirections, connectionId, coordinates, type } = connection;
  const connectionDirection = getConnectionDirection(areaDirections);
  const key = `${type}:${connectionDirection}:${coordinates.join(',')}`;

  let entry = connectionBorderCache.get(connectionId);

  if (entry?.key !== key) {
    const { height, width, x, y } = getBoundingRect(coordinates);

    const rect = {
      height: height * cellPx,
      width: width * cellPx,
      x: x * cellPx,
      y: y * cellPx,
    };

    const paths = getConnectionBorderPaths(rect, connectionDirection, type);

    entry = { key, paths };
    connectionBorderCache.set(connectionId, entry);
  }

  if (isInvisibleConnection(type, connectionDirection)) {
    return;
  }

  return {
    connectionBorders: entry.paths,
    connectionShape: entry.paths.flat(),
  };
}

/**
 * Returns a shadow shape's paths.
 */
function getShadowPaths(area: MatrixInstructionsArea, areaPaths: Path[]): Path[] {
  const { areaId, shadows } = area;
  const shadowCoordinates = [ ...shadows.values() ];
  const key = shadowCoordinates.join(',');

  let entry = shadowPathCache.get(areaId);

  if (entry?.key !== key) {
    const paths = unionPaths([
      ...areaPaths,
      ...shadowCoordinates.map(getCellPath),
    ]);

    entry = { key, paths };
    shadowPathCache.set(areaId, entry);
  }

  return entry.paths;
}

/**
 * Returns if a connection should exclude border paths and area openings.
 * These connection may have connection details rendered in `<Connection>`
 */
function isInvisibleConnection(
  type: CONNECTION,
  connectionDirection: ConnectionDirection
): boolean {
  if (type === CONNECTION.SecretPassageway) {
    // Omit connection borders for secret passageways.
    return true;
  }

  if (type === CONNECTION.SecretDoor && directionsCardinal.has(connectionDirection as DirectionCardinal)) {
    // Omit connection borders for exterior secret doors.
    return true;
  }

  return false;
}
