// @ts-expect-error - No types for `esm-seedrandom`, use @types/seedrandom
import { prng_arc4 } from 'esm-seedrandom'; // eslint-disable-line camelcase

import type { Range } from './number';
import type { PRNG } from 'seedrandom';

// -- Docs ---------------------------------------------------------------------

/**
 * The dice lib uses `seedrandom` for pseudo random number generation.
 *
 * @see https://github.com/davidbau/seedrandom
 * @see https://github.com/shanewholloway/js-esm-seedrandom
 */

// -- Types --------------------------------------------------------------------

export type ProbabilityTable<Item> = Map<Item, number>;

export type Dice = ReturnType<typeof getDiceBag>;

// -- Config -------------------------------------------------------------------

/** Minimum value for a percentile dice roll. */
export const minPercent = 1;

/** Maximum value for a percentile dice roll. */
export const maxPercent = 100;

// -- Public Functions ---------------------------------------------------------

/**
 * Returns a probability table based on the numeric score of each item.
 *
 * Note, if not given exact percentage scores, the last item will be adjusted to
 * ensure the total is 100%.
 */
export function createProbabilityTable<T extends string>(
  items: T[] = [],
  scores: Record<T, number>
): ProbabilityTable<T> {
  const scoreTotal = items.reduce((total, item) => total + scores[item], 0);
  const probabilityTable: ProbabilityTable<T> = new Map();

  let total = 0;
  let i = 0;

  for (const item of items) {
    const score = scores[item];

    if (!score) {
      throw new TypeError(`Invalid item, "${item}", in createProbabilityTable()`);
    }

    const isLast = i === items.length - 1;

    const chance = isLast
      ? maxPercent - total // Ensure total is 100% (because JS math precision).
      : Math.max(Math.floor((score / scoreTotal) * 100), 1);

    probabilityTable.set(item, chance);

    total += chance;
    i++;
  }

  return probabilityTable;
}

/**
 * Creates a closure around a seeded pseudo random number generator and returns
 * and object with methods to operate on the generator.
 *
 * TDL pass a seed arg, no need for an obj
 */
export function getDiceBag({ seed }: { seed?: string } = {}) {
  const prng: PRNG = prng_arc4(seed);

  return {
    getProbabilityRoll: <Item>(probabilityTable: ProbabilityTable<Item>) => getProbabilityRoll(prng, probabilityTable),
    roll: (min = 0, max = 1) => roll(prng, min, max),
    rollArrayItem: <Item>(items: Item[] | readonly Item[]) => rollArrayItem(prng, items),
    rollPercentile: (chance: number) => rollPercentile(prng, chance),
    rollRange: ([ min, max ]: Range = [ 0, 1 ]) => roll(prng, min, max),
    shuffle: <Item>(items: Item[]) => shuffle(prng, items),
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Creates closure around the given probability table and returns a function
 * which rolls an item from the table.
 *
 * Example table:
 *
 * ```
 * const enemies: ProbabilityTable<Creature> = new Map([
 *   [ 'giant octopus', 23 ],
 *   [ 'minotaur',      16 ],
 *   [ 'goblins',       61 ],
 * ]);
 * ```
 */
function getProbabilityRoll<Item>(
  prng: PRNG,
  probabilityTable: ProbabilityTable<Item>
) {
  if (!probabilityTable.size) {
    throw new TypeError('Probability table must have a size in getProbabilityRoll()');
  }

  // Create a probability "roll table", which is a Map keyed by the aggregated
  // percentage chances for each item. For example:
  //
  //   [ 16,  'giant octopus' ], // 0 + 16
  //   [ 39,  'minotaur'      ], // 16 + 23
  //   [ 100, 'goblins'       ], // 39 + 61
  //
  const rollTable = [ ...probabilityTable.entries() ].sort(([ , a ], [ , b ]) => b - a).reduce((table, [ item, probability ], i) => {
    if (probability <= 0) {
      throw new TypeError(`Probability of "${probability}" for "${item}" must be greater than 0 in getProbabilityRoll()`);
    }

    if (probability > maxPercent) {
      throw new TypeError(`Probability of "${probability}" for "${item}" must not exceed ${maxPercent} in getProbabilityRoll()`);
    }

    const previousProbability = i === 0 ? 0 : table[i - 1][0];
    const percent = previousProbability + probability;

    if (i === (probabilityTable.size - 1) && percent !== maxPercent) {
      throw new TypeError(`Probabilities totaling "${percent}" for "${item}" must equal ${maxPercent} in getProbabilityRoll()`);
    }

    table.push([ percent, item ]);

    return table;
  }, [] as [ percent: number, item: Item ][]);

  return (): Item => {
    const result = roll(prng, minPercent, maxPercent);
    const [ , item ] = rollTable.find(([ percent ]) => result <= percent)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return item;
  };
}

/**
 * Rolls a random integer between `min` and `max`, inclusive.
 */
function roll(prng: PRNG, min = 0, max = 1): number {
  min = Math.ceil(min);
  max = Math.floor(max);

  if (max < min) {
    throw new TypeError('Maximum must be larger than minimum in roll()');
  }

  return Math.floor(prng() * (max - min + 1) + min);
}

/**
 * Rolls and returns a random item from the given array
 */
function rollArrayItem<Item>(prng: PRNG, items: Item[] | readonly Item[]): Item {
  if (!items.length) {
    throw new TypeError('Items can not be empty in rollArrayItem()');
  }

  return items[Math.floor(prng() * items.length)];
}

/**
 * Rolls a percentile die and returns `true` if the roll is less than than given
 * `chance`, otherwise `false`.
 */
function rollPercentile(prng: PRNG, chance: number): boolean {
  if (chance > maxPercent) {
    throw new TypeError('Chance must be less then 100 in rollPercentile()');
  }

  if (chance < 0) {
    throw new TypeError('Chance must be 0 or greater in rollPercentile()');
  }

  if (chance === 0) {
    return false;
  }

  return roll(prng, minPercent, maxPercent) <= chance;
}

/**
 * Returns a shuffled shallow copy of the given array.
 */
function shuffle<Item>(prng: PRNG, items: Item[]): Item[] {
  const shuffled: Item[] = [];

  for (const item of items) {
    const index = roll(prng, 0, items.length - 1);
    shuffled.splice(index, 0, item);
  }

  return shuffled;
}
