import { Button, Stack } from '@mui/material';

import styles from './index.module.css';
import { inputStackSpacing } from '../../../config/ui';

// -- Public Component ---------------------------------------------------------

/**
 * Renders an input panel wrapper.
 */
export default function InputPanel({
  'aria-label': ariaLabel,
  children,
  onSetShowInfo,
  showInfo,
}: {
  'aria-label': string;
  children: React.ReactNode;
  onSetShowInfo: (showInfo: boolean) => void;
  showInfo: boolean;
}) {
  return (
    <form
      aria-label={ariaLabel}
      className={styles.panel}
    >
      <div className={styles.options}>
        <Button
          aria-label={`${showInfo ? 'Hide' : 'Show'} editor info`}
          aria-pressed={showInfo}
          color={showInfo ? 'inherit' : 'primary'}
          onClick={() => onSetShowInfo(!showInfo)}
          size="small"
          variant="outlined"
        >
          Info
        </Button>
      </div>

      <Stack spacing={inputStackSpacing}>
        {children}
      </Stack>
    </form>
  );
}
