import {
  Button,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import { memo } from 'react';

import styles from './HistoryButtons.module.css';
import {
  Redo as RedoIcon,
  Undo as UndoIcon,
} from '../../Display/Icons';
import useModifierKey from '../../Interface/hooks/useModifierKey';

// -- Config -------------------------------------------------------------------

/** Button Group styles. */
const buttonGroupSx = {
  '& .MuiButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: 'inherit',
  },
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: 'inherit',
  },
};

/** Button styles. Mimics `MuiToggleButtonGroup` hover styles */
const buttonSx = {
  '& > span': {
    transitionDuration: '300ms',
    transitionProperty: 'background',
  },
  '&:disabled': {
    background: 'var(--color-overlay)',
    pointerEvents: 'auto',
  },
  '&:hover': {
    background: 'var(--color-overlay)',
    boxShadow: 'none',
  },
  '&:hover > span': {
    background: 'rgba(255 255 255 / 8%)',
  },
  background: 'var(--color-overlay)',
  borderRadius: '0.25rem',
  boxShadow: 'none',
  mr: '0.5rem',
  padding: 0,
};

/** Toolbar tooltip delay. */
const tooltipDelay = 500;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map's undo and redo buttons.
 */
export default memo(function HistoryButtons({
  className,
  onRedo,
  onUndo,
}: {
  className: string;
  onRedo?: () => void;
  onUndo?: () => void;
}) {
  const modifierKey = useModifierKey();

  return (
    <ButtonGroup
      aria-label="History controls"
      className={className}
      sx={buttonGroupSx}
    >
      <HistoryButton
        icon={UndoIcon}
        onClick={onUndo}
        title={`Undo (${modifierKey} Z)`}
      />

      <HistoryButton
        icon={RedoIcon}
        onClick={onRedo}
        title={`Redo (${modifierKey} Shift Z)`}
      />
    </ButtonGroup>
  );
});

// -- Private Components -------------------------------------------------------

/**
 * Renders a history button.
 */
function HistoryButton({
  className = '',
  icon: Icon,
  onClick,
  title,
}: {
  className?: string;
  icon: React.ComponentType<{ className: string }>;
  onClick?: () => void;
  title: string;
}) {
  return (
    <Button
      aria-label={title}
      className={className}
      color="inherit"
      disabled={!onClick}
      onClick={onClick}
      size="small"
      sx={buttonSx}
      variant="contained"
    >
      <Tooltip
        enterDelay={tooltipDelay}
        placement="top"
        title={title}
      >
        <span>
          <Icon aria-hidden className={styles.icon} />
        </span>
      </Tooltip>
    </Button>
  );
}
