import { getDebugMatrix } from './debug';
import {
  filterAreaDraw,
  filterConnectionDraw,
  filterDetailDraw,
  filterErase,
} from './draw';
import {
  cloneMatrix,
  getAdjacentCells,
  getCellGroups,
  getCellIdPool,
  getCellValue,
  getConnectionCellAreaDirections,
  getConnectionCellDirection,
  getCoordinatesInDirection,
  getDrawCoordinates,
  getMatrixDimensions,
  getMaxRectangle,
  getMergeId,
  getOppositeDirection,
  getRegion,
  initializeClone,
  initializeMatrix,
  isArea,
  isConnection,
  offsets,
  toCoordinatesMap,
} from './utility';
import { getMapEntryRequired, getOrSetMapEntry } from '..';
import { getRandomSeed } from '../seed';

import type { Debug } from './debug';
import type { Dice } from '../dice';

// -- Types --------------------------------------------------------------------

/**
 * A map of cell values, keyed by direction in clockwise order, for some or all
 * of the cells surrounding a single cell based on a cell detection strategy.
 */
export type AdjacentCellMap = Map<Direction, {
  coordinates: Coordinates;
  value: CellValue;
}>;

/**
 * Brush types.
 */
export type Brush = AREA | CONNECTION | DETAIL;

/**
 * Matrix cell values.
 *
 *   - Empty cells have a value of `null`.
 *   - Cells in an area have a positive numerical identifier (the area id).
 *   - Cells which connect areas have a negative numerical identifier.
 *   - Cells outside of the matrix's bounds are undefined.
 */
export type CellValue = number | null;

/**
 * A map of a connection's directions and the associated area id with each
 * direction.
 */
export type ConnectionAreaDirections = Map<DirectionCardinal, number>;

/**
 * A connection's computed direction.
 */
export type ConnectionDirection = DirectionCardinal | DirectionMeridian | DirectionParallel;

/**
 * Matrix x & y coordinates. Coordinates are measured in matrix units as the
 * distance from the origin (0,0), the top left corner of the matrix.
 */
export type Coordinates = readonly [ x: number, y: number ];

/**
 * A map of coordinates, keyed by their corresponding coordinates keys.
 */
export type CoordinatesMap = Map<CoordinatesKey, Coordinates>;

/**
 * Textual representation of a cell's x & y coordinates as a comma separated
 * string. Useful as unique identifiers for cells.
 */
export type CoordinatesKey = `${number},${number}`;

/**
 * Matrix details.
 *
 * When adding new properties, be sure to add any necessary deep clone logic to
 * `initializeClone()` in `src/lib/map/utility.ts`.
 */
export interface Detail {
  coordinates: Coordinates;
  rotation?: number;
  type: DETAIL;
}

/**
 * Lookup map containing matrix details, keyed by coordinates keys.
 */
export type Details = Map<CoordinatesKey, Detail>;

/**
 * Rectangular dimensions in matrix units.
 */
export type Dimensions = readonly [ width: number, height: number ];

/**
 * Directions.
 */
export type Direction = DirectionCardinal | DirectionOrdinal;

/**
 * Cardinal directions.
 */
export type DirectionCardinal = 'north' | 'east' | 'south' | 'west';

/**
 * Meridian direction.
 */
export type DirectionMeridian = 'north-south';

/**
 * Ordinal directions.
*/
export type DirectionOrdinal = 'north-east' | 'south-east' | 'south-west' | 'north-west';

/**
 * Parallel direction.
 */
export type DirectionParallel = 'east-west';

/**
 * Matrix clone properties. Used when internally cloning a matrix instance.
 */
export type MatrixClone = {
  details: Details;
  matrix: MatrixMutable;
  regions: Regions;
};

/**
 * Matrix history entry. Used for restoring, undoing, and redoing matrix state.
 */
export interface MatrixHistoryEntry {
  details?: { [key in DETAIL]?: Omit<Detail, 'type'>[] };
  dimensions: Dimensions;
  regions?: (Omit<Region, 'coordinates'> & {
    coordinates: Coordinates[];
  })[];
}

/**
 * Immutable multi dimensional array of cells.
 */
export type MatrixImmutable = readonly (readonly CellValue[])[];

/**
 * Instructions for rendering a matrix on the canvas.
 */
export interface MatrixInstructions {
  /** Area instructions, keyed by area id. */
  areas: Map<number, MatrixInstructionsArea>;

  /** Connection instructions, keyed by connection id. */
  connections: Map<number, MatrixInstructionsConnection>;
}

/**
 * Matrix area.
 */
export interface MatrixInstructionsArea {
  areaId: number;
  connectionIds: Set<number>;
  coordinates: Coordinates[];
  details: Detail[] | null;
  label: {
    number: number;
    rect: Rect;
  };
  seed: string;
  shadows: CoordinatesMap;
  type: AREA;
}

/**
 * Matrix connection.
 */
export interface MatrixInstructionsConnection {
  areaDirections: ConnectionAreaDirections;
  connectionId: number;
  coordinates: Coordinates[];
  type: CONNECTION;
}

/**
 * Mutable multi dimensional array of cells.
 */
export type MatrixMutable = CellValue[][];

/**
 * Pixel-based coordinates which form a shape.
 */
export type Path = Coordinates[];

/**
 * Dimensions and coordinates for a rectangle, which can either be in matrix
 * units or pixel units.
 */
export interface Rect {
  height: number;
  width: number;
  x: number;
  y: number;
}

/**
 * Matrix region, consisting of adjacent matrix cells of identical values.
 *
 * When adding new properties, be sure to add any necessary deep clone logic to
 * `initializeClone()` in `src/lib/map/utility.ts`.
 */
export interface Region {
  coordinates: CoordinatesMap;
  id: number;
  seed: string;
  type: RegionType;
}

/* Updatable region properties. */
export type RegionUpdate = Partial<Pick<Region, 'seed' | 'type'>>;

/**
 * Lookup map containing properties for each region (grouped area and connection
 * cells), keyed by cell id (a non-null cell value).
 */
export type Regions = Map<number, Region>;

/**
 * Region types.
 */
export type RegionType = AREA | CONNECTION;

// -- Enums --------------------------------------------------------------------

/**
 * Area cell types.
 */
export enum AREA {
  Dungeon = 'Dungeon',
}

/**
 * Connection cell types.
 */
export enum CONNECTION {
  Connection = 'Connection',
  Passageway = 'Passageway',
  SecretDoor = 'SecretDoor',
  SecretPassageway = 'SecretPassageway',
  Stairway = 'Stairway',
  WoodDoor = 'WoodDoor',
}

/**
 * Detail cell types.
 */
export enum DETAIL {
  Chest = 'Chest',
  Column = 'Column',
  Crates = 'Crates',
  Fountain = 'Fountain',
  Rubble = 'Rubble',
  Table = 'Table',
  Trap = 'Trap',
}

/**
 * Matrix draw tool options.
 */
export enum DRAW_OPTION {
  Free = 'Free',
  Line = 'Line',
  Rectangle = 'Rectangle',
  Stamp = 'Stamp',
}

/**
 * Matrix tool types.
 */
export enum TOOL {
  Draw = 'Draw',
  Erase = 'Erase',
  Pan = 'Pan',
  Select = 'Select',
}

// -- Config -------------------------------------------------------------------

/** Area region types. */
export const areaRegions: Set<AREA> = new Set(Object.values(AREA));

/** All brush types: areas, connections, and details. */
export const brushes: readonly Brush[] = [
  ...Object.values(AREA),
  ...Object.values(CONNECTION),
  ...Object.values(DETAIL),
];

/** Horizontal connection directions. */
export const connectionDirectionsMeridian = new Set<ConnectionDirection>([
  'north-south',
  'north',
  'south',
]);

/**
 * Connection maximum span in matrix units.
 *
 * For horizontal connections, this is the maximum number of horizontal cell a
 * connection can have. For vertical connections, this is the number of vertical
 * cells a connection can have.
 */
export const connectionMaxSpan = 4;

/** Connection types.  */
export const connections: Set<CONNECTION> = new Set(Object.values(CONNECTION));

/** Door connections types. */
export const connectionsDoors = new Set<CONNECTION>([
  CONNECTION.WoodDoor,
]);

/** Lockable connection types. */
export const connectionsLockable = new Set<CONNECTION>([
  CONNECTION.WoodDoor,
]);

/** Detail types. */
export const details: Set<DETAIL> = new Set(Object.values(DETAIL));

/** Cardinal directions. */
export const directionsCardinal = new Set<DirectionCardinal>([
  'north',
  'east',
  'south',
  'west',
]);

/** Ordinal directions. */
export const directionsOrdinal = new Set<DirectionOrdinal>([
  'north-east',
  'south-east',
  'south-west',
  'north-west',
]);

/** Brush draw options. The first draw option is the default for the brush. */
export const drawOptionsBrush: Record<Brush, DRAW_OPTION[]> = {
  // Areas
  [AREA.Dungeon]: [ DRAW_OPTION.Rectangle, DRAW_OPTION.Free ],

  // Connections
  [CONNECTION.Connection]: [ DRAW_OPTION.Rectangle ],
  [CONNECTION.Passageway]: [ DRAW_OPTION.Rectangle ],
  [CONNECTION.SecretDoor]: [ DRAW_OPTION.Rectangle ],
  [CONNECTION.SecretPassageway]: [ DRAW_OPTION.Rectangle ],
  [CONNECTION.Stairway]: [ DRAW_OPTION.Rectangle ],
  [CONNECTION.WoodDoor]: [ DRAW_OPTION.Rectangle ],

  // Details
  [DETAIL.Chest]: [ DRAW_OPTION.Stamp ],
  [DETAIL.Column]: [ DRAW_OPTION.Stamp ],
  [DETAIL.Crates]: [ DRAW_OPTION.Rectangle, DRAW_OPTION.Free ],
  [DETAIL.Fountain]: [ DRAW_OPTION.Rectangle ],
  [DETAIL.Rubble]: [ DRAW_OPTION.Rectangle, DRAW_OPTION.Free ],
  [DETAIL.Table]: [ DRAW_OPTION.Stamp ],
  [DETAIL.Trap]: [ DRAW_OPTION.Stamp ],
};

/** Brush draw options. The first draw option is the default for the brush. */
export const drawOptionsErase: DRAW_OPTION[] = [
  DRAW_OPTION.Free,
  DRAW_OPTION.Rectangle,
];

// -- Map Matrix ---------------------------------------------------------------

/**
 * Matrix manager, containing a multi dimensional array of cell values,
 * positions, and properties which produce regions and details from contiguous
 * matrix cells.
 *
 *   > matrix, n.
 *   >
 *   > "A place or medium in which something is originated, produced, or
 *   > developed; the environment in which a particular activity or process
 *   > begins..."
 *   >
 *   >   – [Oxford English Dictionary](https://www.oed.com/search/dictionary/?q=matrix)
 */
export default class Matrix {

  // -- Private Properties -----------------------------------------------------

  /** Multi dimensional array of cell values and positions. */
  private matrix: MatrixMutable;

  /** Map of the matrix's regions. */
  private regions: Regions;

  /** Map of the matrix's details. */
  private details: Details;

  /** Dice for seed randomization. */
  private dice?: Dice;

  // -- Constructor ------------------------------------------------------------

  /**
   * Matrix constructor.
   */
  constructor(entry?: MatrixHistoryEntry, dice?: Dice, clone?: Matrix) {
    const { details: mapDetails, matrix, regions } = clone
      ? initializeClone({ details: clone.details, matrix: clone.matrix, regions: clone.regions })
      : initializeMatrix(entry);

    this.details = mapDetails;
    this.regions = regions;
    this.matrix = matrix;
    this.dice = dice;
  }

  // -- Public Methods ---------------------------------------------------------

  /**
   * Returns a new matrix, cloned from the current matrix.
   */
  clone = (): Matrix => new Matrix(undefined, undefined, this);

  /**
   * Assigns area or connection cell values to the matrix, or places details at
   * the given coordinates.
   */
  draw = (
    coordinates: Coordinates[],
    brush: Brush,
    drawOption: DRAW_OPTION
  ): boolean => {
    if (!drawOptionsBrush[brush]) {
      throw new TypeError(`Invalid brush "${brush}" in draw()`);
    }

    if (!drawOptionsBrush[brush].includes(drawOption)) {
      throw new TypeError(`Invalid draw option "${drawOption}" for brush "${brush}" in draw()`);
    }

    if (coordinates.length === 0) {
      return false;
    }

    if (brush in AREA) {
      return this.drawAreaCells(coordinates, brush as AREA, drawOption);
    }

    if (brush in CONNECTION) {
      return this.drawConnectionCells(coordinates, brush as CONNECTION);
    }

    if (brush in DETAIL) {
      return this.drawDetail(coordinates, brush as DETAIL, drawOption);
    } /* v8 ignore next 2 */

    throw new TypeError(`Invalid brush "${brush}" in draw()`);
  };

  /**
   * Removes cell values from the given coordinates and returns the grid
   * instance. Returns a boolean indicating if an erase occurred.
   */
  erase = (eraseCoordinates: Coordinates[], drawOption: DRAW_OPTION): boolean => {
    if (eraseCoordinates.length === 0) {
      return false;
    }

    return this.eraseCells(eraseCoordinates, drawOption);
  };

  /**
   * Returns dimensions of the matrix.
   */
  getDimensions = (): Dimensions => {
    return getMatrixDimensions(this.matrix);
  };

  /**
   * Returns a matrix for debugging.
   */
  getDebugMatrix = (debug?: Debug) => getDebugMatrix(
    this.matrix,
    this.regions,
    this.details,
    debug
  );

  /**
   * Returns an entry for storage in matrix history.
   */
  getHistoryEntry = (): MatrixHistoryEntry => {
    const historyEntry: MatrixHistoryEntry = {
      dimensions: this.getDimensions(),
    };

    if (!this.regions.size) {
      return historyEntry;
    }

    historyEntry.regions = [];

    for (const { coordinates, id, seed, type } of this.regions.values()) {
      historyEntry.regions.push({
        coordinates: [ ...coordinates.values() ],
        id,
        seed,
        type,
      });
    }

    if (this.details.size) {
      historyEntry.details = {};

      for (const { coordinates, rotation, type } of [ ...this.details.values() ]) {
        if (typeof historyEntry.details[type] === 'undefined') {
          historyEntry.details[type] = [];
        }

        historyEntry.details[type]?.push({ coordinates, rotation });
      }
    }

    return historyEntry;
  };

  /**
   * Returns matrix instructions.
   */
  getInstructions = (): MatrixInstructions => {
    const instructions: MatrixInstructions = {
      areas: new Map(),
      connections: new Map(),
    };

    // -- Gather Instructions --------------------------------------------------

    let areaNumber = 1;

    for (const [ id, { coordinates: regionCoordinates, seed, type }] of this.regions) {
      const coordinates = [ ...regionCoordinates.values() ];

      // Collect connections

      if (type in CONNECTION) {
        instructions.connections.set(id, {
          areaDirections: getConnectionCellAreaDirections(this.matrix, coordinates[0]),
          connectionId: id,
          coordinates,
          type: type as CONNECTION,
        });

        continue;
      }

      // Collect areas

      if (type in AREA) {
        const areaDetails: Detail[] = [];
        const shadows: CoordinatesMap = new Map();

        for (const cellCoordinates of coordinates) {
          const [ x, y ] = cellCoordinates;

          // Gather area details

          const detail = this.details.get(`${x},${y}`);

          detail && areaDetails.push(detail);

          // Gather shadow coordinates

          this.addAreaShadows(shadows, cellCoordinates);
        }

        const labelRect = getMaxRectangle(regionCoordinates);

        if (labelRect) {
          instructions.areas.set(id, {
            areaId: id,
            connectionIds: new Set(),
            coordinates,
            details: areaDetails.length ? areaDetails : null,
            label: {
              number: areaNumber++,
              rect: labelRect,
            },
            seed,
            shadows,
            type: type as AREA,
          });
        }

        continue;
      }
    }

    // Add area connections

    for (const { areaDirections, connectionId, coordinates } of instructions.connections.values()) {
      for (const areaId of areaDirections.values()) {
        const area = getMapEntryRequired(instructions.areas, areaId);

        area.connectionIds.add(connectionId);

        if (areaDirections.size === 1) {
          // Only exterior connections require shadow coordinates

          for (const cellCoordinates of coordinates) {
            this.addAreaShadows(area.shadows, cellCoordinates);
          }
        }
      }
    }

    return instructions;
  };

  /**
   * Returns an object containing read only clones of the matrix's source: a
   * multi dimensional array of cell values and their positions, which make up a
   * matrix's layout and composition.
   */
  getSource = (): MatrixImmutable => cloneMatrix(this.matrix);

  /**
   * Updates a region's properties.
   */
  updateRegion = (regionId: number, { seed, type }: RegionUpdate) => {
    const region = getRegion(this.regions, regionId);

    if (typeof seed !== 'undefined') {
      region.seed = seed;
      return;
    }

    if (type) {
      if (isArea(regionId) && !areaRegions.has(type as AREA)) {
        throw new TypeError(`Invalid area region type "${type}" for area "${regionId}" in updateRegion()`);
      }

      if (isConnection(regionId) && !connections.has(type as CONNECTION)) {
        throw new TypeError(`Invalid connection region type "${type}" for connection "${regionId}" in updateRegion()`);
      }

      region.type = type;
      return;
    }
  };

  // -- Private Methods --------------------------------------------------------

  /**
   * Adds area shadow cells to the given area shadow coordinates map.
   */
  private addAreaShadows(shadows: CoordinatesMap, [ x, y ]: Coordinates): void {
    for (const [ offsetX, offsetY ] of offsets) {
      const cellX = x + offsetX;
      const cellY = y + offsetY;
      const key: CoordinatesKey = `${cellX},${cellY}`;

      if (shadows.has(key)) {
        continue;
      }

      const shadowCellCoordinates: Coordinates = [ cellX, cellY ];
      const value = getCellValue(this.matrix, shadowCellCoordinates);

      if (isArea(value)) {
        continue;
      }

      shadows.set(key, shadowCellCoordinates);
    }
  }

  /**
   * Clears a cell in the matrix.
   */
  private clearCell(coordinates: Coordinates): void {
    const [ x, y ] = coordinates;
    const value = this.matrix[x][y];

    if (value) {
      this.details.delete(`${x},${y}`);
      this.removeCoordinatesFromRegion(value, coordinates);

      this.matrix[x][y] = null;
    }
  }

  /**
   * Adds new area cells to the matrix, merging regions as necessary. Returns
   * false when no cells are added, true otherwise.
   */
  private drawAreaCells(
    drawCoordinates: Coordinates[],
    brush: AREA,
    drawOption: DRAW_OPTION
  ): boolean {
    const areaCells = filterAreaDraw(this.matrix, getDrawCoordinates(drawCoordinates, drawOption));

    if (!areaCells.size) {
      return false;
    }

    // Calculate area draw

    const {
      expansionCoordinates,
      mergeRegionIds,
    } = this.calculateAreaDraw(areaCells);

    const coordinates = [ ...areaCells.values(), ...expansionCoordinates ];

    // Determine area id

    const areaId = mergeRegionIds.size
      ? getMergeId(this.regions, mergeRegionIds)
      : getCellIdPool(this.matrix).getId(brush);

    // Add area cells to the matrix

    for (const cellCoordinates of coordinates) {
      this.setCell(areaId, cellCoordinates, brush);
    }

    // Merge regions

    if (mergeRegionIds.size) {
      this.mergeRegions(mergeRegionIds, areaId, brush);
    }

    // Handle fragmentation in the new region due to rapid free draw

    this.updateFragmentedRegion(areaId);

    return true;
  }

  /**
   * Adds new connection cells to the matrix, merging regions as necessary, or
   * updates an existing connection's type. Returns false when no cells are
   * added, true otherwise.
   */
  private drawConnectionCells(
    drawCoordinates: Coordinates[],
    brush: CONNECTION
  ): boolean {
    const originCellValue = getCellValue(this.matrix, drawCoordinates[0]);

    if (isConnection(originCellValue)) {
      const connectionRegion = getRegion(this.regions, originCellValue);

      if (connectionRegion.type === brush) {
        return false;
      }

      // Update existing connection type.
      connectionRegion.type = brush;
      return true;
    }

    const {
      area,
      connection,
    } = filterConnectionDraw(this.matrix, drawCoordinates);

    if (!connection.length) {
      return false;
    }

    // Gather adjacent connection regions

    const adjacentConnectionIds: Set<number> = new Set();

    for (const cellCoordinates of connection) {
      const adjacentConnectionCells = getAdjacentCells(this.matrix, cellCoordinates, {
        find: 'connection',
      });

      if (!adjacentConnectionCells.size) {
        continue;
      }

      for (const { value: connectionId } of adjacentConnectionCells.values()) {
        typeof connectionId === 'number' && adjacentConnectionIds.add(connectionId);
      }
    }

    // Determine connection id

    const connectionId = adjacentConnectionIds.size
      ? getMergeId(this.regions, adjacentConnectionIds)
      : getCellIdPool(this.matrix).getId(brush);

    // Add connection cells to the matrix

    for (const cellCoordinates of connection) {
      const [ x, y ] = cellCoordinates;

      if (this.details.has(`${x},${y}`)) {
        this.details.delete(`${x},${y}`);
      }

      this.setCell(connectionId, cellCoordinates, brush);
    }

    // Merge connection regions

    if (adjacentConnectionIds.size) {
      this.mergeRegions(adjacentConnectionIds, connectionId, brush);
    }

    // Handle fragmentation when splitting an area region

    if (isArea(originCellValue)) {
      this.updateFragmentedRegion(originCellValue);
    }

    if (area.length) {
      this.drawAreaCells(area, AREA.Dungeon, DRAW_OPTION.Rectangle);
    }

    return true;
  }

  /**
   * Adds details to area regions. Returns false when no details are added,
   * true otherwise.
   */
  private drawDetail(
    drawCoordinates: Coordinates[],
    brush: DETAIL,
    drawOption: DRAW_OPTION
  ): boolean {
    const coordinates = filterDetailDraw(this.matrix, getDrawCoordinates(drawCoordinates, drawOption));

    if (!coordinates.length) {
      return false;
    }

    for (const cellCoordinates of coordinates) {
      const [ x, y ] = cellCoordinates;
      const key: CoordinatesKey = `${x},${y}`;

      this.details.set(key, {
        coordinates: cellCoordinates,
        type: brush,
      });
    }

    return true;
  }

  /**
   * Removes region and/or detail cells from the matrix, splitting regions into
   * new groups as needed. Returns false when no cells are removed, true
   * otherwise.
   */
  private eraseCells(
    eraseCoordinates: Coordinates[],
    drawOption: DRAW_OPTION
  ): boolean {
    const coordinates = filterErase(this.matrix, getDrawCoordinates(eraseCoordinates, drawOption));

    if (!coordinates.length) {
      return false;
    }

    // Erase detail cells

    const [ x1, y1 ] = eraseCoordinates[0];

    if (this.details.has(`${x1},${y1}`)) {
      for (const cellCoordinates of coordinates) {
        const [ x, y ] = cellCoordinates;
        this.details.delete(`${x},${y}`);
      }

      return true;
    }

    // Erase region cells

    const modifiedRegions = new Set<number>();
    const adjacentConnectionIds: Set<number> = new Set();

    // Gather modified regions and adjacent connections before removing cells

    for (const cellCoordinates of coordinates) {
      const cellValue = getCellValue(this.matrix, cellCoordinates);

      if (cellValue) {
        modifiedRegions.add(cellValue);
      }

      const adjacentConnectionCells = getAdjacentCells(this.matrix, cellCoordinates, {
        find: 'connection',
      });

      if (!adjacentConnectionCells.size) {
        continue;
      }

      for (const { value: connectionId } of adjacentConnectionCells.values()) {
        typeof connectionId === 'number' && adjacentConnectionIds.add(connectionId);
      }
    }

    // Remove cells from the matrix

    for (const cellCoordinates of coordinates) {
      this.clearCell(cellCoordinates);
    }

    // Update modified regions

    for (const id of modifiedRegions) {
      this.updateFragmentedRegion(id);
    }

    // Reconcile adjacent connections

    for (const id of adjacentConnectionIds) {
      this.updateConnectionRegion(id);
    }

    return true;
  }

  /**
   * Returns all area ids connected to the given connection cell's coordinates.
   */
  private getConnectedAreaIds(connectionCoordinates: Coordinates): number[] {
    const adjacentAreaIds: number[] = [];

    const connectionDirection = getConnectionCellDirection(this.matrix, connectionCoordinates);

    for (const areaDirection of (connectionDirection?.split('-')) as Direction[]) {
      const connectedToCoordinates = getCoordinatesInDirection(connectionCoordinates, areaDirection);
      const areaId = getCellValue(this.matrix, connectedToCoordinates);

      if (isArea(areaId)) {
        adjacentAreaIds.push(areaId);
      }
    }

    return adjacentAreaIds;
  }

  /**
   * Merges two or more regions into the target region id.
   */
  private mergeRegions(
    sourceIds: Set<number>,
    targetId: number,
    cellType: RegionType
  ): void {
    const targetRegion = getRegion(this.regions, targetId);

    for (const sourceId of sourceIds) {
      if (sourceId === targetId) {
        continue;
      }

      const sourceRegion = this.regions.get(sourceId);

      if (!sourceRegion) {
        continue;
      }

      targetRegion.coordinates = new Map([
        ...sourceRegion.coordinates,
        ...targetRegion.coordinates,
      ]);

      for (const [ x, y ] of sourceRegion.coordinates.values()) {
        this.matrix[x][y] = targetId;
      }

      this.regions.delete(sourceId);
    }

    targetRegion.type = cellType;
  }

  /**
   * Removes a coordinate from a region.
   */
  private removeCoordinatesFromRegion(id: number, coordinates: Coordinates): void {
    const region = getRegion(this.regions, id);
    const [ x, y ] = coordinates;
    const key: CoordinatesKey = `${x},${y}`;

    region.coordinates.delete(key);

    if (region.coordinates.size === 0) {
      this.regions.delete(id);
    }
  }

  /**
   * Sets a cell id to the matrix, creating and/or updating regions.
   */
  private setCell(id: number, coordinates: Coordinates, type: RegionType): void {
    const [ x, y ] = coordinates;
    const key: CoordinatesKey = `${x},${y}`;
    const previousValue = this.matrix[x][y];
    const region = this.regions.get(id);

    if (region) {
      region.coordinates.set(key, coordinates);
    } else {
      this.regions.set(id, {
        coordinates: new Map([[ key, coordinates ]]),
        id,
        seed: isConnection(id) ? '' : getRandomSeed(this.dice),
        type,
      });
    }

    if (previousValue !== null) {
      this.removeCoordinatesFromRegion(previousValue, coordinates);
    }

    this.matrix[x][y] = id;
  }

  /**
   * Updates the matrix for a modified connection region.
   */
  private updateConnectionRegion(id: number) {
    const region = this.regions.get(id);

    if (!region) {
      return;
    }

    // Determine the connection's directions

    const connectionDirections: Map<ConnectionDirection | null, Coordinates[]> = new Map();

    for (const coordinates of region.coordinates.values()) {
      const direction = getConnectionCellDirection(this.matrix, coordinates);
      getOrSetMapEntry(connectionDirections, direction, []).push(coordinates);
    }

    if (connectionDirections.size === 1 && !connectionDirections.get(null)) {
      // Connection directions are consistent, nothing to update.
      return;
    }

    // Analyze connection cells

    const invalidCoordinates: Coordinates[] = [];
    const validCoordinates: CoordinatesMap = new Map();

    for (const [ direction, coordinates ] of connectionDirections) {
      if (direction === 'north-south' || direction === 'east-west') {
        for (const cellCoordinates of coordinates) {
          const [ x, y ] = cellCoordinates;
          validCoordinates.set(`${x},${y}`, cellCoordinates);
        }

        continue;
      }

      invalidCoordinates.push(...coordinates);
    }

    // Remove invalid connection cells from the matrix

    for (const coordinates of invalidCoordinates) {
      this.clearCell(coordinates);
    }

    // Update connection region

    if (validCoordinates.size) {
      region.coordinates = validCoordinates;
    }

    this.updateFragmentedRegion(id);
  }

  /**
   * Updates the matrix if the region has been fragmented.
   */
  private updateFragmentedRegion(id: number) {
    const region = this.regions.get(id);

    if (!region) {
      return;
    }

    const fragments = getCellGroups(region.coordinates);

    if (fragments.length === 1) {
      // Region is not fragmented, nothing to update.
      return;
    }

    const [ firstRegion, ...newRegions ] = fragments;

    // Update coordinates in the first region

    region.coordinates = toCoordinatesMap(firstRegion);

    const { getAreaId, getConnectionId } = getCellIdPool(this.matrix);

    // Create new regions

    for (const regionCoordinates of newRegions) {
      const cellValue = getCellValue(this.matrix, regionCoordinates[0]);

      const regionId = isConnection(cellValue)
        ? getConnectionId()
        : getAreaId();

      for (const coordinates of regionCoordinates) {
        this.setCell(regionId, coordinates, region.type);
      }
    }
  }

  // -- Calculations ------------------------------------------------------------

  /**
   * Calculates the area draw and returns additional coordinates and region ids
   * which should be merged.
   */
  private calculateAreaDraw(areaCells: CoordinatesMap): {
    /**
     * Additional coordinates which should be included in the draw due to
     * parallel connections which should be surrounded by new area cells.
     */
    expansionCoordinates: Coordinates[];

    /** Adjacent region ids which should be merged into the largest region. */
    mergeRegionIds: Set<number>;
  } {
    const mergeRegionIds: Set<number> = new Set();
    const parallelConnections: Map<number, Direction> = new Map();

    for (const cellCoordinates of areaCells.values()) {
      const adjacentCells = getAdjacentCells(this.matrix, cellCoordinates, {
        find: 'occupied',
      });

      if (!adjacentCells.size) {
        continue;
      }

      for (const [ direction, { coordinates: adjacentCoordinates, value }] of adjacentCells) {
        if (isArea(value)) {
          // TDL only merge areas of the same type
          mergeRegionIds.add(value);
          continue;
        }

        if (isConnection(value)) {
          const connectionDirection = getConnectionCellDirection(this.matrix, adjacentCoordinates);

          if (direction === connectionDirection) {
            // Connections parallel to new area cells become valid two-way
            // connections, which should not be merged.
            parallelConnections.set(value, connectionDirection);
            continue;
          }

          // Connections perpendicular to new area cells are invalid and merged.
          mergeRegionIds.add(value);

          // Merge areas connected to the merged connection.

          for (const areaId of this.getConnectedAreaIds(adjacentCoordinates)) {
            mergeRegionIds.add(areaId);
          }
        }
      }
    }

    // Expand area draw to surround any adjacent connections, if necessary

    const expansionCoordinates: CoordinatesMap = new Map();

    if (parallelConnections.size) {
      const parallelConnectionIds: Set<number> = new Set();

      // Get expansion coordinates for the draw

      for (const [ connectionId, direction ] of parallelConnections) {
        parallelConnectionIds.add(connectionId);

        const { coordinates: connectionCoordinates } = getRegion(this.regions, connectionId);

        for (const cellCoordinates of connectionCoordinates.values()) {
          const coordinatesToExpand = getCoordinatesInDirection(cellCoordinates, getOppositeDirection(direction));
          const [ x, y ] = coordinatesToExpand;

          if (!areaCells.has(`${x},${y}`)) {
            expansionCoordinates.set(`${x},${y}`, coordinatesToExpand);
          }
        }
      }

      for (const coordinatesToExpand of expansionCoordinates.values()) {
        const adjacentCells = getAdjacentCells(this.matrix, coordinatesToExpand, {
          find: 'occupied',
        });

        for (const { coordinates: adjacentCoordinates, value: regionId } of adjacentCells.values()) {
          if (regionId && parallelConnectionIds.has(regionId)) {
            continue;
          }

          // If an expansion cell is adjacent to a region, merge it.

          if (isArea(regionId)) {
            mergeRegionIds.add(regionId);
            continue;
          }

          // If an expansion cell is adjacent to a connection, merge it and all
          // areas it's connected to.

          if (isConnection(regionId)) {
            mergeRegionIds.add(regionId);

            for (const areaId of this.getConnectedAreaIds(adjacentCoordinates)) {
              mergeRegionIds.add(areaId);
            }
          }
        }
      }
    }

    return {
      expansionCoordinates: [ ...expansionCoordinates.values() ],
      mergeRegionIds,
    };
  }
}
