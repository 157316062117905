import type { LootConfig, LootConfigDefaults, LootConfigIncomplete } from '../';

// -- Types --------------------------------------------------------------------

type LootEntries = { [name: string]: Partial<LootConfig> | null };

// -- Config -------------------------------------------------------------------

const defaults: LootConfigDefaults = {
  category: 'weapons',
  rarity: 'uncommon',
  size: 'medium',
};

const magicDefaults: Partial<LootConfig> = {
  isMagic: true,
};

const weapons: LootEntries = {
  Battleaxe: { rarity: 'average' },
  Blowgun: null,
  Caltrops: { maxCount: 20, size: 'tiny' },
  Club: { rarity: 'abundant' },
  'Crossbow, hand': { rarity: 'rare' },
  'Crossbow, heavy': { rarity: 'rare' },
  'Crossbow, light': { rarity: 'rare' },
  Dagger: { rarity: 'common', size: 'small' },
  Dart: { size: 'tiny' },
  Flail: null,
  Glaive: { size: 'large' },
  Greataxe: { size: 'large' },
  Greatclub: { size: 'large' },
  Greatsword: { size: 'large' },
  Halberd: { size: 'large' },
  Handaxe: null,
  Javelin: null,
  Lance: null,
  'Light hammer': null,
  Longbow: { size: 'large' },
  Longsword: { size: 'large' },
  Mace: null,
  Maul: null,
  Morningstar: null,
  Net: null,
  Pike: null,
  Quarterstaff: { rarity: 'common', size: 'large' },
  Rapier: null,
  Scimitar: null,
  Shortbow: null,
  Shortsword: { rarity: 'average' },
  Sickle: null,
  Sling: null,
  Spear: { size: 'large' },
  Trident: { size: 'large' },
  'War pick': null,
  Warhammer: { size: 'large' },
  Whip: null,
};

const magicalVariants: Partial<LootConfig>[] = [
  { name: '+1', rarity: 'uncommon' },
  { name: '+2', rarity: 'rare' },
  { name: '+3', rarity: 'veryRare' },
];

const magicalWeapons: Omit<LootConfigIncomplete, 'category'>[] = Object.entries(weapons).flatMap(([ name, config ]) => {
  return magicalVariants.map(({ name: bonus, rarity }) => ({
    ...config,
    name: `${name}, ${bonus}`,
    rarity,
  }));
});

const specialMagicalWeapons: LootEntries = {
  'Berserker Axe': { rarity: 'rare' },
  'Dagger of Venom': { rarity: 'rare' },
  'Dancing Sword': { rarity: 'veryRare' },
  Defender: { rarity: 'legendary' },
  'Dragon Slayer': { rarity: 'rare' },
  'Dwarven Thrower': { rarity: 'veryRare' },
  'Flame Tongue': { rarity: 'rare' },
  'Frost Brand': { rarity: 'veryRare' },
  'Giant Slayer': { rarity: 'rare' },
  'Hammer of Thunderbolts': { rarity: 'legendary' },
  'Holy Avenger': { rarity: 'legendary' },
  'Javelin of Lightning': { rarity: 'uncommon' },
  'Luck Blade': { rarity: 'legendary' },
  'Mace of Disruption': { rarity: 'rare' },
  'Mace of Smiting': { rarity: 'rare' },
  'Mace of Terror': { rarity: 'rare' },
  'Nine Lives Stealer': { rarity: 'veryRare' },
  Oathbow: { rarity: 'veryRare' },
  'Scimitar of Speed': { rarity: 'veryRare' },
  'Sun Blade': { rarity: 'rare' },
  'Sword of Life Stealing': { rarity: 'rare' },
  'Sword of Sharpness': { rarity: 'veryRare' },
  'Sword of Wounding': { rarity: 'rare' },
  'Trident of Fish Command': { rarity: 'uncommon' },
  'Vicious Weapon': { rarity: 'rare' },
  'Vorpal Sword': { rarity: 'legendary' },
};

const configs: LootConfigIncomplete[] = [

  // -- Basic weapons ----------------------------------------------------------

  ...Object.entries(weapons).map(([ name, config ]) => ({
    ...defaults,
    ...config,
    name,
  })),

  // -- Magical weapons --------------------------------------------------------

  ...magicalWeapons.map((config) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
  })),

  // -- Special magical armor --------------------------------------------------

  ...Object.entries(specialMagicalWeapons).map(([ name, config ]) => ({
    ...defaults,
    ...magicDefaults,
    ...config,
    name,
  })),

];

export default configs;
