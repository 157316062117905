import styles from './VisuallyHiddenFileInput.module.css';

/**
 * Renders a visually hidden input for use within a button to create a file
 * upload button.
 */
export default function VisuallyHiddenFileInput({
  accept,
  onChange,
}: {
  accept?: 'application/json';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <input
      accept={accept}
      aria-hidden
      className={styles.input}
      onChange={onChange}
      onClick={(event) => { event.currentTarget.value = ''; }}
      type="file"
    />
  );
}
