import { memo } from 'react';
import { Circle, Group, Line, Rect as Rectangle } from 'react-konva';

import { cellPx, colors } from '../../../config/map';

import type { ConnectionProps } from './';

// -- Types -------------------------------------------------------------------

type LockProps = Pick<ConnectionProps, 'xPx' | 'yPx'>;

// -- Config -------------------------------------------------------------------

const halfCellPx = cellPx / 2;

const size = cellPx / 2;

const bodyWidth = size * 0.56;
const bodyHeight = size * 0.44;

const barWidth = size * 0.32;
const barHeight = size * 0.48;

const bodyWidthHalf = bodyWidth / 2;

const lockStrokeWidth = size * 0.16;
const lockStrokeWidthHalf = lockStrokeWidth / 2;

const barPoints = [
  0,
  barHeight,

  0,
  lockStrokeWidthHalf,

  barWidth,
  lockStrokeWidthHalf,

  barWidth,
  barHeight,
];

const lockHeightHalf = (bodyHeight + barHeight + lockStrokeWidthHalf) / 2;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a locked connection indicator.
 */
export default memo(function Lock({ xPx, yPx }: LockProps) {
  return (
    <Group
      data-id="lock"
      offsetX={bodyWidthHalf - halfCellPx}
      offsetY={lockHeightHalf - halfCellPx}
      x={xPx}
      y={yPx}
    >
      <Rectangle
        fill={colors.shadowDetail}
        height={bodyHeight}
        lineJoin="round"
        stroke={colors.shadowDetail}
        strokeWidth={lockStrokeWidth}
        width={bodyWidth}
        y={barHeight}
      />

      <Line
        lineJoin="round"
        points={barPoints}
        stroke={colors.shadowDetail}
        strokeWidth={lockStrokeWidth}
        x={(bodyWidth - barWidth) / 2}
      />

      <Circle
        fill={colors.shadow}
        radius={bodyWidth / 6}
        x={bodyWidthHalf}
        y={barHeight + bodyHeight / 2}
      />
    </Group>
  );
});
