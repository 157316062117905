import styles from './CenterContent.module.css';

/**
 * Renders children centered vertically and horizontally in a container. On
 * small viewports, no padding is applied, even when given a `padding` prop.
 */
export default function CenterContent({
  children,
  padding,
}: {
  children: React.ReactNode;
  padding?: 'small';
}) {
  return (
    <div
      className={styles.centerContent}
      data-padding={padding || null}
    >
      {children}
    </div>
  );
}
