import { Layer, Stage, Text } from 'react-konva';

import Artwork from './Artwork';
import { appDomain } from '../../config';
import { cellPx, colors, fontFamilyTitle } from '../../config/map';

import type { ArtworkProps } from './Artwork';
import type Konva from 'konva';
import type React from 'react';

// -- Config -------------------------------------------------------------------

const watermarkFontSize = cellPx / 4;
const watermarkMarginX = cellPx / 4;
const watermarkMarginY = cellPx / 3;

// -- Public Component ---------------------------------------------------------

/**
 * Renders a full resolution, hidden map stage and artwork for reading the
 * canvas data url.
 *
 * The `<MapCanvas>` component cannot be read because it is cropped and scaled.
 */
export default function DownloadStage({
  stageRef,
  ...artworkProps
}: ArtworkProps & {
  stageRef: React.RefObject<Konva.Stage>;
}) {
  const {
    matrixHeight,
    matrixWidth,
  } = artworkProps;

  const stageHeight = matrixHeight * cellPx;
  const stageWidth = matrixWidth * cellPx;

  return (
    <Stage
      className="hide"
      height={stageHeight}
      ref={stageRef}
      width={stageWidth}
    >
      <Layer listening={false}>
        <Artwork {...artworkProps} />

        <Text
          align="right"
          fill={colors.shadowDetail}
          fontFamily={fontFamilyTitle}
          fontSize={watermarkFontSize}
          fontStyle="500"
          height={watermarkFontSize}
          text={`created at ${appDomain}`}
          width={stageWidth - watermarkMarginX}
          x={0}
          y={stageHeight - watermarkMarginY}
        />
      </Layer>
    </Stage>
  );
}
