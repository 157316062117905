import { Button, Stack } from '@mui/material';

import styles from './index.module.css';
import SeedInput from './SeedInput';
import { inputStackSpacing } from '../../../config/ui';

import type { GeneratorPanelState } from './hooks/useGeneratorPanelState';
import type { SeedState } from './hooks/useSeedState';
import type { ButtonProps } from '@mui/material/Button';

// -- Config -------------------------------------------------------------------

const toggleButtonSx = { flex: '1 0 0' };

// -- Public Component ---------------------------------------------------------

/**
 * Renders a generator settings panel wrapper.
 */
export default function GeneratorPanel({
  advancedSettings,
  'aria-label': ariaLabel,
  children,
  onGenerate,
  onSeedChange,
  onToggleAdvanced,
  onToggleInfo,
  seed,
  showAdvanced,
  showAdvancedDivider,
  showInfo,
}: {
  advancedSettings?: React.ReactNode;
  'aria-label': string;
  children: React.ReactNode;
  onGenerate: () => void;
  showAdvancedDivider?: boolean;
} & GeneratorPanelState & SeedState) {
  return (
    <form
      aria-label={ariaLabel}
      className={styles.panel}
    >
      <header className={styles.header}>
        <Button
          color="secondary"
          fullWidth
          onClick={onGenerate}
          variant="contained"
        >
          Generate
        </Button>

        <Stack
          alignItems="stretch"
          direction="row"
          justifyContent="stretch"
          mb={4}
          mt={2}
          spacing={1}
        >
          <Button
            aria-label={`${showInfo ? 'Hide' : 'Show'} generator info`}
            onClick={onToggleInfo}
            {...getButtonProps({ isActive: showInfo })}
          >
            Info
          </Button>

          <Button
            aria-label={`${showAdvanced ? 'Hide' : 'Show'} advanced generator settings`}
            onClick={onToggleAdvanced}
            {...getButtonProps({ isActive: showAdvanced })}
          >
            Advanced
          </Button>
        </Stack>

        {showAdvanced &&
          <Stack
            className={styles.advanced}
            data-divider={showAdvancedDivider}
            spacing={inputStackSpacing}
          >
            <SeedInput
              onSeedChange={onSeedChange}
              seed={seed}
              showInfo={showInfo}
            />

            {advancedSettings}
          </Stack>
        }
      </header>

      {children}

    </form>
  );
}

// -- Private Functions --------------------------------------------------------

/**
 * Renders the settings panel toggle buttons.
 */
function getButtonProps({
  isActive,
}: {
  isActive?: boolean;
}): ButtonProps {
  return {
    'aria-pressed': isActive,
    color: isActive ? 'inherit' : 'primary',
    size: 'small',
    sx: toggleButtonSx,
    variant: 'outlined',
  };
}
