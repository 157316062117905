import { appVersionMin } from '../../config';
import { matrixDimensionMax, matrixDimensionMin, themes } from '../../config/map';
import Map from '../matrix';

import type { MapSnapshot } from '../../lib/map';

/**
 * Validates a map snapshot when restoring a map, throwing an error if the data
 * is invalid in any way.
 */
export function validateSnapshot(snapshot: MapSnapshot) {
  if (!snapshot) {
    throw new Error('Missing map data.');
  }

  if (!snapshot.v) {
    throw new Error('Missing map version.');
  }

  if (!snapshot.v || snapshot.v < appVersionMin) {
    throw new Error('Unsupported map version.');
  }

  if (!themes.includes(snapshot.theme)) {
    throw new Error('Unsupported map theme.');
  }

  if (!Array.isArray(snapshot.dimensions)) {
    throw new Error('Invalid map dimensions.');
  }

  const { dimensions: [ width, height ] } = snapshot;

  if (width > matrixDimensionMax || height > matrixDimensionMax) {
    throw new RangeError(`Invalid map dimension(s) "${width} x ${height}" exceeding ${matrixDimensionMax}.`);
  }

  if (width < matrixDimensionMin || height < matrixDimensionMin) {
    throw new RangeError(`Invalid map dimension(s) "${width} x ${height}" less than ${matrixDimensionMin}.`);
  }

  new Map(snapshot);
}
