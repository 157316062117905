import AreaEditorInputs from './AreaEditorInputs';
import ConnectionEditorInputs from './ConnectionEditorInputs';
import MapEditorInputs from './MapEditorInputs';
import styles from './MapEditorOptions.module.css';
import Palette from './Palette';
import { getMapEntryRequired } from '../../../lib';
import { TOOL } from '../../../lib/matrix';
import { isArea, isConnection } from '../../../lib/matrix/utility';
import CenterContent from '../../Display/CenterContent';
import EmptyMessage from '../../Display/EmptyMessage';
import { CursorDefault as CursorDefaultIcon } from '../../Display/Icons';
import TabSet from '../../Interface/TabSet';

import type { CellValue, MatrixInstructions } from '../../../lib/matrix';
import type { TabState } from '../../Interface/hooks/useTabState';
import type { InteractiveMapState } from '../hooks/useInteractiveMap';
import type { MapInfoState } from '../hooks/useMapInfo';

// -- Types --------------------------------------------------------------------

type MapEditorOptionsProps = {
  activeTool: TOOL;
  instructions: MatrixInstructions;
  isMapEmpty: boolean;
  selectedRegionId: CellValue;
} & Pick<InteractiveMapState, 'instructions' | 'onUpdateRegion'>
  & Pick<MapInfoState, 'mapInfo' | 'onSetAreaInfo' | 'onSetConnectionInfo'>
  & React.ComponentProps<typeof Palette>
  & React.ComponentProps<typeof MapEditorInputs>
  & TabState;

// -- Public Component ---------------------------------------------------------

/**
 * Renders the map editor options panel.
 */
export default function MapEditorOptions({
  activeBrush,
  activeTabIndex,
  activeTool,
  instructions,
  mapInfo,
  onChangeBrush,
  onSetAreaInfo,
  onSetConnectionInfo,
  onSetTab,
  onUpdateRegion,
  selectedRegionId,
  ...inputProps
}: MapEditorOptionsProps) {
  if (activeTool === TOOL.Select) {
    return (
      <RegionEditor
        instructions={instructions}
        mapInfo={mapInfo}
        onSetAreaInfo={onSetAreaInfo}
        onSetConnectionInfo={onSetConnectionInfo}
        onUpdateRegion={onUpdateRegion}
        selectedRegionId={selectedRegionId}
      />
    );
  }

  return (
    <TabSet
      activeTabIndex={activeTabIndex}
      aria-label="Map editor sidebar tabs"
      onSetTab={onSetTab}
      tabs={[ 'Draw', 'Settings' ]}
    >
      <Palette
        activeBrush={activeBrush}
        onChangeBrush={onChangeBrush}
      />

      <MapEditorInputs {...inputProps} />
    </TabSet>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders region editor options.
 */
function RegionEditor({
  instructions,
  mapInfo,
  onSetAreaInfo,
  onSetConnectionInfo,
  onUpdateRegion,
  selectedRegionId,
}: Pick<MapEditorOptionsProps, 'mapInfo'
  | 'instructions'
  | 'onSetAreaInfo'
  | 'onSetConnectionInfo'
  | 'onUpdateRegion'
  | 'selectedRegionId'
>) {
  if (selectedRegionId !== null) {
    if (isArea(selectedRegionId)) {
      const { type } = getMapEntryRequired(instructions.areas, selectedRegionId);

      return (
        <AreaEditorInputs
          areaId={selectedRegionId}
          info={mapInfo.areaInfo[selectedRegionId]}
          key={selectedRegionId}
          onSetAreaInfo={onSetAreaInfo}
        />
      );
    }

    if (isConnection(selectedRegionId)) {
      const { type } = getMapEntryRequired(instructions.connections, selectedRegionId);

      return (
        <ConnectionEditorInputs
          connectionId={selectedRegionId}
          info={mapInfo.connectionInfo[selectedRegionId]}
          onSetConnectionInfo={onSetConnectionInfo}
          onUpdateRegion={onUpdateRegion}
          type={type}
        />
      );
    }

    throw new TypeError(`Invalid selected region "${selectedRegionId}" in <RegionEditor>`);
  }

  return (
    <CenterContent padding="small">
      <div className={styles.placeholder}>
        <CursorDefaultIcon className={styles.icon} />
        <EmptyMessage title="Select a region" />
      </div>
    </CenterContent>
  );
}
