import { Link as MuiLink } from '@mui/material';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { UnsavedChangesContext } from '../../components/Utility/UnsavedChangesContextProvider';

import type { LinkProps } from '@mui/material';
import type { To } from 'react-router-dom';

// -- Config -------------------------------------------------------------------

/** Link props. */
const linkProps: LinkProps = {
  color: 'primary',
  underline: 'hover',
};

/** External link props. */
const externalLinkProps: LinkProps = {
  ...linkProps,
  rel: 'noopener noreferrer',
  target: '_blank',
};

// -- Public Component ---------------------------------------------------------

/**
 * Renders an anchor link or a router link based on either a `to` or `href`
 * prop.
 */
export default function Link({
  children,
  href,
  to,
}: {
  children: React.ReactNode;
  href?: string;
  to?: To;
}) {
  if (to) {
    return <InternalLink to={to}>{children}</InternalLink>;
  }

  if (href) {
    return <ExternalLink href={href}>{children}</ExternalLink>;
  }

  throw new TypeError('`to` or `href` must be provided in <Link>');
}

// -- Private Components -------------------------------------------------------

/**
 * Renders an external link.
 */
function ExternalLink({
  children,
  href,
}: {
  children: React.ReactNode;
  href: string;
}) {
  return (
    <MuiLink
      href={href}
      {...externalLinkProps}
    >
      {children}
    </MuiLink>
  );
}

/**
 * Renders an internal router link.
 */
function InternalLink({
  children,
  to,
}: {
  children: React.ReactNode;
  to: To;
}) {
  const { onNav } = useContext(UnsavedChangesContext);

  return (
    <MuiLink
      component={RouterLink}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => onNav(event, to)}
      to={to}
      {...linkProps}
    >
      {children}
    </MuiLink>
  );
}
