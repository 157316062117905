import { Button, Typography } from '@mui/material';

import styles from './index.module.css';
import StaticPage from '../../components/Display/StaticPage';
import WarningBox from '../../components/Display/WarningBox';
import Link from '../../components/Interface/Link';
import { discordInviteUrl, patreonUrl } from '../../config';

// -- Public Component ---------------------------------------------------------

/**
 * Renders the about page.
 */
export default function AboutPage() {
  return (
    <StaticPage
      browserTitle="About"
      metaDescription="All about the Mystic Waffle D&D Dungeon Generator. Who created it? Is it awesome? Let's find out!"
      title="About Mystic Waffle"
    >
      <div>
        <p className="paragraph">
          Mystic Waffle’s D&D and TTRPG Generator is a web application forged in JavaScript by AJ, a Human
          Sorcerer. The app features a tile based drawing and annotation suite
          for creating Dungeons & Dragons and other TTRPG maps&mdash;designed to simulate
          drawing adventures on actual grid paper. Dungeon maps are generated
          using a procedural generation algorithm which includes area
          descriptions<sup>1</sup> and generated loot. Every aspect of generation
          can be curated and fine-tuned via an extensive set of configuration
          options.
        </p>

        <WarningBox className="paragraph">
          <Typography
            component="sup"
            variant="body2"
          >
            1. The v2 map generator is currently still being migrated 😱! Area descriptions, loot generation, forking connections, and more are not yet available in the map generator.
          </Typography>
        </WarningBox>

        <p className="paragraph">
          This app represents thousands of hours of passionate work which started
          as an experiment in the winter of 2019 while I was stuck at the in-laws.
        </p>

        <p className="paragraph">
          This app and all content generated on it is 100% free for personal and commercial use.
        </p>

        <p className="paragraph">
          If you like the app, come chat about TTRPGs and share your creations on <Link href={discordInviteUrl}>Discord</Link>. Become
          a free or paid member on <Link href={patreonUrl}>Patreon</Link> to get updates and support future development. We’re hungry
          for constructive feedback, bug reports, and feature requests. Share this app with your awesome gaming friends!
        </p>

        <div className={styles.center}>
          <Button
            color="primary"
            href={discordInviteUrl}
            rel="noopener noreferrer"
            size="medium"
            target="_blank"
            variant="text"
          >
            👉 Join Us On Discord Here 👈
          </Button>

          <span aria-hidden className={styles.buttonSeparator}>•</span>

          <Button
            color="primary"
            href={patreonUrl}
            rel="noopener noreferrer"
            size="medium"
            target="_blank"
            variant="text"
          >
            Support the Generator Here ❤️
          </Button>

          <p>Happy adventuring! &ndash; AJ</p>
        </div>

        <div className={styles.team}>
          <div>
            <img alt="AJ" src="/img/team/aj.jpg" />
            <Typography variant="h4">AJ</Typography>
            <Typography align="center" component="p" fontSize="0.825rem">Sorcerer</Typography>
          </div>
          <div>
            <img alt="Paul" src="/img/team/paul.jpg" />
            <Typography variant="h4">Paul</Typography>
            <Typography align="center" component="p" fontSize="0.825rem">Shaman</Typography>
          </div>
        </div>

        <Typography
          marginTop={4}
          textAlign="center"
          variant="h2"
        >
          Credits
        </Typography>

        <p>A huge thank you to the following open source libraries, and the open source community in general, which have made this app possible!</p>

        <Typography component="ul">
          <li><Link href="https://konvajs.org/">Konva</Link></li>
          <li><Link href="https://mui.com/material-ui/">Material UI</Link></li>
          <li><Link href="https://sourceforge.net/projects/jsclipper/">Javascript Clipper</Link></li>
          <li><Link href="https://github.com/davidbau/seedrandom">seedrandom</Link></li>
          <li><Link href="https://vitejs.dev/">Vite</Link></li>
          <li><Link href="https://pictogrammers.com/">Pictogrammers</Link></li>
        </Typography>
      </div>
    </StaticPage>
  );
}
