import { memo } from 'react';

import PixelArt from './';
import Cell from './Cell';
import { DETAIL } from '../../../../lib/matrix';

import type { Pixels } from './';
import type { Coordinates } from '../../../../lib/matrix';

// -- Config -------------------------------------------------------------------

/** Column pixels: v1 */
const pixels: Pixels = [
  [ 'black', [[ 4, 19 ], [ 3, 19 ], [ 3, 18 ], [ 4, 18 ]], [[ 17, 19 ], [ 16, 19 ], [ 16, 18 ], [ 17, 18 ]], [[ 13, 16 ], [ 12, 16 ], [ 12, 15 ], [ 13, 15 ]], [[ 8, 15 ], [ 7, 15 ], [ 7, 14 ], [ 8, 14 ]], [[ 6, 17 ], [ 7, 17 ], [ 7, 18 ], [ 6, 18 ], [ 6, 19 ], [ 14, 19 ], [ 14, 18 ], [ 8, 18 ], [ 8, 17 ], [ 14, 17 ], [ 14, 16 ], [ 15, 16 ], [ 15, 8 ], [ 14, 8 ], [ 14, 9 ], [ 13, 9 ], [ 13, 14 ], [ 12, 14 ], [ 12, 9 ], [ 8, 9 ], [ 8, 13 ], [ 7, 13 ], [ 7, 9 ], [ 6, 9 ], [ 6, 8 ], [ 5, 8 ], [ 5, 16 ], [ 6, 16 ]], [[ 8, 4 ], [ 7, 4 ], [ 7, 3 ], [ 8, 3 ]], [[ 14, 2 ], [ 17, 2 ], [ 17, 3 ], [ 14, 3 ]], [[ 3, 2 ], [ 6, 2 ], [ 6, 3 ], [ 3, 3 ]], [[ 6, 1 ], [ 14, 1 ], [ 14, 2 ], [ 6, 2 ]], [[ 17, 14 ], [ 17, 15 ], [ 18, 15 ], [ 18, 18 ], [ 17, 18 ], [ 17, 16 ], [ 16, 16 ], [ 16, 17 ], [ 15, 17 ], [ 15, 19 ], [ 16, 19 ], [ 16, 20 ], [ 4, 20 ], [ 4, 19 ], [ 5, 19 ], [ 5, 17 ], [ 4, 17 ], [ 4, 16 ], [ 3, 16 ], [ 3, 18 ], [ 2, 18 ], [ 2, 15 ], [ 3, 15 ], [ 3, 14 ], [ 4, 14 ], [ 4, 8 ], [ 3, 8 ], [ 3, 7 ], [ 2, 7 ], [ 2, 3 ], [ 3, 3 ], [ 3, 5 ], [ 6, 5 ], [ 6, 6 ], [ 4, 6 ], [ 4, 7 ], [ 6, 7 ], [ 6, 6 ], [ 14, 6 ], [ 14, 7 ], [ 16, 7 ], [ 16, 6 ], [ 14, 6 ], [ 14, 5 ], [ 17, 5 ], [ 17, 3 ], [ 18, 3 ], [ 18, 7 ], [ 17, 7 ], [ 17, 8 ], [ 16, 8 ], [ 16, 14 ]], [[ 13, 7 ], [ 7, 7 ], [ 7, 8 ], [ 13, 8 ]]],
  [ 'gray10', [[ 3, 16 ], [ 4, 16 ], [ 4, 17 ], [ 5, 17 ], [ 5, 19 ], [ 4, 19 ], [ 4, 18 ], [ 3, 18 ]], [[ 5, 9 ], [ 6, 9 ], [ 6, 10 ], [ 7, 10 ], [ 7, 17 ], [ 6, 17 ], [ 6, 16 ], [ 5, 16 ]], [[ 4, 6 ], [ 6, 6 ], [ 6, 7 ], [ 4, 7 ]]],
  [ 'gray20', [[ 8, 15 ], [ 8, 14 ], [ 7, 14 ], [ 7, 13 ], [ 8, 13 ], [ 8, 10 ], [ 12, 10 ], [ 12, 14 ], [ 13, 14 ], [ 13, 15 ], [ 12, 15 ], [ 12, 16 ], [ 13, 16 ], [ 13, 17 ], [ 8, 17 ], [ 8, 18 ], [ 14, 18 ], [ 14, 19 ], [ 6, 19 ], [ 6, 18 ], [ 7, 18 ], [ 7, 15 ]], [[ 7, 7 ], [ 13, 7 ], [ 13, 8 ], [ 7, 8 ]]],
  [ 'gray30', [[ 16, 17 ], [ 16, 16 ], [ 17, 16 ], [ 17, 18 ], [ 16, 18 ], [ 16, 19 ], [ 15, 19 ], [ 15, 17 ]], [[ 14, 9 ], [ 14, 8 ], [ 15, 8 ], [ 15, 16 ], [ 14, 16 ], [ 14, 17 ], [ 13, 17 ], [ 13, 9 ]], [[ 7, 10 ], [ 6, 10 ], [ 6, 9 ], [ 7, 9 ]], [[ 8, 9 ], [ 12, 9 ], [ 12, 10 ], [ 8, 10 ]], [[ 6, 9 ], [ 5, 9 ], [ 5, 8 ], [ 6, 8 ]], [[ 14, 6 ], [ 16, 6 ], [ 16, 7 ], [ 14, 7 ]], [[ 14, 5 ], [ 14, 6 ], [ 6, 6 ], [ 6, 5 ], [ 3, 5 ], [ 3, 3 ], [ 6, 3 ], [ 6, 2 ], [ 14, 2 ], [ 14, 3 ], [ 17, 3 ], [ 17, 5 ]], [[ 8, 3 ], [ 7, 3 ], [ 7, 4 ], [ 8, 4 ]]],
];

// -- Memoized Components ------------------------------------------------------

const DetailsMemo = memo(Details);

// -- Public Component ---------------------------------------------------------

/**
 * Renders a pixel art column.
 */
export default function Column({ coordinates }: { coordinates: Coordinates }) {
  return (
    <Cell
      coordinates={coordinates}
      type={DETAIL.Column}
    >
      <DetailsMemo />
    </Cell>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders pixels. */
function Details() {
  return <PixelArt pixels={pixels} />;
}
