import { Tab, Tabs } from '@mui/material';
import { Children, useCallback, useId } from 'react';

import type { TabState } from './hooks/useTabState';

// -- Types --------------------------------------------------------------------

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabSetId: string;
  value: number;
}

// -- Public Component ---------------------------------------------------------

/**
 * Renders a tab set and corresponding tab panels. Must be controlled by the
 * parent component, typically using the `useTabState` hook.
 */
export default function TabSet({
  activeTabIndex,
  'aria-label': ariaLabel,
  children,
  onSetTab,
  tabs,
}: {
  'aria-label': string;
  children: React.ReactNode;
  tabs: React.ReactNode[];
} & TabState) {
  const tabSetId = useId();
  const childCount = Children.count(children);

  if (tabs.length !== childCount) {
    throw new RangeError(`<TabSet> requires exactly 1 tab for each child, received ${tabs.length} tabs and ${childCount} children`);
  }

  if (activeTabIndex < 0 || (activeTabIndex > tabs.length - 1)) {
    throw new RangeError(`Out of bounds active tab index "${activeTabIndex}" in <TabSet>`);
  }

  const onChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    onSetTab(newValue);
  }, [ onSetTab ]);

  return (
    <>
      <Tabs
        aria-label={ariaLabel}
        onChange={onChange}
        value={activeTabIndex}
        variant="fullWidth"
      >
        {tabs.map((label, index) => (
          <Tab
            aria-controls={getTabPanelId(tabSetId, index)}
            id={getTabId(tabSetId, index)}
            key={index}
            label={label}
          />
        ))}
      </Tabs>

      {Children.map(children, (child, index) => (
        <TabPanel
          index={index}
          tabSetId={tabSetId}
          value={activeTabIndex}
        >
          {child}
        </TabPanel>
      ))}
    </>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders a tab panel.
 */
function TabPanel({
  children,
  index,
  tabSetId,
  value,
  ...rest
}: TabPanelProps) {
  return (
    <section
      aria-labelledby={getTabId(tabSetId, index)}
      hidden={value !== index}
      id={getTabPanelId(tabSetId, index)}
      role="tabpanel"
      {...rest}
    >
      {value === index && children}
    </section>
  );
}

// -- Private Functions --------------------------------------------------------

/** Returns a tab's unique identifier. */
function getTabId(tabSetId: string, index: number) {
  return `tab-set-${tabSetId}-tab-${index}`;
}

/** Returns a tab panel's unique identifier. */
function getTabPanelId(tabSetId: string, index: number) {
  return `tab-set-${tabSetId}-tab-panel-${index}`;
}
