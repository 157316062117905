/**
 * Downloads a file from a data string.
 */
export function downloadFile(dataUrl: string, filename: string) {
  const link = globalThis.document.createElement('a');

  link.download = filename;
  link.href = dataUrl;

  globalThis.document.body.appendChild(link);
  link.click();
  globalThis.document.body.removeChild(link);
}

/**
 * Returns a filename stem for the given string.
 */
export function getFilenameStem(string: string): string {
  return string.toLowerCase().replace(/[^a-z0-9 _-]/g, '').replace(/\s+/g, '-');
}

/**
 * Returns a data URL for a JSON string.
 */
export function getJsonDataUrl(json: string): string {
  const blob = new globalThis.Blob([ json ], { type: 'application/json' });
  return globalThis.URL.createObjectURL(blob);
}
