import { memo } from 'react';
import { Shape } from 'react-konva';

import drawPaths from '../../lib/konva/drawPaths';

import type { Path } from '../../lib/matrix';

// -- Public Component (Memoized) ----------------------------------------------

/**
 * Renders a connection, area, area shadow, or highlighted area shape.
 */
export default memo(RegionShape, (prev, curr) => {
  return prev.paths.join(',') === curr.paths.join(',')
    && prev.fill === curr.fill
    && prev.opacity === curr.opacity;
});

// -- Private Components -------------------------------------------------------

/**
 * Renders a region shape.
 */
function RegionShape({
  fill,
  opacity,
  paths,
}: {
  fill: string;
  opacity?: number;
  paths: Path[];
}) {
  return (
    <Shape
      fill={fill}
      opacity={opacity}
      sceneFunc={(context, shape) => drawPaths(context, shape, paths)}
    />
  );
}
