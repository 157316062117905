import { Group, Line } from 'react-konva';

import { cellPx, colors, detailPx } from '../../../config/map';
import { getDiceBag } from '../../../lib/dice';
import { DETAIL } from '../../../lib/matrix';

import type { Coordinates } from '../../../lib/matrix';

// -- Types --------------------------------------------------------------------

interface RubbleInstructions {
  points: number[];
}

interface RubbleProps {
  coordinates: Coordinates;
  seed: string;
}

// -- Config -------------------------------------------------------------------

const radiusMin = cellPx / 20;
const radiusMax = cellPx / 5;

const sidesMin = 6;
const sidesMax = 8;

const shadowOffset = cellPx / -14;

// -- Public Component ---------------------------------------------------------

/**
 * Renders rubble.
 *
 * TDL memo, nest hook in memo component
 */
export default function Rubble(props: RubbleProps) {
  const rubble = useRubbleInstructions(props);

  const { coordinates: [ x, y ] } = props;
  const xPx = x * cellPx;
  const yPx = y * cellPx;

  return (
    <Group
      data-id={DETAIL.Rubble}
      data-theme="classic"
      x={xPx}
      y={yPx}
    >
      {rubble.map(({ points }, i) => (
        <Line
          closed
          fill={colors.shadow}
          key={`${i}-shadow`}
          lineJoin="round"
          offsetX={shadowOffset}
          offsetY={shadowOffset}
          points={points}
          strokeWidth={detailPx}
        />
      ))}

      {rubble.map(({ points }, i) => (
        <Line
          closed
          fill={colors.regionDungeon}
          key={`${i}-rubble`}
          lineJoin="round"
          points={points}
          stroke={colors.border}
          strokeWidth={detailPx}
        />
      ))}
    </Group>
  );
}

// -- Private Hooks ------------------------------------------------------------

/**
 * Returns instructions for rubble placement, size, and rotation.
 *
 * TDL fine tune rubble. Less rubble, smaller rubble, rounder rubble.
 * TDL increase rubble amount based on adjacent rubble cells.
 */
function useRubbleInstructions({
  coordinates: [ x, y ],
  seed,
}: RubbleProps): RubbleInstructions[] {
  const dice = getDiceBag({ seed: `${seed}.${x}.${y}` });
  const count = dice.roll(1, 5);
  const instructions: RubbleInstructions[] = [];

  for (let i = 0; i < count; i++) {
    const angelStart = dice.roll(1, 360);
    const radius = dice.roll(radiusMin, radiusMax);
    const sides = dice.roll(sidesMin, sidesMax);

    const xPx = dice.roll(radius, (cellPx - radius));
    const yPx = dice.roll(radius, (cellPx - radius));

    const angleIncrement = 2 * (Math.PI / sides);

    const points: number[] = [];

    let angle = angelStart;

    for (let j = i; j < sides; j++) {
      const wobbleAmount = radius / dice.roll(2, 8);
      const wobble = dice.roll(-wobbleAmount, wobbleAmount);

      points.push(
        xPx + radius * Math.cos(angle) + wobble,
        yPx + radius * Math.sin(angle) + wobble
      );

      angle = angle + angleIncrement;
    }

    instructions.push({
      points,
    });
  }

  return instructions;
}
